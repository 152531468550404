import classNames from 'classnames'
import React from 'react'

import { useSurvey } from '../../module/survey/hook'

export default function Question({
  title,
  description,
  className,
}: {
  title: string
  description?: string
  className?: string
}) {
  const { surveyOnModal } = useSurvey()

  return (
    <div
      className={classNames(
        'flex flex-col mt-[15px]',
        surveyOnModal ? 'tablet-sm:mt-[5px]' : 'tablet-sm:mt-[45px]',
        className,
      )}
    >
      <label className={classNames('font-semibold', surveyOnModal ? 'text-[18px]' : 'text-[21px]')}>
        {title}
      </label>
      {description && (
        <label className={`${surveyOnModal ? 'text-[14px]' : 'text-[15px]'}`}>{description}</label>
      )}
    </div>
  )
}
