import React, { useEffect, type ReactNode } from 'react'

import { useStudyingReport } from '../../module/myPage/hook'
import { WarningSpeechBubbleIcon } from '../icons/icon'

import ContentCard, { ContentsCardSkeleton, NoContentCard } from './ContentCard'

interface IStudyingTopThreeLayoutProps {
  children: ReactNode
  className?: string
  blur?: boolean
}

export default function StudyingTopThreeLayout({
  children,
  className,
  blur = false,
}: IStudyingTopThreeLayoutProps) {
  const { noContents, isToday } = useStudyingReport()

  return (
    <div className={`${className} relative mt-[25px]`}>
      <span className='font-medium text-[11px] tablet-sm:text-[13px] text-[#BBBBBB]'>
        학습시간 TOP 3
      </span>
      {blur && (
        <div className='absolute z-10 w-[calc(100%_+_30px)] h-full bg-white opacity-80 mx-[-20px]'></div>
      )}
      {noContents || isToday ? <NoDataStudyingTopThree isToday={isToday} /> : children}
    </div>
  )
}

function NoDataStudyingTopThree({ isToday }: { isToday: boolean }) {
  return (
    <>
      <div className='z-10 h-full absolute flex flex-col items-center justify-center w-full gap-[10px] text-black'>
        <WarningSpeechBubbleIcon />
        {isToday ? (
          <div className='flex flex-col text-center text-[13px] font-medium'>
            <span>오늘의 학습시간 TOP3는</span>
            <span>
              <span className='font-extrabold text-decoration__underline--mid-layer'>내일</span>{' '}
              확인할 수 있어요.
            </span>
          </div>
        ) : (
          <div className='flex flex-col text-center text-[13px] font-medium'>
            <span>학습시간을 기록해 보세요.</span>
            <span>
              <span className='font-extrabold text-decoration__underline--mid-layer'>
                나만의 공부루틴
              </span>
              을 확인할 수 있어요.
            </span>
            <span className='mt-2 text-[11px] text-[#8e9293]'>
              북카페에서 다운 받은 도서만 노출됩니다.
            </span>
          </div>
        )}
      </div>
      <ContentsCardSkeleton />
    </>
  )
}

StudyingTopThreeLayout.ContentCard = ContentCard
StudyingTopThreeLayout.NoContentCard = NoContentCard
