import React from 'react'

import { useSurvey } from '../../module/survey/hook'

import DeferredComponent from './DeferredComponent'
import SconnLoading from './SconnLoading'

export default function LoadingFallback() {
  const { surveyOnModal } = useSurvey()
  return (
    <DeferredComponent>
      <div
        className={`flex flex-col items-center w-full gap-5 ${surveyOnModal ? 'my-12' : 'my-28'}`}
      >
        <SconnLoading width={75} height={75} />
        <p className='text-[16px] text-sub-font'>잠시만 기다려주세요...</p>
      </div>
    </DeferredComponent>
  )
}

export function LoadingFallbackFullScreen() {
  return (
    <DeferredComponent>
      <div className='absolute z-20 flex flex-col items-center justify-center w-screen h-screen gap-5 bg-white'>
        <SconnLoading width={75} height={75} />
        <p className='text-[16px] text-sub-font'>잠시만 기다려주세요...</p>
      </div>
    </DeferredComponent>
  )
}
