import React, { useEffect, useState } from 'react'

import { useStudyingReport } from '../module/myPage/hook'
import { contentsStatsData, formatDurationToText } from '../module/myPage/statsHelper'
import { useUserContentStatistics } from '../module/sconnStudyData/hook'
import { CustomDate, type DateRange } from '../util/CustomDate'

import StudyingTopThreeLayout from './studyingTopThreeLayout'
import { ContentsCardSkeleton } from './studyingTopThreeLayout/ContentCard'

export default function StudyingTopThree() {
  const {
    statsPeriod,
    statsSelectedDate,
    statsStartDate,
    statsEndDate,
    noContents,
    isToday,
    statsDisplayContents: contents,
    setStatsIsNoContents,
    setStatsIsToday,
    setStatsDisplayContents,
  } = useStudyingReport()
  const [rangeParam, setRangeParam] = useState<DateRange>(
    (statsSelectedDate ?? new CustomDate().setYesterday()).getOneMonthRange(),
  )
  const [disableApi, setDisableApi] = useState<boolean>(false)
  const { data } = useUserContentStatistics(rangeParam, disableApi)

  useEffect(() => {
    if (data) {
      if (data === 'NO_DATA') {
        setStatsIsNoContents(true)
        setStatsDisplayContents([])
      } else {
        const filtered = data
          .filter((item) => item.duration >= 60)
          .map((item) => ({
            title: item.title,
            studyTime: formatDurationToText(item.duration),
            thumbnail: item.thumbnail,
          }))
        setStatsIsNoContents(filtered.length === 0)
        setStatsDisplayContents(filtered)
      }
    }
  }, [data])

  useEffect(() => {
    const result = contentsStatsData[statsPeriod](statsSelectedDate, statsStartDate, statsEndDate)
    if (result.range) {
      setRangeParam(result.range)
      setStatsIsToday(result.isToday)
      setDisableApi(result.range.to.isSame(new Date()))
    }
  }, [statsSelectedDate, statsStartDate, statsEndDate])

  return (
    <StudyingTopThreeLayout blur={noContents || isToday}>
      <div className='grid tablet-sm:grid-cols-3 tablet-sm:grid-rows-1 grid-cols-2 grid-rows-2 gap-[15px] mt-[10px] text-white h-[370px] tablet-sm:h-[200px]'>
        <StudyingTopThreeLayout.ContentCard
          content={contents[0]}
          className='col-span-2 tablet-sm:col-span-1'
          imgClassName='mt-[91px] tablet-sm:mt-[107px]'
          backgroundColor='bg-gradient-to-b from-[#FF8E06] to-[#FFAF13]'
        />
        {contents.length < 2 ? (
          <StudyingTopThreeLayout.NoContentCard
            textColor='text-[#ACA7CE]'
            backgroundColor='bg-gradient-to-b from-[#CBC2F5] to-[#E5E5E5]'
          />
        ) : (
          <StudyingTopThreeLayout.ContentCard
            content={contents[1]}
            imgClassName='mt-[107px]'
            backgroundColor='bg-gradient-to-b from-[#6249DE] to-[#C0E6FC]'
          />
        )}
        {contents.length < 3 ? (
          <StudyingTopThreeLayout.NoContentCard
            textColor='text-[#95BC90]'
            backgroundColor='bg-gradient-to-b from-[#C0E1BF] to-[#E5E5E5]'
          />
        ) : (
          <StudyingTopThreeLayout.ContentCard
            content={contents[2]}
            imgClassName='mt-[107px]'
            backgroundColor='bg-gradient-to-b from-[#78C776] to-[#D9EC91]'
          />
        )}
      </div>
    </StudyingTopThreeLayout>
  )
}

export function StudyingTopThreeSkeleton() {
  const { setStatsIsNoContents } = useStudyingReport()

  useEffect(() => {
    setStatsIsNoContents(true)
  }, [])

  return (
    <StudyingTopThreeLayout className='animate-pulse' blur>
      <ContentsCardSkeleton />
    </StudyingTopThreeLayout>
  )
}
