export function appendObjectMarker(name: string, ellipsis = false, post = ''): string {
  const charCode = name.charCodeAt(name.length - 1)

  // 유니코드의 한글 범위 내에서 해당 코드의 받침 확인
  const consonantCode = (charCode - 44032) % 28

  // 한글이 아닌 다른 문자나 기호일 경우
  if (consonantCode < 0 && name.length > 1) {
    return `${appendObjectMarker(name.slice(0, -1), ellipsis, name[name.length - 1])}`
  }

  // 0이면 받침 없음 -> 를
  if (consonantCode === 0) {
    return `${name}${post}${ellipsis ? '...' : ''}를`
  }

  // 1이상이면 받침 있음 -> 을
  return `${name}${post}${ellipsis ? '...' : ''}을`
}

export function isKoreanComplete(str: string) {
  // 한글 자음(ㄱ-ㅎ) 또는 모음(ㅏ-ㅣ)이 포함되어 있는지 검사하는 정규 표현식
  const regex = /[ㄱ-ㅎㅏ-ㅣ]/g

  // 문자열에 자음이나 모음이 포함되어 있으면 false 반환
  if (regex.test(str)) {
    return false
  }

  // 문자열이 완성형 한글(가-힣)만 포함하고 있는지 검사
  return /^[가-힣]+$/.test(str)
}

export function formatThousandSeparated(number: number) {
  const formatter = new Intl.NumberFormat('ko-KR')
  return formatter.format(number)
}
