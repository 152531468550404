/**
 * Removes the seconds from a given number of seconds, rounding down to the nearest minute.
 *
 * @param {number} seconds - The number of seconds.
 * @returns {number} - The number of seconds rounded down to the nearest minute.
 */
export function removeSeconds(seconds: number) {
  return Math.floor(seconds / 60) * 60
}

/**
 * Converts a given number of seconds to hours, rounding down to the nearest hour.
 *
 * @param {number} seconds - The number of seconds.
 * @returns {number} - The number of hours.
 */
export function secondsToHour(seconds: number) {
  return Math.floor(seconds / 3600)
}

/**
 * Converts a given number of seconds to minutes, rounding down to the nearest minute.
 *
 * @param {number} seconds - The number of seconds.
 * @returns {number} - The number of minutes.
 */
export function secondsToMinute(seconds: number) {
  return Math.floor((seconds % 3600) / 60)
}

/**
 * Converts a given number of seconds to hours and minutes.
 *
 * @param date1
 * @param date2
 * @returns
 */
export function dateDiffInDays(date1: Date, date2: Date) {
  const oneDay = 1000 * 60 * 60 * 24

  const date1Ms = date1.getTime()
  const date2Ms = date2.getTime()

  const diffMs = Math.abs(date1Ms - date2Ms)

  return Math.round(diffMs / oneDay)
}
