import { type showShareStudyingStatsModalPayload, type INativeInteractor } from './nativeInteractor'

declare global {
  interface Window {
    webkit: WebKit
    getNativeToken: (token: string) => void
    requestParentConsent: (isConsented: string | undefined) => void
  }
}

export declare interface WebKit {
  messageHandlers: MessageHandlers
}

export declare interface MessageHandlers {
  callNativeFunction: CallNativeFunction
}

export declare interface CallNativeFunction {
  postMessage: (params: string) => void
}

export declare interface IosMessage {
  type: string
  payload?: any
}

export declare interface ShowProductIosMessagePayload {
  pdfId: string
}

export declare interface ShowProductIdIosMessage extends IosMessage {
  payload: ShowProductIosMessagePayload
}

export declare interface OpenUrlIosMessagePayload {
  url: string
}

export declare interface OpenUrlIosMessage extends IosMessage {
  payload: OpenUrlIosMessagePayload
}

export class IosNativeInteractor implements INativeInteractor {
  async getNativeToken(): Promise<string | undefined> {
    return await new Promise((resolve, reject) => {
      const getToken = (token: string) => {
        resolve(token)
      }

      if (window.webkit) {
        const input: IosMessage = { type: 'get-native-token' }
        this.postMessage(input)
      }

      window.getNativeToken = getToken
    })
  }

  async requestParentConsent(): Promise<string | undefined> {
    return await new Promise((resolve, reject) => {
      const parentConsent = (isConsented: string | undefined) => {
        resolve(isConsented)
      }

      if (window.webkit) {
        const input: IosMessage = { type: 'request-parent-consent' }
        this.postMessage(input)
      }

      window.requestParentConsent = parentConsent
    })
  }

  showStoreProduct(pdfId: string) {
    const showProductIdIosMessage: ShowProductIdIosMessage = {
      type: 'show-store-product',
      payload: { pdfId },
    }
    this.postMessage(showProductIdIosMessage)
  }

  showSettingModal() {
    const iosMessage: IosMessage = {
      type: 'show-setting',
    }
    this.postMessage(iosMessage)
  }

  showShareStudyingStatsModal(payload: showShareStudyingStatsModalPayload) {
    const iosMessage: IosMessage = {
      type: 'show-share-studying-stats-modal',
      payload,
    }
    this.postMessage(iosMessage)
  }

  showSconnChargeModal() {
    const iosMessage: IosMessage = {
      type: 'show-sconn-charge-modal',
    }
    this.postMessage(iosMessage)
  }

  showHoldPointPage() {
    const iosMessage: IosMessage = {
      type: 'show-hold-point-page',
    }
    this.postMessage(iosMessage)
  }

  showCouponPage() {
    const iosMessage: IosMessage = {
      type: 'show-coupon-page',
    }
    this.postMessage(iosMessage)
  }

  openUrl(url: string) {
    const showProductIdIosMessage: OpenUrlIosMessage = {
      type: 'open-url',
      payload: { url },
    }
    this.postMessage(showProductIdIosMessage)
  }

  showServiceList() {
    const iosMessage: IosMessage = {
      type: 'show-service-list',
    }
    this.postMessage(iosMessage)
  }

  serviceLogout() {
    const iosMessage: IosMessage = {
      type: 'service-logout',
    }
    this.postMessage(iosMessage)
  }

  private postMessage(message: IosMessage) {
    window?.webkit?.messageHandlers?.callNativeFunction?.postMessage(JSON.stringify(message))
  }
}
