import React, { type ChangeEvent } from 'react'

import { type PeriodType } from '../module/myPage/types'

interface PeriodTypeRadioButtonProps {
  value: PeriodType
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export default function PeriodTypeRadioButton(props: PeriodTypeRadioButtonProps) {
  return (
    <>
      <div className='w-[46px] text-center text-[14px] text-[#A3A4A5]'>
        <input
          type='radio'
          className='hidden peer'
          id='monthly'
          value='monthly'
          checked={props.value === 'monthly'}
          name='report'
          onChange={props.onChange}
        />
        <label htmlFor='monthly' className='peer-checked:font-extrabold peer-checked:text-black'>
          월간
        </label>
      </div>

      <div className='h-[15px] border-r-[#DEDDE7] border-r-[0.5px] '></div>
      <div className='w-[46px] text-center text-[14px] text-[#A3A4A5]'>
        <input
          type='radio'
          className='hidden peer'
          id='weekly'
          value='weekly'
          checked={props.value === 'weekly'}
          name='report'
          onChange={props.onChange}
        />
        <label htmlFor='weekly' className='peer-checked:font-extrabold peer-checked:text-black'>
          주간
        </label>
      </div>
      <div className='h-[15px] border-r-[#DEDDE7] border-r-[0.5px]'></div>
      <div className='w-[46px] text-center text-[14px] text-[#A3A4A5]'>
        <input
          type='radio'
          className='hidden peer'
          id='daily'
          value='daily'
          checked={props.value === 'daily'}
          name='report'
          onChange={props.onChange}
        />
        <label htmlFor='daily' className='peer-checked:font-extrabold peer-checked:text-black'>
          일간
        </label>
      </div>
    </>
  )
}
