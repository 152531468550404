import React, { type InputHTMLAttributes, type ChangeEvent } from 'react'

import { BrandColorCheckboxIcon } from '../icons/icon'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  onClick: () => void
}

export default function Checkbox(props: CheckboxProps) {
  return (
    <div className='flex items-start mt-[20px] appearance-none'>
      <input
        className={`relative w-4 h-4 mt-1 ${props.checked ? 'appearance-none' : ''}  peer shrink-0`}
        type='checkbox'
        {...props}
      />
      {props.checked && (
        <BrandColorCheckboxIcon
          onClick={props.onClick}
          fill={props.disabled ? '#D7D7D7' : '#FECB4C'}
        />
      )}
      <label htmlFor={props.id} className='pl-[16px] text-[14px] tablet-sm:text-[16px]'>
        {props.label}
      </label>
    </div>
  )
}
