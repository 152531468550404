import { IonDatetime, IonModal } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import type ReactDatePicker from 'react-datepicker'

import { useStudyingReport } from '../module/myPage/hook'
import { useLatestStatsDate, useSelectedStatsDate } from '../module/myPage/statsHelper'
import { type PeriodType } from '../module/myPage/types'
import { CustomDate } from '../util/CustomDate'

import Button from './common/Button'
import { DatePicker } from './datePicker/DatePicker'
import { DoubleChevronIcon } from './icons/icon'

const PeriodLables: Record<PeriodType, string> = {
  daily: '일',
  weekly: '주',
  monthly: '월',
}

interface DateRangePickerModalProps {
  open: boolean
  onCancel: () => void
  onOk: (selectedDate: CustomDate | null, dateRange: [CustomDate | null, CustomDate | null]) => void
}

export function DateRangePickerModal(props: DateRangePickerModalProps) {
  const { statsPeriod, statsSelectedDate, statsEndDate } = useStudyingReport()
  const [selectedDate, setSelectedDate] = useState<CustomDate | null>(null)
  const [dateRange, setDateRange] = useState<[CustomDate | null, CustomDate | null]>([null, null])
  const [wheelPickerDate, setWheelPickerDate] = useState<Date | null>(null)
  const [openWheelPicker, setOpenWheelPicker] = useState<boolean>(false)
  const pickerRef = useRef<ReactDatePicker | null>(null)

  useEffect(() => {
    setOpenWheelPicker(false)
    setLatestPickerDate()
  }, [statsPeriod])

  useEffect(() => {
    if (props.open) {
      setOpenWheelPicker(false)
      setPickerDate(statsSelectedDate ?? statsEndDate ?? new Date())
    }
  }, [props.open])

  const setPickerDate = (date: Date) => {
    const customDate = new CustomDate(date)
    const { selectedDate, startDate, endDate } = useSelectedStatsDate(statsPeriod, customDate)

    setSelectedDate(selectedDate)
    setDateRange([startDate, endDate])
  }

  const setLatestPickerDate = () => {
    const { selectedDate, startDate, endDate } = useLatestStatsDate(statsPeriod)

    setSelectedDate(selectedDate)
    setDateRange([startDate, endDate])
  }

  const handleDateChange = (date: Date | [Date | null, Date | null]) => {
    let changedDate = date as Date

    if (statsPeriod === 'weekly') {
      const selectDate = date as [Date | null, Date | null]
      if (selectDate[0]) {
        changedDate = selectDate[0]
      }
    }

    setPickerDate(changedDate)
  }

  const handleMonthChange = (date: Date) => {
    if (statsPeriod === 'monthly') {
      const today = new Date()

      // 이번 달이면 어제, 아니면 마지막일자 전달
      if (today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()) {
        today.setDate(today.getDate() - 1)
        setPickerDate(today)
      } else {
        const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        setPickerDate(lastDate)
      }
    }
  }

  const handleYearChange = (date: Date) => {
    setPickerDate(date)
  }

  const handleWheelPickerChange = (e: any) => {
    if (typeof e.detail.value === 'string') {
      const isoDate = e.detail.value
      const date = new Date(isoDate)
      setWheelPickerDate(date)
    }
  }

  const handleClickLatestDate = () => {
    setLatestPickerDate()
    setOpenWheelPicker(false)

    // 캘린더를 닫았다가 다시 여는 방식으로 강제 리렌더링
    if (pickerRef.current) {
      pickerRef.current.setOpen(false)
      setTimeout(() => {
        pickerRef.current?.setOpen(true)
      }, 0)
    }
  }

  const handleClickSelectMonthYear = () => {
    setOpenWheelPicker((isOpen) => {
      if (!isOpen) {
        // open picker
        setWheelPickerDate(selectedDate ?? new Date())
      } else {
        // close picker
        setPickerDate(wheelPickerDate ?? new Date())
      }
      return !isOpen
    })
  }

  return (
    <IonModal
      id='custom-modal'
      className='date-range-picker-modal'
      keepContentsMounted
      isOpen={props.open}
      onIonModalDidDismiss={props.onCancel}
    >
      <div className='flex flex-col h-full'>
        <div className='flex flex-col h-full'>
          <div className='flex items-center justify-end bg-[#F3F3F3] h-[44px] pr-[15px] tablet-sm:pr-[25px]'>
            <div className='flex items-center gap-[2px]' onClick={handleClickLatestDate}>
              <span className='text-[14px] font-medium'>{`최근 ${PeriodLables[statsPeriod]}`}</span>
              <DoubleChevronIcon />
            </div>
          </div>
          <div className='relative flex items-start justify-center h-full'>
            {openWheelPicker && (
              <div className='z-20 flex items-start justify-center absolute pt-[50px] mt-[45px] w-full h-[calc(100%_-_60px)] bg-white'>
                <IonDatetime
                  preferWheel
                  id='app-date-range-picker'
                  presentation='month-year'
                  max={new Date().toISOString()}
                  defaultValue={wheelPickerDate?.toISOString()}
                  value={wheelPickerDate?.toISOString()}
                  onIonChange={handleWheelPickerChange}
                />
              </div>
            )}
            <DatePicker
              selectedDate={selectedDate}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              periodType={statsPeriod}
              isOpenMonthYearPicker={openWheelPicker}
              onChange={handleDateChange}
              onMonthChange={handleMonthChange}
              onYearChange={handleYearChange}
              onClickSelectMonthYear={handleClickSelectMonthYear}
              ref={pickerRef}
            />
          </div>
        </div>
        <div className='px-[20px] pt-[10px] pb-[20px]'>
          {!openWheelPicker && (
            <Button
              disabled={false}
              onClick={() => {
                props.onOk(selectedDate, dateRange)
              }}
            >
              완료
            </Button>
          )}
        </div>
      </div>
    </IonModal>
  )
}
