import { type CertData } from '../api/interfaces/sconnStudyData'
import { type CheckboxItemCertData } from '../components/ExaminationSelect'

export const userGroups = [
  {
    id: 'college',
    name: 'group',
    value: 'college',
    label: '대학생',
  },
  {
    id: 'repeater',
    name: 'group',
    value: 'repeater',
    label: 'N수생/반수생',
  },
  {
    id: 'highschool',
    name: 'group',
    value: 'highschool',
    label: '고등학생',
  },
  {
    id: 'middleschool',
    name: 'group',
    value: 'middleschool',
    label: '중학생',
  },
  {
    id: 'elemantaryschool',
    name: 'group',
    value: 'elemantaryschool',
    label: '초등학생',
  },
  {
    id: 'general',
    name: 'group',
    value: 'general',
    label: '일반인',
    description: '( 자격증, 취업, 공무원, 전문직 등..)',
  },
]

/** default examinatins 10개 선정 기본 시험 항목 */
export const EXAM_OPTIONS: CheckboxItemCertData[] = [
  {
    id: '0',
    code: 'add-button',
    name: '시험 찾아보기',
    type: '',
    checked: false,
    keyWords: '',
    manageHostName: '',
  },
  {
    id: '11',
    code: 'none',
    name: '준비중인 시험 없음',
    type: '',
    checked: false,
    keyWords: '',
    manageHostName: '',
  },
]

export const EXAM_POPULAR: CertData[] = [
  {
    id: 'CE_nationalCert_696',
    code: 'S0726',
    name: '의사 국가시험',
    type: 'nationalCert',
    keyWords:
      '의사, 의사, 의사국시, 국시, KMLE, 큼레, 치과, 치과의사, 한의사, 국가시험, 국시원, 한국보건의료인국가시험원, 보건복지부',
    manageHostName: '보건복지부',
  },
  {
    id: 'CE_nationalCert_680',
    code: 'S0731',
    name: '간호사 국가시험',
    type: 'nationalCert',
    keyWords:
      '간호사, 간호사, 간호사국시, 국시, 간호, 국가시험, 국시원, 한국보건의료인국가시험원, 보건복지부',
    manageHostName: '보건복지부',
  },
  {
    id: 'CE_exam_1',
    code: 'EXAM_EN',
    name: '외국어(영어)',
    type: 'exam',
    keyWords:
      '외국어(영어), 토익, 토플, TOEIC, TOEFL, OPIC, IELTS, SAT, 외국어, 언어, 영어, 외국어(영어)',
    manageHostName: '외국어(영어)',
  },
  {
    id: 'CE_exam_2',
    code: 'EXAM_CN',
    name: '외국어(중국어)',
    type: 'exam',
    keyWords: '외국어(중국어), HSK, HSKK, TSC, BCT, 외국어, 언어, 중국어, 외국어(중국어)',
    manageHostName: '외국어(중국어)',
  },
  {
    id: 'CE_exam_3',
    code: 'EXAM_JP',
    name: '외국어(일본어)',
    type: 'exam',
    keyWords: '외국어(일본어), JLPT, JPT, SJPT, FLEX, 외국어, 언어, 일본어, 외국어(일본어)',
    manageHostName: '외국어(일본어)',
  },
  {
    id: 'CE_exam_4',
    code: 'CS_EXAM_00092',
    name: '공무원 행정직',
    type: 'exam',
    keyWords: '공무원 행정직, 공무원, 행정직, 행정, 광역지방자치단체',
    manageHostName: '광역지방자치단체',
  },
  {
    id: 'CE_exam_5',
    code: 'CS_EXAM_POLICE',
    name: '공무원 경찰직',
    type: 'exam',
    keyWords: '경찰공무원 임용고시, 공무원, 경찰직, 경찰, 광역지방자치단체',
    manageHostName: '광역지방자치단체',
  },
  {
    id: 'CE_exam_6',
    code: 'CS_EXAM_FIRE_FIGHTER',
    name: '소방공무원 임용고시',
    type: 'exam',
    keyWords: '소방공무원 임용고시, 공무원, 소방직, 소방, 광역지방자치단체',
    manageHostName: '광역지방자치단체',
  },
  {
    id: 'CE_exam_7',
    code: 'EXAM_PTKH',
    name: '한국사능력검정시험',
    type: 'exam',
    keyWords:
      '한국사능력검정시험, 한국사능력검정시험, 한국사 기본, 한국사 심화, 한국사, 국사편찬위원회',
    manageHostName: '국사편찬위원회',
  },
  {
    id: 'CE_nationalCert_518',
    code: 'S9630',
    name: '공인중개사',
    type: 'nationalCert',
    keyWords: '공인중개사, 한국산업인력공단',
    manageHostName: '한국산업인력공단',
  },
]

const combinedExams = [...EXAM_OPTIONS, ...EXAM_POPULAR]
// const sortedExams = combinedExams.sort((a, b) => a.id - b.id)

export const EXAM_PRESET: CertData[] = combinedExams
