import React, { useState, type ChangeEvent, useMemo, type ReactNode, type MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/common/Button'
import Checkbox from '../components/common/Checkbox'
import { ChevronLeftIcon } from '../components/icons/icon'
import FormContent from '../components/surveyFormContentLayout'
import { useBookcafeData } from '../module/myPage/hook'
import { useNativeInteractor } from '../module/nativeInteractor/context'
import { useConsentSurvey } from '../module/sconnStudyData/hook'
import { useErrorToast } from '../util/hooks/useToast'

export default function PrivacyPolicy() {
  const navigate = useNavigate()
  const { user } = useBookcafeData()
  const { interactor } = useNativeInteractor()
  const [checked, setChecked] = useState<boolean>(false)
  const { setErrorMessage, setOpenError, errorToast } = useErrorToast()
  const [parentalAuthenticated, setParentalAuthenticated] = useState<boolean>(false)
  const { isPending, mutate } = useConsentSurvey()
  const overAge14 = useMemo(() => user?.overAge14, [user?.overAge14])

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  const handleClickTermsOfUse = () => {
    interactor.openUrl(
      'https://bookcafe.sconn.io/%ed%95%99%ec%8a%b5%eb%8d%b0%ec%9d%b4%ed%84%b0-%ec%84%9c%eb%b9%84%ec%8a%a4-%ea%b0%9c%ec%9d%b8%ec%a0%95%eb%b3%b4-%ec%88%98%ec%a7%91-%eb%b0%8f-%ec%9d%b4%ec%9a%a9-%eb%8f%99%ec%9d%98/',
    )
  }

  const handleParentalAuthentication = () => {
    interactor
      .requestParentConsent()
      .then((isConsented) => {
        if (isConsented?.toLowerCase() === 'true') {
          setParentalAuthenticated(true)
        }
      })
      .catch((e: any) => {
        console.log('callNativeFunction, error', e)
      })
  }

  return (
    <>
      <Header
        onClick={() => {
          navigate('/my-page')
        }}
      />
      <Layout>
        <FormContent.Question
          className='mb-[30px]'
          title='설문에 참여하기 위해 약관에 동의해 주세요.'
          description={`${
            overAge14
              ? `${user?.id ?? ''}님의 설문을 바탕으로 학습데이터를 제공하기 위해 설문 동의가 필요해요.`
              : `${user?.id ?? ''}님의 설문을 바탕으로 학습데이터를 제공하기 위해 보호자의 설문 동의가 필요해요.`
          } `}
        />
        <FormContent.Content>
          <Checkbox
            id='consent'
            name='consent'
            checked={checked}
            value={`${checked}`}
            onChange={handleCheckboxChange}
            disabled={!overAge14 && parentalAuthenticated}
            onClick={() => {
              setChecked(false)
            }}
            label={`${overAge14 ? '개인정보처리방침 이용 동의' : '법정대리인(보호자)로서 피보호자의 “학습데이터 서비스 개인정보 수집 및 이용동의” 약관에 동의합니다.'}`}
          />
          {!overAge14 && (
            <ParentalAuthenticationButton
              parentalAuthenticated={parentalAuthenticated}
              disabled={!checked || parentalAuthenticated}
              onClick={handleParentalAuthentication}
            />
          )}
          <TermsViewLink onClick={handleClickTermsOfUse} />
          <CompleteButton
            loading={isPending}
            disabled={!overAge14 ? !parentalAuthenticated : !checked}
            onClick={() => {
              if (checked) {
                mutate(checked, {
                  onSuccess: (data, variables) => {
                    navigate('/survey')
                  },
                  onError: (err) => {
                    setOpenError(true)
                    setErrorMessage(`설문 참여 진행에 실패했습니다. \n[${err.message}]`)
                  },
                })
              }
            }}
          />
          {errorToast()}
        </FormContent.Content>
      </Layout>
    </>
  )
}

function Header({ onClick }: { onClick: () => void }) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClick()
  }

  return (
    <header className='z-10 sticky top-0 flex px-[18px] pt-[13px] pb-[12px] bg-white'>
      <button type='button' onClick={handleClick}>
        <ChevronLeftIcon />
      </button>
    </header>
  )
}

function Layout({ children }: { children: ReactNode }) {
  return (
    <div className='flex flex-col pb-[160px] ml-[26px] mr-[25px] tablet-sm:mx-[78px] tablet-md:mx-[80px] tablet-lg:mx-[118px]'>
      {children}
    </div>
  )
}

function ParentalAuthenticationButton({
  parentalAuthenticated,
  disabled,
  onClick,
}: {
  parentalAuthenticated: boolean
  disabled: boolean
  onClick: () => void
}) {
  return (
    <div className='mt-[20px] text-[14px] tablet-sm:text-[16px]'>
      <Button accent='true' disabled={disabled} onClick={onClick}>
        {parentalAuthenticated ? '보호자 인증 완료' : '보호자 인증'}
      </Button>
    </div>
  )
}

function TermsViewLink({ onClick }: { onClick: () => void }) {
  return (
    <div className='mt-[20px] select-none' onClick={onClick}>
      <span className='text-brand-primary text-[14px] underline'>약관 보기</span>
    </div>
  )
}

function CompleteButton({
  loading,
  disabled,
  onClick,
}: {
  loading: boolean
  disabled: boolean
  onClick: () => void
}) {
  return (
    <div className='bg-white fixed inset-x-0 bottom-0 pl-[26px] pt-[15px] pr-[25px] pb-[25px] tablet-sm:px-[78px] tablet-sm:pt-[25px] tablet-sm:pb-[25px] tablet-md:px-[80px] tablet-md:pt-[25px] tablet-md:pb-[50px] tablet-lg:px-[118px] tablet-lg:pt-[25px] tablet-lg:pb-[80px] text-[14px] tablet-sm:text-[16px]'>
      <Button loading={loading ? 'true' : 'false'} disabled={disabled} onClick={onClick}>
        설문 시작
      </Button>
    </div>
  )
}
