import React, { useState } from 'react'

import EditNicknameModal from '../components/EditNicknameModal'
import EditSurveyFormModal from '../components/EditSurveyFormModal'
import MyAccountSetting from '../components/myAccountSettingLayout'
import { useBookcafeData } from '../module/myPage/hook'
import { useNativeInteractor } from '../module/nativeInteractor/context'
import { useSurveySteps } from '../module/survey/hook'
import { type SurveyStep } from '../module/survey/types'

export default function MyBookcafeAccount() {
  const { user } = useBookcafeData()
  const { interactor } = useNativeInteractor()
  const { setSkipLastStep } = useSurveySteps()
  const [editStep, setEditStep] = useState<SurveyStep>('userGroup')
  const [openEditSurveyModal, setOpenEditSurveyModal] = useState<boolean>(false)
  const [openEditNicknameModal, setOpenEditNicknameModal] = useState<boolean>(false)

  const handleLogout = () => {
    console.log('logout')
    interactor.serviceLogout()
  }

  return (
    <MyAccountSetting>
      <MyAccountSetting.Header />
      <MyAccountSetting.Contents title='계정 정보'>
        <MyAccountSetting.ContentsRow title='이메일'>
          <span className='text-sub-font'>{user?.email}</span>
        </MyAccountSetting.ContentsRow>
        <MyAccountSetting.ContentsRow
          title='닉네임'
          visible={user?.nickName !== user?.email}
          editable
          onClickEdit={() => {
            setOpenEditNicknameModal(true)
          }}
        >
          {user?.nickName ?? ''}
        </MyAccountSetting.ContentsRow>
        <MyAccountSetting.ContentsRow
          title='그룹'
          visible={user?.usertype !== undefined && user?.usertype !== null}
          editable
          onClickEdit={() => {
            setEditStep('userGroup')
            setSkipLastStep(false)
            setOpenEditSurveyModal(true)
          }}
        >
          {user?.usertype}
        </MyAccountSetting.ContentsRow>
        <MyAccountSetting.ContentsRow
          title='학교'
          visible={user?.group !== undefined && user?.group !== null}
          editable
          onClickEdit={() => {
            setEditStep('schoolName')
            setSkipLastStep(user?.usertype === '대학생')
            setOpenEditSurveyModal(true)
          }}
        >
          {user?.group}
        </MyAccountSetting.ContentsRow>
        <MyAccountSetting.ContentsRow
          title='준비 시험'
          visible={
            (user?.usertype === '일반인' || user?.usertype === '대학생') &&
            (user.exams === null || user.exams?.length === 0)
          }
          editable={user?.exams !== '대학수학능력시험'}
          onClickEdit={() => {
            setEditStep('examinations')
            setSkipLastStep(user?.usertype !== '대학생')
            setOpenEditSurveyModal(true)
          }}
        >
          준비중인 시험 없음
        </MyAccountSetting.ContentsRow>
        <MyAccountSetting.ContentsRow
          title='준비 시험'
          visible={user?.exams !== undefined && user?.exams !== null}
          editable={
            user?.usertype === '일반인' ||
            user?.usertype === '대학생' ||
            (user?.usertype !== '일반인' &&
              user?.usertype !== '대학생' &&
              user?.exams !== '대학수학능력시험')
          }
          onClickEdit={() => {
            setEditStep('examinations')
            setSkipLastStep(user?.usertype !== '대학생')
            setOpenEditSurveyModal(true)
          }}
        >
          <span className='line-clamp-2'>{user?.exams}</span>
        </MyAccountSetting.ContentsRow>
        <MyAccountSetting.ContentsRow title='로그아웃' onClickTitle={handleLogout} />
        <MyAccountSetting.Extensions>
          <EditNicknameModal
            open={openEditNicknameModal}
            onClose={() => {
              setOpenEditNicknameModal(false)
            }}
          />
          <EditSurveyFormModal
            open={openEditSurveyModal}
            editStep={editStep}
            onCancel={() => {
              setOpenEditSurveyModal(false)
            }}
          />
        </MyAccountSetting.Extensions>
      </MyAccountSetting.Contents>
    </MyAccountSetting>
  )
}
