import { IonModal } from '@ionic/react'
import React, {
  Suspense,
  type MouseEvent,
  type ChangeEvent,
  useState,
  lazy,
  useEffect,
} from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type CertData } from '../api/interfaces/sconnStudyData'
import { EXAM_POPULAR } from '../constants/checkboxItems'

import Button from './common/Button'
import ErrorFallback from './common/ErrorFallback'
import LoadingFallback from './common/LoadingFallback'
import SearchInput from './common/SearchInput'
import ExaminationCheckboxList from './ExaminationCheckboxList'

const ExaminationNameFilter = lazy(async () => await import('./ExaminationNameFilter'))

interface ExaminationAddModalProps {
  open: boolean
  onAdd: (e?: MouseEvent<HTMLButtonElement>, item?: CertData) => void
  onCancel: (e: MouseEvent<HTMLButtonElement>) => void
}

export default function ExaminationAddModal(props: ExaminationAddModalProps) {
  const [filterText, setFilterText] = useState<string>('')
  const [selectedSearch, setSelectedSearch] = useState<CertData | null>(null)

  useEffect(() => {
    setFilterText('')
    setSelectedSearch(null)
  }, [props.open])

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value)
    setSelectedSearch(null)
  }

  const handleFilterListItemClick = (item: CertData) => {
    setFilterText(item.name)
    setSelectedSearch(item)
  }

  const handleExaminationCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedId = e.target.value
    const selectedItem = EXAM_POPULAR.filter((i) => i.id === selectedId)
    if (selectedItem) {
      props.onAdd(undefined, selectedItem[0])
    }
  }

  return (
    <IonModal id='sheet-modal' keepContentsMounted isOpen={props.open} backdropDismiss={false}>
      <div className='flex flex-col h-full p-[30px]'>
        <div className='flex items-center min-w-[50px] justify-end'>
          <button type='button' className='text-cancel-font' onClick={props.onCancel}>
            취소
          </button>
        </div>
        <SearchInput
          className='mt-[20px]'
          label='시험분야'
          id='schoolName'
          name='schoolName'
          placeholder='시험명을 입력해 주세요.'
          searchicon='true'
          value={filterText}
          onChange={handleTextChange}
        />
        <div className='overflow-auto grow' style={{ maxHeight: 'calc(100% - 60px)' }}>
          {!filterText && !selectedSearch && (
            <div className='py-[25px] px-[19px]'>
              <span className='text-[13px]'>인기 분야들이에요.</span>
              <ExaminationCheckboxList
                items={EXAM_POPULAR.map((item) => ({ ...item, checked: false }))}
                onChange={handleExaminationCheckboxChange}
              />
            </div>
          )}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={(filterText || selectedSearch) && <LoadingFallback />}>
              <ExaminationNameFilter
                filterText={filterText}
                selectedItem={selectedSearch}
                onListItemClick={handleFilterListItemClick}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
        {selectedSearch && (
          <Button
            type='button'
            disabled={!selectedSearch}
            onClick={(e) => {
              if (selectedSearch !== null) {
                props.onAdd(e, selectedSearch)
              }
            }}
          >
            확인
          </Button>
        )}
      </div>
    </IonModal>
  )
}
