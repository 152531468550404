import React, { useEffect, useState } from 'react'

import { useSurvey, useSurveySteps } from '../../module/survey/hook'

export default function ProgressBar() {
  const { surveyOnModal } = useSurvey()
  const { steps, userGroup, activeStep } = useSurveySteps()
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    setWidth(
      userGroup === 'general'
        ? (activeStep / steps.length) * 100
        : ((activeStep + 1) / steps.length) * 100,
    )
  }, [userGroup, activeStep, steps])

  return (
    <>
      {!surveyOnModal && (
        <div className='px-[65px] py-[5px] tablet-sm:px-[115px] tablet-md:px-[80px] tablet-lg:px-[235px]'>
          <div className='w-full rounded-[40px] h-[15px] bg-progress-background'>
            <div
              className='h-full rounded-[40px] bg-brand-primary'
              style={{ width: `${width}%` }}
            ></div>
          </div>
        </div>
      )}
    </>
  )
}
