import React, { useState, useEffect } from 'react'

import { useSurvey } from '../module/survey/hook'

import SearchInput from './common/SearchInput'
import FormContent from './surveyFormContentLayout'
import YearSelectModal from './YearSelectModal'

export default function YearSelect() {
  const { latestResult, year, setYear } = useSurvey()
  const [open, setOpen] = useState<boolean>(false)
  const [filterText, setFilterText] = useState<string>(year ?? new Date().getFullYear().toString())
  const [selectedSearch, setSelectedSearch] = useState<string>(
    year ?? new Date().getFullYear().toString(),
  )

  useEffect(() => {
    if (latestResult) {
      setFilterText(latestResult.group?.year ?? year ?? new Date().getFullYear().toString())
      setSelectedSearch(latestResult.group?.year ?? year ?? new Date().getFullYear().toString())
    }
  }, [latestResult])

  return (
    <FormContent>
      <FormContent.Grow>
        <FormContent.Question
          title='대학교 입학년도를 알려주세요!'
          description='입학 동기들과 학습 시간을 비교해볼 수 있어요.'
        />
        <SearchInput
          className='mt-[50px]'
          id='year'
          name='year'
          required
          readOnly
          value={`${selectedSearch}년`}
          onChange={(e) => {
            setFilterText(e.target.value)
          }}
          onFocus={() => {
            setFilterText(selectedSearch)
            setOpen(true)
          }}
          onKeyDown={(e) => {
            e.preventDefault()
          }}
          label='입학년도'
        />
      </FormContent.Grow>
      <FormContent.ProcessButton
        disabled={!selectedSearch}
        onNextClick={() => {
          if (selectedSearch) {
            setYear(selectedSearch)
          }
        }}
      />
      <YearSelectModal
        open={open}
        onOk={() => {
          setOpen(false)
          setSelectedSearch(filterText)
        }}
        onCancel={() => {
          setOpen(false)
          setFilterText(year ?? new Date().getFullYear().toString())
        }}
        year={`${filterText}`}
        onChangeYear={(e) => {
          if (typeof e.detail.value === 'string') {
            const year = e.detail.value.split('-')[0]
            setFilterText(year)
          }
        }}
      />
    </FormContent>
  )
}
