import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  type SconnStudyData,
  type Highschool,
  type Middleschool,
  type University,
  type UniversityMajor,
  type SchoolClass,
  type CertData,
  type LatestSurvey,
} from '../../api/interfaces/sconnStudyData'
import { type CheckboxItemCertData } from '../../components/ExaminationSelect'

import { SurveyDispatchContext, SurveyStateContext } from './context'
import {
  type OkNo,
  type UserGroup,
  type SurveyStep,
  SURVEY_NEXT_STEPS,
  SURVEY_PREV_STEPS,
  SURVEY_SET_CHECKBOX_IS_UNIVERSITY,
  SURVEY_SET_CLASSROOM,
  SURVEY_SET_EXAMINATION,
  SURVEY_SET_EXAMINATION_CHECKBOX_ITEM,
  SURVEY_SET_EXAMINATION_HAS_USER_SEARCH,
  SURVEY_SET_GRADE,
  SURVEY_SET_HIGHSCHOOL,
  SURVEY_SET_SKIP_FIRST_STEP,
  SURVEY_SET_MAJOR,
  SURVEY_SET_MIDDLESCHOOL,
  SURVEY_SET_STEPS,
  SURVEY_SET_UNIVERSITY,
  SURVEY_SET_USER_GROUP,
  SURVEY_SET_CHECKED_USER_GROUP,
  SURVEY_SET_YEAR,
  SURVEY_SET_IS_PENDING,
  SURVEY_SET_ON_MODAL,
  SURVEY_SET_LATEST_RESULT,
  SURVEY_SET_SKIP_LAST_STEP,
  SURVEY_SET_START_STEP,
} from './types'

function useSurveyState() {
  const state = useContext(SurveyStateContext)
  if (state === null) throw new Error('SurveyProvider not found.')
  return state
}

function useSurveyDispatch() {
  const dispatch = useContext(SurveyDispatchContext)
  if (dispatch === null) throw new Error('SurveyProvider not found.')
  return dispatch
}

export function useSurvey() {
  const {
    surveyed: {
      userType,
      university,
      middleschool,
      highschool,
      grade,
      major,
      year,
      classroom,
      examinations,
    },
    checkedUserGroup,
    checkboxIsUniversity,
    surveyIsPending,
    surveyOnModal,
    latestResult,
    surveyResult,
  } = useSurveyState()
  const dispatch = useSurveyDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const userSchool =
    userType === 'UC'
      ? university
      : userType === 'M'
        ? middleschool
        : userType === 'H'
          ? highschool
          : null

  function setUserSchool(payload: SconnStudyData) {
    if (userType === 'UC') {
      dispatch({ type: SURVEY_SET_UNIVERSITY, payload: payload as University })
    } else if (userType === 'M') {
      dispatch({ type: SURVEY_SET_MIDDLESCHOOL, payload: payload as Middleschool })
    } else if (userType === 'H') {
      dispatch({ type: SURVEY_SET_HIGHSCHOOL, payload: payload as Highschool })
    } else {
      console.log('Not exist school info in this user type', userType)
    }

    addSearchParams('school', payload.code)
  }

  function setMajor(payload: UniversityMajor) {
    dispatch({ type: SURVEY_SET_MAJOR, payload })
    addSearchParams('major', payload.code)
  }

  function setYear(payload: string) {
    dispatch({ type: SURVEY_SET_YEAR, payload })
    addSearchParams('year', `${payload}`)
  }

  function setGrade(payload: SchoolClass[]) {
    dispatch({ type: SURVEY_SET_GRADE, payload })
    addSearchParams('grade', `${payload[0].grade}`)
  }

  function setClassroom(payload: SchoolClass | null) {
    dispatch({ type: SURVEY_SET_CLASSROOM, payload })

    if (payload !== null) {
      addSearchParams('classroom', payload.class ?? payload.department)
    }
  }

  function setExaminations(payload: CertData[]) {
    dispatch({ type: SURVEY_SET_EXAMINATION, payload })
    appendSearchParams(
      'exam',
      payload.map((v) => v.id),
    )
  }

  function setCheckboxIsUniversity(payload: OkNo) {
    dispatch({ type: SURVEY_SET_CHECKBOX_IS_UNIVERSITY, payload })
    addSearchParams('isUniversity', payload)
  }

  function setCheckedUserGroup(payload: UserGroup | null) {
    dispatch({ type: SURVEY_SET_CHECKED_USER_GROUP, payload })
  }

  function setSurveyIsPending(payload: boolean) {
    dispatch({ type: SURVEY_SET_IS_PENDING, payload })
  }

  function setSurveyOnModal(payload: boolean) {
    dispatch({ type: SURVEY_SET_ON_MODAL, payload })
  }

  function setLatestSurvey(payload: LatestSurvey) {
    dispatch({ type: SURVEY_SET_LATEST_RESULT, payload })
  }

  function addSearchParams(key: string, value: string) {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  function appendSearchParams(key: string, values: string[]) {
    values.forEach((value) => {
      searchParams.append(key, value)
    })
    setSearchParams(searchParams)
  }

  return {
    userType,
    userSchool,
    university,
    middleschool,
    highschool,
    major,
    year,
    grade,
    classroom,
    examinations,
    checkboxIsUniversity,
    checkedUserGroup,
    surveyIsPending,
    surveyOnModal,
    latestResult,
    surveyResult,
    setUserSchool,
    setMajor,
    setYear,
    setGrade,
    setClassroom,
    setExaminations,
    setCheckboxIsUniversity,
    setCheckedUserGroup,
    setSurveyIsPending,
    setSurveyOnModal,
    setLatestSurvey,
  }
}

export function useExaminationPage() {
  const { hasUserSearch, checkboxExaminations } = useSurveyState()
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useSurveyDispatch()

  function setHasUserSearch(hasUserSearch: boolean) {
    dispatch({ type: SURVEY_SET_EXAMINATION_HAS_USER_SEARCH, payload: hasUserSearch })

    if (hasUserSearch) {
      searchParams.set('hasUserSearch', 'true')
      setSearchParams(searchParams)
    } else {
      searchParams.delete('hasUserSearch')
      setSearchParams(searchParams)
    }
  }

  function setCheckboxExaminations(list: CheckboxItemCertData[]) {
    dispatch({ type: SURVEY_SET_EXAMINATION_CHECKBOX_ITEM, payload: list })
  }

  return {
    hasUserSearch,
    checkboxExaminations,
    setHasUserSearch,
    setCheckboxExaminations,
  }
}

export function useSurveySteps() {
  const { steps, userGroup, activeStep, isSkipLastStep } = useSurveyState()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useSurveyDispatch()

  function setUserGroup(userGroup: UserGroup, startStep?: SurveyStep) {
    dispatch({ type: SURVEY_SET_USER_GROUP, payload: userGroup })
    setUserGroupStep(userGroup, startStep)

    searchParams.set('userGroup', userGroup)
    setSearchParams(searchParams)
  }

  function setUserGroupStep(userGroup: UserGroup, startStep?: SurveyStep) {
    dispatch({ type: SURVEY_SET_STEPS, payload: userGroup })
  }

  function setNextStep() {
    dispatch({ type: SURVEY_NEXT_STEPS })
  }

  function setPrevStep() {
    dispatch({ type: SURVEY_PREV_STEPS })

    const currentStep = steps[activeStep]
    switch (currentStep) {
      case 'classroom':
        searchParams.delete('classroom')
        break
      case 'examinations':
      case 'examinationsCollege':
        searchParams.delete('exam')
        searchParams.delete('hasUserSearch')
        searchParams.delete('isUniversity')
        break
      case 'grade':
        searchParams.delete('grade')
        break
      case 'major':
        searchParams.delete('major')
        break
      case 'year':
        searchParams.delete('year')
        break
      case 'schoolName':
        searchParams.delete('school')
        break
    }
    setSearchParams(searchParams)
  }

  function setSkipFirstStep(skip: boolean) {
    dispatch({ type: SURVEY_SET_SKIP_FIRST_STEP, payload: skip })
  }

  function setSkipLastStep(skip: boolean) {
    dispatch({ type: SURVEY_SET_SKIP_LAST_STEP, payload: skip })
  }

  function setStartStep(step: SurveyStep) {
    dispatch({ type: SURVEY_SET_START_STEP, payload: step })
  }

  return {
    steps,
    userGroup,
    activeStep,
    isSkipLastStep,
    setNextStep,
    setPrevStep,
    setStartStep,
    setUserGroup,
    setUserGroupStep,
    setSkipFirstStep,
    setSkipLastStep,
  }
}
