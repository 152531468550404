import React, { type MouseEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useExaminationPage, useSurvey, useSurveySteps } from '../../module/survey/hook'
import { ChevronLeftIcon } from '../icons/icon'

export default function Header() {
  const location = useLocation()
  const navigate = useNavigate()
  const { examinations } = useSurvey()
  const { steps, activeStep, setPrevStep } = useSurveySteps()
  const { hasUserSearch, setHasUserSearch } = useExaminationPage()
  const currentStep = steps[activeStep]

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (location.pathname === '/survey/complete') {
      navigate(-1)
      return
    }

    if (activeStep === 0) {
      navigate('/my-page')
      return
    }

    if (currentStep === 'examinations' && hasUserSearch && !examinations) {
      setHasUserSearch(false)
    }

    setPrevStep()
  }

  return (
    <header className='z-10 sticky top-0 flex px-[18px] pt-[13px] pb-[12px] bg-white'>
      <button type='button' onClick={handleClick}>
        <ChevronLeftIcon />
      </button>
    </header>
  )
}
