import React from 'react'
import { Outlet } from 'react-router-dom'

export default function MyPage() {
  return (
    <section className='flex flex-col'>
      <Outlet />
    </section>
  )
}
