import { IonContent, IonModal } from '@ionic/react'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useUserSurvey } from '../module/sconnStudyData/hook'
import { getUserGroup } from '../module/survey/helper'
import { useExaminationPage, useSurvey, useSurveySteps } from '../module/survey/hook'
import { type SurveyStep } from '../module/survey/types'

import { ChevronLeftIcon } from './icons/icon'
import SurveyForm from './SurveyForm'

interface EditSurveyFormModalProps {
  open: boolean
  editStep: SurveyStep
  onCancel: () => void
}

export default function EditSurveyFormModal(props: EditSurveyFormModalProps) {
  const location = useLocation()
  const contentRef = useRef<HTMLIonContentElement>(null)
  const [keyForResetting, setKey] = useState<number>(0)
  const { data } = useUserSurvey()

  const { latestResult, examinations, setLatestSurvey } = useSurvey()
  const { steps, activeStep, setUserGroup, setPrevStep, setStartStep } = useSurveySteps()
  const { hasUserSearch, setHasUserSearch } = useExaminationPage()
  const currentStep = steps[activeStep]

  useEffect(() => {
    if (data) {
      setLatestSurvey(data)
    }
  }, [data])

  useEffect(() => {
    if (props.open) {
      setKey((prev) => ++prev)
    } else {
      setStartStep('userGroup')
    }
  }, [props.open])

  useEffect(() => {
    contentRef.current?.scrollToTop(300)
  }, [location])

  const headerOnClick = () => {
    if (activeStep === 0) {
      props.onCancel()
    }

    if (currentStep === 'examinations' && hasUserSearch && !examinations) {
      setHasUserSearch(false)
    }

    setPrevStep()
  }

  const handleFormLoad = () => {
    if (latestResult?.userType) {
      if (props.editStep !== 'userGroup') {
        const userGroup = getUserGroup(latestResult?.userType) ?? 'college'
        setUserGroup(userGroup)
      }
      setStartStep(props.editStep)
    }
  }

  return (
    <IonModal id='sheet-modal' keepContentsMounted isOpen={props.open} backdropDismiss={false}>
      <div className='flex flex-col h-full px-[30px] tablet-sm:px-[30px]'>
        <div
          className={classNames(
            'flex items-center min-w-[50px]  h-[50px] tablet-sm:h-[60px]',
            currentStep !== props.editStep ? 'justify-between' : 'justify-end',
          )}
        >
          {currentStep !== props.editStep && (
            <ChevronLeftIcon className='ml-[-12px] tablet-sm:ml-[0px]' onClick={headerOnClick} />
          )}
          <button type='button' className='text-cancel-font' onClick={props.onCancel}>
            취소
          </button>
        </div>
        <IonContent ref={contentRef}>
          <SurveyForm
            key={keyForResetting}
            onFormLoad={handleFormLoad}
            onSubmitSuccess={() => {
              props.onCancel()
            }}
          />
        </IonContent>
      </div>
    </IonModal>
  )
}
