import React, { useEffect, useState } from 'react'

import { BAR_OPTION } from '../../constants/chartOption'

import ErrorFallback from './ErrorFallback'
import NoDataEchart from './NoDataEchart'

interface ErrorFallbackProps {
  error: any
  className: string
}

/**
 * no data
 * false -> error 로딩 ~ -> true
 */
export default function ChartErrorFallback({ error, className }: ErrorFallbackProps) {
  const [today, setToday] = useState<Date>()
  const [isNoData, setNoData] = useState<boolean>(false)

  useEffect(() => {
    if (error.error.message === 'NO_DATA') {
      const now = new Date()
      setToday(now)
      setNoData(true)
    } else {
      setNoData(false)
    }
  }, [error])

  // TODO: nodata 말고 다른에러 확인 필요
  // MEMO: h-399; layout shift 방지
  return (
    <div className={`${!isNoData && 'h-[399px]'}`}>
      {isNoData ? (
        <NoDataEchart
          chartOption={BAR_OPTION}
          className={className}
          criteriaDate={today ?? new Date()}
        />
      ) : (
        <ErrorFallback error={error} />
      )}
    </div>
  )
}
