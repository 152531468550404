import { type SuggestBook } from '../api/interfaces/sconnStudyData'

export const popularBooks: SuggestBook[] = [
  { productId: 0, title: '2020 임상의학종합평가360 dfsdfsd 안녕', thumbnail: '/img/book1.jpeg' },
  {
    productId: 1,
    title: '2020 임상의학종합평가360제임상의학종합평...',
    thumbnail: '/img/book2.jpeg',
  },
  {
    productId: 2,
    title: '2020 임상의학종합평가360제임상의학종합평가가가가가가가',
    thumbnail: '/img/book1.jpeg',
  },
  {
    productId: 3,
    title: '2020 임상의학종합평가360제임상의학종합평임상의학종합평가360제임상의학종합평',
    thumbnail: '/img/book2.jpeg',
  },
  {
    productId: 4,
    title: '2020 임상의학종합평가360제임상의학종합평...',
    thumbnail: '/img/book1.jpeg',
  },
]

export const rankProfiles = [
  {
    rank: 6,
    srcSet: '/img/level/Cake.webp',
    src: '/img/level/Cake.png',
    alt: 'Cake',
    label: 'Lv.4 공부 좀 한 스콘',
    studyTime: '4h',
  },
  {
    rank: 7,
    srcSet: '/img/level/SconnIcon1.webp',
    src: '/img/level/SconnIcon1.png',
    alt: 'SconnIcon1',
    label: 'Lv.2 공부초보 스콘',
    studyTime: '3.5h',
  },
  {
    rank: 8,
    srcSet: '/img/level/Sconn1.webp',
    src: '/img/level/Sconn1.png',
    alt: 'Sconn1',
    label: 'Lv.3 공부에 재미붙은 스콘',
    studyTime: '3h',
    focus: true,
  },
  {
    rank: 9,
    srcSet: '/img/level/NormalSconn1.webp',
    src: '/img/level/NormalSconn1.png',
    alt: 'NormalSconn1',
    label: 'Lv.2 공부초보 스콘',
    studyTime: '2.5h',
  },
  {
    rank: 10,
    srcSet: '/img/level/SconnIcon1.webp',
    src: '/img/level/SconnIcon1.png',
    alt: 'SconnIcon1',
    label: 'Lv.4 공부 좀 한 스콘',
    studyTime: '2.4h',
  },
]
