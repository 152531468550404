import {
  type Action,
  type MyPageState,
  MYPAGE_SET_USER,
  MYPAGE_SET_IS_ONLINE,
  MYPAGE_SET_SURVEY_PARTICIPATED,
  MYPAGE_SET_STATS_COMPLETE,
  MYPAGE_SET_STATS_UPDATED_AT,
  MYPAGE_SET_STATS_PERIOD,
  MYPAGE_SET_STATS_START_DATE,
  MYPAGE_SET_STATS_END_DATE,
  MYPAGE_SET_STATS_SELECTED_DATE,
  MYPAGE_SET_STATS_DURATION,
  MYPAGE_SET_STATS_DATE_RANGE_LABEL,
  MYPAGE_SET_STATS_IS_NO_CONTENTS,
  MYPAGE_SET_STATS_IS_TODAY,
  MYPAGE_SET_STATS_DISPLAY_CONTENTS,
} from './types'

export function myPageReducer(state: MyPageState, action: Action): MyPageState {
  switch (action.type) {
    case MYPAGE_SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case MYPAGE_SET_IS_ONLINE:
      return {
        ...state,
        isOnline: action.payload,
      }
    case MYPAGE_SET_SURVEY_PARTICIPATED:
      return {
        ...state,
        surveyParticipated: action.payload,
      }
    case MYPAGE_SET_STATS_COMPLETE:
      return {
        ...state,
        statsComplete: action.payload,
      }
    case MYPAGE_SET_STATS_PERIOD:
      return {
        ...state,
        statsPeriod: action.payload,
      }
    case MYPAGE_SET_STATS_DATE_RANGE_LABEL:
      return {
        ...state,
        statsDateRangeLabel: action.payload,
      }
    case MYPAGE_SET_STATS_UPDATED_AT:
      return {
        ...state,
        statsUpdatedAt: action.payload,
      }
    case MYPAGE_SET_STATS_SELECTED_DATE:
      return {
        ...state,
        statsSelectedDate: action.payload,
      }
    case MYPAGE_SET_STATS_START_DATE:
      return {
        ...state,
        statsStartDate: action.payload,
      }
    case MYPAGE_SET_STATS_END_DATE:
      return {
        ...state,
        statsEndDate: action.payload,
      }
    case MYPAGE_SET_STATS_DURATION:
      return {
        ...state,
        statsDuration: action.payload,
      }
    case MYPAGE_SET_STATS_IS_NO_CONTENTS:
      return {
        ...state,
        statsIsNoContents: action.payload,
      }
    case MYPAGE_SET_STATS_IS_TODAY:
      return {
        ...state,
        statsIsToday: action.payload,
      }
    case MYPAGE_SET_STATS_DISPLAY_CONTENTS:
      return {
        ...state,
        statsDisplayContents: action.payload,
      }
    default:
      throw Error('Not Exist Action In MyPageReducer')
  }
}
