import React, { type ChangeEvent, useState, type MouseEvent, useMemo, useEffect } from 'react'

import { type CertData } from '../api/interfaces/sconnStudyData'
import { useContentRef } from '../App'
import { EXAM_OPTIONS, EXAM_PRESET } from '../constants/checkboxItems'
import { useExaminationPage, useSurvey } from '../module/survey/hook'
import { useInfoToast } from '../util/hooks/useToast'

import ExaminationAddModal from './ExaminationAddModal'
import ExaminationCheckboxList from './ExaminationCheckboxList'
import ExaminationUserSearchList from './ExaminationUserSearchList'
import FormContent from './surveyFormContentLayout'

export interface CheckboxItemCertData extends CertData {
  checked: boolean
}

export default function ExaminationSelect() {
  const {
    hasUserSearch,
    setHasUserSearch,
    checkboxExaminations: list,
    setCheckboxExaminations: setList,
  } = useExaminationPage()
  const contentRef = useContentRef()
  const { latestResult, surveyOnModal, examinations, setExaminations } = useSurvey()
  const { setOpenToast, setMessage, renderToast } = useInfoToast()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [nextClicked, setNextClicked] = useState<boolean>(false)
  const noSelectInList = EXAM_OPTIONS.find((option) => option.code === 'none')
  const checked: CheckboxItemCertData[] | null = useMemo(
    () => list.filter((item) => item.checked),
    [list],
  )

  useEffect(() => {
    setNextClicked(false)
    setList(EXAM_PRESET.map((item) => ({ ...item, checked: false })))
  }, [])

  useEffect(() => {
    if (latestResult?.exams) {
      const res: CheckboxItemCertData[] = latestResult.exams.map((item) => ({
        id: item.id,
        name: item.name,
        code: item.id,
        type: '',
        keyWords: '',
        manageHostName: '',
        checked: true,
      }))
      setHasUserSearch(true)
      setList(res ?? [])
    }
  }, [latestResult])

  useEffect(() => {
    if (examinations && !nextClicked) {
      const selectedIds = examinations.map((item) => item.id)
      const presetIds = EXAM_PRESET.map((item) => item.id)

      if (selectedIds.every((item) => presetIds.includes(item))) {
        setHasUserSearch(false)
        setList(EXAM_PRESET.map((item) => ({ ...item, checked: !!selectedIds.includes(item.id) })))
      } else {
        setHasUserSearch(true)
        setList(examinations.map((item) => ({ ...item, checked: true })))
      }
    }
  }, [examinations])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedId = e.target.value

    if (selectedId === noSelectInList?.id) {
      setList(
        list.map((item) => ({
          ...item,
          checked: selectedId === item.id,
        })),
      )
    } else {
      setList(
        list.map((item) => ({
          ...item,
          checked:
            item.id === noSelectInList?.id
              ? false
              : selectedId === item.id
                ? !item.checked
                : item.checked,
        })),
      )
    }
  }

  const handleAddItemClick = (e?: MouseEvent<HTMLButtonElement>, selectedItem?: CertData) => {
    e?.preventDefault()

    if (!selectedItem) {
      return
    }

    if (!list.map((item) => item.id).includes(selectedItem.id)) {
      list.splice(list.length - 2, 0, { ...selectedItem, checked: true }) // insert
      setList(
        list.map((item) => ({
          ...item,
          checked: item.id === noSelectInList?.id ? false : item.checked,
        })),
      )
    } else {
      const index = list.findIndex((item) => item.id === selectedItem.id && item.checked)
      if (index !== -1) {
        setOpenToast(true)
        setMessage('이미 등록된 시험입니다.')
      }

      setList(
        list.map((item) => ({
          ...item,
          checked: selectedItem.id === item.id ? true : item.checked,
        })),
      )
    }

    contentRef?.current?.scrollToBottom(300)
    setOpenModal(false)
  }

  const handleClickSearchMore = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setList(
      list.map((item) => (item.id === noSelectInList?.id ? { ...item, checked: false } : item)),
    )

    setHasUserSearch(true)
    setOpenModal(true)
  }

  const handleRemoveItem = (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.preventDefault()

    setList(
      list.map((item) => ({
        ...item,
        checked: id === item.id ? false : item.checked,
      })),
    )
  }

  return (
    <FormContent>
      <FormContent.Grow>
        <FormContent.Question
          title='준비중인 시험이 있으세요?'
          description='최대 4개 선택할 수 있어요.'
        />
        {hasUserSearch ? (
          <ExaminationUserSearchList
            items={list.filter((item) => item.checked && item.id !== noSelectInList?.id)}
            disabledAdd={checked.length > 3}
            onChange={handleChange}
            onClickAddItem={(e) => {
              e.preventDefault()
              setOpenModal(true)
            }}
            onClickRemoveItem={handleRemoveItem}
          />
        ) : (
          <div className='mt-[30px]'>
            <ExaminationCheckboxList
              items={list}
              disabledAdd={checked.length > 3}
              onChange={handleChange}
              onClickSearchMore={handleClickSearchMore}
            />
          </div>
        )}
        <ExaminationAddModal
          open={openModal}
          onAdd={handleAddItemClick}
          onCancel={(e) => {
            e.preventDefault()
            setOpenModal(false)
          }}
        />
        {renderToast()}
      </FormContent.Grow>
      <FormContent.ProcessButton
        disabled={
          (!surveyOnModal && (checked === null || checked.length === 0)) || checked.length > 4
        }
        onNextClick={() => {
          if (checked !== null && checked.length !== 0) {
            setNextClicked(true)
            setExaminations(checked)
          } else {
            if (surveyOnModal && noSelectInList) {
              setExaminations([noSelectInList])
            }
          }
        }}
      />
    </FormContent>
  )
}
