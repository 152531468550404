import classNames from 'classnames'
import React, { type InputHTMLAttributes, type ChangeEvent } from 'react'

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  description?: string
}

export default function Radio(props: RadioProps) {
  return (
    <div
      className={classNames(
        'w-full border-solid rounded-[12px] tablet-sm:rounded-[7px] border-[0.5px]',
        props.checked ? 'border-main-border shadow-selected' : 'border-sub-border shadow-none',
      )}
    >
      <input {...props} className='hidden' type='radio' id={`${props.id}`} />
      <label
        htmlFor={`${props.id}`}
        className={`block text-center p-[15px] ${props.checked ? 'font-bold' : 'font-normal'}`}
      >
        <p className='line-clamp-1 text-[15px] tablet-sm:text-[16px]'>{props.label}</p>
        {props.description && <p className='text-[14px] text-sub-border'>{props.description}</p>}
      </label>
    </div>
  )
}
