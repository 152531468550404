import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../components/common/Button'

export default function NotFound() {
  const navigate = useNavigate()
  return (
    <div className='flex flex-col w-full h-screen p-10 font-extrabold'>
      <h1>페이지를 찾을 수 없습니다.</h1>
      <div className='flex-grow'></div>
      <Button
        onClick={() => {
          navigate('/my-page')
        }}
      >
        마이페이지로 돌아가기
      </Button>
    </div>
  )
}
