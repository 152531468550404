import React, { useEffect, useMemo, useRef, useState } from 'react'

import { type WorkspaceStats } from '../api/interfaces/sconnStudyData'
import { BAR_OPTION, WEEK_AXIS_DATA } from '../constants/chartOption'
import { useStudyingReport } from '../module/myPage/hook'
import { processData } from '../module/myPage/statsHelper'
import { type PeriodType } from '../module/myPage/types'
import { useUserWorkspaceStatistics } from '../module/sconnStudyData/hook'
import { CustomDate, type DateRange } from '../util/CustomDate'
import { removeSeconds } from '../util/numberUtils'

import Echart from './common/Echart'
import { CloseIcon, HelpIcon, HelpPopoverIcon } from './icons/icon'

const periodRange: Record<
  PeriodType,
  (
    selectedDate: CustomDate | null,
    startDate: CustomDate | null,
    endDate: CustomDate | null,
  ) => DateRange | null
> = {
  daily: (selectedDate) => selectedDate?.getLastSevenDaysRange() ?? null,
  weekly: (_, startDate, endDate) =>
    endDate?.getLastFourWeeksRange(startDate ?? new CustomDate()) ?? null,
  monthly: (selectedDate) => selectedDate?.getLastFourMonthsRange() ?? null,
}

interface AxisData {
  xAxis: number[]
  series: number[]
  yAxisMax: number
}

interface StudyingTimeChartProps {
  className: string
}

export default function StudyingTimeChart({ className }: StudyingTimeChartProps) {
  const {
    statsPeriod,
    statsSelectedDate,
    statsStartDate,
    statsEndDate,
    setStatsUpdatedAt,
    setStatsDuration,
  } = useStudyingReport()
  const [rangeParam, setRangeParam] = useState<DateRange>(
    (statsSelectedDate ?? new CustomDate().setYesterday()).getLastFourMonthsRange(),
  )
  const { data } = useUserWorkspaceStatistics(rangeParam)
  const [isUnderHour, setUnderHour] = useState<boolean>(false)
  const [axisData, setAxisData] = useState<AxisData>(WEEK_AXIS_DATA)

  useEffect(() => {
    const statsData = statsPeriod === 'daily' ? data : processData(data, statsPeriod)
    processSetAxisData(statsData)

    setStatsUpdatedAt(data.latestTimeStamp)
    setStatsDuration(statsData.durations[statsData.durations.length - 1])
  }, [data])

  useEffect(() => {
    const range = periodRange[statsPeriod](statsSelectedDate, statsStartDate, statsEndDate)
    if (range) {
      setRangeParam(range)
    }
  }, [statsPeriod, statsSelectedDate, statsStartDate, statsEndDate])

  const processSetAxisData = (data: WorkspaceStats) => {
    const secondsDurations = data.durations.map((i) => removeSeconds(i))
    const hourDurations = secondsDurations.map((i) => i / 3600)
    const minuteDurations = secondsDurations.map((i) => i / 60)

    let yAxisMax = Math.max(...hourDurations)

    if (yAxisMax < 1) {
      yAxisMax = Math.ceil(Math.max(...minuteDurations))

      setUnderHour(true)
      setAxisData({
        series: minuteDurations,
        xAxis: data.timestamps,
        yAxisMax: yAxisMax % 2 === 0 ? yAxisMax : yAxisMax + 1,
      })
    } else {
      yAxisMax = Math.ceil(yAxisMax)

      setUnderHour(false)
      setAxisData({
        series: hourDurations,
        xAxis: data.timestamps,
        yAxisMax: yAxisMax % 2 === 0 ? yAxisMax : yAxisMax + 1,
      })
    }
  }

  const option = useMemo(
    () => ({
      ...BAR_OPTION,
      tooltip: {
        ...BAR_OPTION.tooltip,
        formatter: (params: any) => tooltipFomatter(params, isUnderHour, statsPeriod),
      },
      xAxis: {
        ...BAR_OPTION.xAxis,
        data: axisData.xAxis,
        axisLabel: {
          ...BAR_OPTION.xAxis.axisLabel,
          formatter: (value: string, index: number) =>
            xAxisFomatter(value, index, axisData.xAxis.length, statsPeriod),
        },
      },
      yAxis: {
        ...BAR_OPTION.yAxis,
        min: 0,
        max: axisData.yAxisMax === 0 ? 4 : axisData.yAxisMax,
        interval: axisData.yAxisMax === 0 ? 1 : axisData.yAxisMax / 4,
        axisLabel: {
          ...BAR_OPTION.yAxis.axisLabel,
          formatter: (value: number) => yAxisFormatter(value, isUnderHour),
        },
      },
      series: [
        {
          ...BAR_OPTION.series[0],
          data: axisData.series,
        },
      ],
    }),
    [axisData, isUnderHour, statsPeriod],
  )

  return (
    <>
      <ChartTitle />
      <Echart chartOption={option} className={className} />
    </>
  )
}

export function ChartTitle() {
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const popoverRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setOpenPopover(false)
    }
  }

  useEffect(() => {
    if (openPopover) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openPopover])

  return (
    <div className='relative flex gap-[5px] items-center mt-[25px]'>
      <span className='font-medium text-[11px] tablet-sm:text-[13px] text-[#BBBBBB]'>
        학습시간 통계
      </span>
      <HelpIcon
        onClick={() => {
          setOpenPopover(true)
        }}
      />
      {openPopover && (
        <div
          ref={popoverRef}
          className='absolute gap-[15px] top-[30px] left-[80px] z-10 flex flex-col bg-white p-[10px] rounded-[5px] border-[2px] border-brand-primary'
        >
          <div className='flex items-center justify-between'>
            <div className='flex gap-[6px] px-[5px] pt-[5px]'>
              <HelpPopoverIcon />
              <span className='text-[14px] font-extrabold'>학습시간 통계</span>
            </div>
            <CloseIcon
              onClick={() => {
                setOpenPopover(false)
              }}
            />
          </div>
          <div className='px-[10px] pb-[10px]'>
            <span className='text-[13] font-medium'>
              <p>문서를 보고 있는 시간만 측정합니다.</p>
              <p>그 외의 화면에 머물러있는 시간은 측정하지 않아요.</p>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

function xAxisFomatter(value: string, index: number, axisLength: number, statsPeriod: PeriodType) {
  const timestamp = +value
  const date = new CustomDate(timestamp)

  if (statsPeriod === 'daily') {
    const monthDay = date.toMonthDay('/', false)
    return index === axisLength - 1 ? `{a|${monthDay}}` : `{b|${monthDay}}`
  }

  if (statsPeriod === 'monthly') {
    const year = date.getFullYear().toString().slice(-2)
    const month = date.getMonth() + 1

    let monthString = `${month}월`
    if (month >= 10 + index) {
      monthString = `${year}년 ${month}월`
    }

    return index === axisLength - 1 ? `{a|${monthString}}` : `{b|${monthString}}`
  }

  if (statsPeriod === 'weekly') {
    const weekNumber = `${date.getMonth() + 1}월 ${date.getWeekNumberOfMonth()}주`
    return index === axisLength - 1 ? `{a|${weekNumber}}` : `{b|${weekNumber}}`
  }
}

function tooltipFomatter(params: any, isUnderHour: boolean, period: PeriodType) {
  const timestamp = +params.name
  const date = new CustomDate(timestamp)
  const name =
    period === 'daily'
      ? date.toMonthDay('.')
      : period === 'monthly'
        ? date.toChartMonthRange()
        : date.toChartWeekRange()

  let time = ''
  if (isUnderHour) {
    time = params.value !== 0 ? `${params.value}분` : '0분'
  } else {
    const hourDuration = params.value
    const hour = Math.floor(hourDuration)
    const minutes = Math.round((hourDuration - hour) * 60)
    time = `${hour !== 0 ? `${hour}시간 ` : ''}
            ${minutes !== 0 ? `${minutes}분` : ''}
            ${hour === 0 && minutes === 0 ? '0분' : ''}`
  }

  return `<div class='tooltip-container'><span class='tooltip-title'>${name}</span>
    <span class='tooltip-value'>${time}</span><div>`
}

function yAxisFormatter(value: number, isUnderHour: boolean) {
  return value === 0 ? '' : `${value}${isUnderHour ? 'm' : 'h'}`
}
