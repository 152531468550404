import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { popularBooks } from '../constants/sampleItems'
import { useMyPage } from '../module/myPage/hook'
import { useNativeInteractor } from '../module/nativeInteractor/context'

import ErrorFallback from './common/ErrorFallback'
import { ChevronRight14Icon } from './icons/icon'

const UserSuggestBooks = lazy(async () => await import('./UserSuggestBooks'))

export default function GroupRecommendedBooks() {
  const { surveyParticipated } = useMyPage()
  const { interactor } = useNativeInteractor()

  const handleClickBookItem = (id: number) => {
    console.log(id)
    interactor.showStoreProduct(`${id}`)
  }

  return (
    <div className={`${!surveyParticipated && 'blur-[3px]'} select-none`}>
      {!surveyParticipated ? (
        <UserSuggestBooksSkeleton />
      ) : (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={null}>
            <UserSuggestBooks onClickBookItem={handleClickBookItem} />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  )
}

function UserSuggestBooksSkeleton() {
  return (
    <>
      <div className={'flex items-center justify-center'}>
        <span className='p-5 text-[14px] font-bold w-[800px]'>
          이번 달 <span className='text-decoration__underline--semibold'>OO그룹</span> 에게 인기가
          많았던 도서들이에요.
        </span>
      </div>
      <div className='flex flex-col overflow-hidden'>
        <div className={'bg-[#BBBBBB12] flex items-center justify-center'}>
          <div className='flex overflow-x-auto max-w-[800px] gap-[16px] px-[20px] py-[30px]'>
            {popularBooks.map((item, index) => (
              <div
                key={index}
                className='flex flex-col w-[100px] tablet-sm:w-[128px] tablet-sm:h-[266px] items-center justify-center'
              >
                <div className='flex items-center justify-center w-[100px] h-[119px] tablet-sm:w-[128px] tablet-sm:h-[151px] mt-[10px]'>
                  <img
                    className={'h-full w-auto object-contain rounded-md'}
                    src={item.thumbnail}
                    alt={item.thumbnail}
                  />
                </div>
                <div className='mt-[8px] tablet-sm:mt-[12px] min-h-[32px]'>
                  <span className='text-[10px] tablet-sm:text-[11px] line-clamp-2 text-center text-pretty font-medium'>
                    {item.title}
                  </span>
                </div>
                <button className='gap-[2px] mb-[10px] mt-[10px] tablet-sm:mt-[20px] flex bg-black rounded-[44px] border-1 border-black h-[29px] w-[98px] justify-center items-center'>
                  <span className='text-white text-[12px] font-medium'>더 알아보기</span>
                  <ChevronRight14Icon stroke='white' />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
