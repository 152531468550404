import classNames from 'classnames'
import React, { type MouseEvent, useMemo } from 'react'

import { useSurvey, useSurveySteps } from '../../module/survey/hook'
import Button from '../common/Button'
import SconnLoading from '../common/SconnLoading'

export default function ProcessButton({
  disabled,
  submitLoading,
  onNextClick,
}: {
  disabled?: boolean
  submitLoading?: boolean
  onNextClick?: (e?: MouseEvent<HTMLButtonElement>) => void
}) {
  const { surveyIsPending, checkedUserGroup, checkboxIsUniversity, surveyOnModal } = useSurvey()
  const { steps, activeStep, setNextStep } = useSurveySteps()
  const currentStep = steps[activeStep]

  /**
   * MEMO:
   *  사용자 그룹에 필요한 서베이 스텝 배열에서 마지막 스텝에서 버튼 타입을 submit 으로
   *  예외.
   *  - 첫 그룹선택 스텝에서 초등학생/N수생일 경우 submits
   *  - 일반인 스텝에서 대학생 아닐 경우 submit
   */
  const isNextButton = useMemo(
    () =>
      (currentStep === 'examinationsCollege' && checkboxIsUniversity !== 'no') ||
      (currentStep === 'userGroup' &&
        checkedUserGroup !== 'elemantaryschool' &&
        checkedUserGroup !== 'repeater') ||
      (currentStep !== 'examinationsCollege' &&
        currentStep !== 'userGroup' &&
        activeStep < steps.length - 1),
    [steps, activeStep, currentStep, checkedUserGroup, checkboxIsUniversity],
  )

  const isSubmitButton = useMemo(
    () =>
      (currentStep === 'examinationsCollege' && checkboxIsUniversity === 'no') ||
      checkedUserGroup === 'elemantaryschool' ||
      checkedUserGroup === 'repeater' ||
      (currentStep !== 'examinationsCollege' && activeStep === steps.length - 1),
    [steps, activeStep, checkedUserGroup, checkboxIsUniversity],
  )

  const content = () => (
    <>
      {isNextButton &&
        (submitLoading ? (
          <>
            <span>진행중...</span>
            <div>
              <SconnLoading width={30} height={30} />
            </div>
          </>
        ) : (
          <span>다음</span>
        ))}
      {isSubmitButton &&
        (surveyIsPending ? (
          <>
            <span>진행중...</span>
            <div>
              <SconnLoading width={30} height={30} />
            </div>
          </>
        ) : (
          <span>완료</span>
        ))}
    </>
  )

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onNextClick) {
      onNextClick(e)

      if (isNextButton) {
        setNextStep()
      }
    }
  }
  return (
    <div
      className={classNames(
        'bg-white sticky bottom-0 inset-x-0 px-[3px] mx-[-3px]' /* px, mx -> margin/padding for prevent showing radio-selected shodow behind */,
        surveyOnModal
          ? 'pt-[15px] pb-[30px]'
          : 'pt-[15px] pb-[25px] tablet-sm:pt-[25px] tablet-sm:pb-[25px] tablet-md:pt-[25px] tablet-md:pb-[50px] tablet-lg:pt-[25px] tablet-lg:pb-[80px]',
      )}
    >
      <Button
        disabled={disabled || submitLoading || surveyIsPending}
        form={isSubmitButton ? 'survey-form' : undefined}
        type={isNextButton ? 'button' : isSubmitButton ? 'submit' : undefined}
        onClick={handleClick}
      >
        {content()}
      </Button>
    </div>
  )
}
