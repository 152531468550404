import React, { type ReactNode, useEffect, useState } from 'react'

interface DefferedComponentProps {
  children: ReactNode
  height?: number
}

/**
 * MEMO: Set height to prevent cumulative layout shift
 */
export default function DeferredComponent({ children, height }: DefferedComponentProps) {
  const [isDeferred, SetIsDeferred] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      SetIsDeferred(true)
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const style = height ? { height: `${height}px` } : undefined

  return isDeferred ? (
    <div style={style}>{children}</div>
  ) : height ? (
    <div style={style}>{null}</div>
  ) : null
}
