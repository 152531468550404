import * as echarts from 'echarts'
import { graphic } from 'echarts/core'
import React, { useEffect, useRef } from 'react'

import { BAR_COLOR_STOP } from '../../constants/chartOption'

interface NoDataEchartProps {
  className: string
  chartOption: any
  criteriaDate: Date
}

export default function NoDataEchart({ className, chartOption, criteriaDate }: NoDataEchartProps) {
  const chartRef = useRef(null)

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current)

    chartInstance.setOption({
      ...chartOption,
      tooltip: {
        ...chartOption.tooltip,
        formatter: (params: any) => tooltipFomatter(params),
      },
      xAxis: {
        ...chartOption.xAxis,
        data: getPreviousSevenDays(criteriaDate),
      },
      yAxis: {
        ...chartOption.yAxis,
        axisLabel: {
          ...chartOption.yAxis.axisLabel,
          formatter: (value: number) => yAxisFormatter(value),
        },
      },
      series: [
        {
          ...chartOption.series[0],
          data: Array(7).fill(0),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, BAR_COLOR_STOP),
          },
        },
      ],
    })

    const resizeHandler = () => {
      chartInstance.resize()
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      chartInstance.dispose()
      window.removeEventListener('resize', resizeHandler)
    }
  }, [chartOption])

  return <div className={className} ref={chartRef} />
}

function tooltipFomatter(params: any) {
  const splitParams = params.name.split('/')
  const month = splitParams[0].padStart(2, '0')
  const day = splitParams[1].padStart(2, '0')
  const name = `${month}.${day}`

  return `<div class='tooltip-container'><span class='tooltip-title'>${name}</span>
    <span class='tooltip-value'>0분</span><div>`
}

function yAxisFormatter(value: number) {
  return value === 0 ? '' : `${value}h`
}

function getPreviousSevenDays(date: Date) {
  const dates = []
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(date)
    currentDate.setDate(date.getDate() - i)
    dates.push(`${currentDate.getMonth() + 1}/${currentDate.getDate()}`)
  }
  // 배열을 반전하여 날짜 순으로 정렬
  return dates.reverse()
}
