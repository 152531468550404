import React, { type ChangeEvent, useEffect, useState, Suspense, lazy, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type SchoolData } from '../api/interfaces/sconnStudyData'
import { useSurvey } from '../module/survey/hook'

import ErrorFallback from './common/ErrorFallback'
import LoadingFallback from './common/LoadingFallback'
import SearchInput from './common/SearchInput'
import FormContent from './surveyFormContentLayout'

const SchoolNameFilter = lazy(async () => await import('./SchoolNameFilter'))

export default function SchoolNameSelect() {
  const [filterId, setFilterId] = useState<string | null>(null)
  const [filterText, setFilterText] = useState<string>('')
  const [selectedSearch, setSelectedSearch] = useState<SchoolData | null>(null)
  const { latestResult, userType, userSchool, setUserSchool } = useSurvey()

  useEffect(() => {
    if (latestResult && userType === latestResult.userType) {
      setFilterId(latestResult.group?.school?.id ?? '')
      setFilterText(latestResult.group?.school?.name ?? '')
    }
  }, [latestResult])

  useEffect(() => {
    if (userSchool) {
      setFilterText(userSchool.name)
      setSelectedSearch(userSchool)
    }
  }, [userSchool])

  const handleFilterListItemClick = (item: SchoolData) => {
    setFilterText(item.name)
    setSelectedSearch(item)
  }

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value)
    setSelectedSearch(null)
    setFilterId(null)
  }

  const student = useMemo(() => {
    switch (userType) {
      case 'UC':
        return '대학생'
      case 'M':
        return '중학생'
      case 'H':
        return '고등학생'
      case 'E':
        return '초등학생'
      default:
        return '학생'
    }
  }, [userType])

  return (
    <FormContent>
      <FormContent.Grow>
        <FormContent.Question
          title={`${student} 이시군요! 학교명을 알려주세요.`}
          description='같은 학교 친구들과 학습 시간을 비교해 볼 수 있어요.'
        />
        <SearchInput
          className='mt-[50px]'
          label='학교명'
          id='schoolName'
          name='schoolName'
          searchicon='true'
          placeholder='학교명을 입력해 주세요.'
          value={filterText}
          required={true}
          onChange={handleTextChange}
        />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingFallback />}>
            <SchoolNameFilter
              userType={userType}
              filterId={filterId}
              filterText={filterText}
              selectedItem={selectedSearch}
              onListItemClick={handleFilterListItemClick}
            />
          </Suspense>
        </ErrorBoundary>
      </FormContent.Grow>
      <FormContent.ProcessButton
        submitLoading={false}
        disabled={!selectedSearch}
        onNextClick={() => {
          if (selectedSearch) {
            setUserSchool(selectedSearch)
          }
        }}
      />
    </FormContent>
  )
}
