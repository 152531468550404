import React from 'react'

import Content from './Content'
import ProcessButton from './ProcessButton'
import Question from './Question'

export default function FormContent({ children }: { children: React.ReactNode }) {
  return <>{children}</>
}

function Grow({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={`grow ${className}`}>{children}</div>
}

FormContent.Question = Question
FormContent.Grow = Grow
FormContent.Content = Content
FormContent.ProcessButton = ProcessButton
