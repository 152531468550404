import React, { type ChangeEvent, Suspense, lazy, useState, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { useStudyingReport } from '../module/myPage/hook'
import { useLatestStatsDate, getDateRangeLabel } from '../module/myPage/statsHelper'
import { type PeriodType } from '../module/myPage/types'
import { type CustomDate } from '../util/CustomDate'

import ChartErrorFallback from './common/ChartErrorFallback'
import ChartLoadingFallback from './common/ChartLoadingFallback'
import ErrorFallback from './common/ErrorFallback'
import { SyncIcon, TimerIcon } from './icons/icon'
import PeriodTypeRadioButton from './PeriodTypeRadioButton'
import StudyingDateRangePicker from './StudyingDateRangePicker'
import StudyingTime from './StudyingTime'
import { StudyingTopThreeSkeleton } from './StudyingTopThree'

const StudyingTopThree = lazy(async () => await import('./StudyingTopThree'))
const StudyingTimeChart = lazy(async () => await import('./StudyingTimeChart'))

interface StudyingTimeReportProps {
  className: string
}

export default function StudyingTimeReport({ className }: StudyingTimeReportProps) {
  const {
    statsUpdatedAt,
    statsPeriod,
    setStatsDateRangeLabel,
    setStatsPeriod,
    setStatsSelectedDate,
    setStatsDateRange,
  } = useStudyingReport()
  const [openCalandar, setOpenCalandar] = useState<boolean>(false)

  useEffect(() => {
    const { selectedDate, startDate, endDate } = useLatestStatsDate(statsPeriod)

    setStatsSelectedDate(selectedDate)
    setStatsDateRange(startDate, endDate)
    setStatsDateRangeLabel(getDateRangeLabel(statsPeriod, selectedDate, startDate, endDate))
  }, [statsPeriod])

  const handleChangePeriodType = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as PeriodType
    setStatsPeriod(value)
  }

  const handleOnOk = (
    selectedDate: CustomDate | null,
    dateRange: [CustomDate | null, CustomDate | null],
  ) => {
    setOpenCalandar(false)

    setStatsSelectedDate(selectedDate)
    setStatsDateRange(dateRange[0], dateRange[1])
    setStatsDateRangeLabel(getDateRangeLabel(statsPeriod, selectedDate, dateRange[0], dateRange[1]))
  }

  return (
    <>
      <Header periodType={statsPeriod} onChangePeriodType={handleChangePeriodType} />
      <StudyingDateRangePicker
        openCalandar={openCalandar}
        periodType={statsPeriod}
        onClickCalandar={() => {
          setOpenCalandar(true)
        }}
        onCancel={() => {
          setOpenCalandar(false)
        }}
        onOk={handleOnOk}
      />
      <UpdateInfo statsUpdatedAt={statsUpdatedAt} />
      <StudyingTime />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<StudyingTopThreeSkeleton />}>
          <StudyingTopThree />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        FallbackComponent={(error) => <ChartErrorFallback error={error} className={className} />}
      >
        <Suspense fallback={<ChartLoadingFallback />}>
          <StudyingTimeChart className={className} />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

function Header({
  periodType,
  onChangePeriodType,
}: {
  periodType: PeriodType
  onChangePeriodType: (e: ChangeEvent<HTMLInputElement>) => void
}) {
  return (
    <div className='tablet-sm:flex tablet-sm:items-center relative mt-[20px] tablet-sm:mt-[30px] tablet-sm:h-[42px]'>
      <div className='static flex items-center justify-between tablet-sm:absolute h-[42px]'>
        <div className='flex items-center gap-[10px]'>
          <TimerIcon />
          <span className='font-extrabold text-[14px]'>학습시간 리포트</span>
        </div>
      </div>
      <div className='h-[42px] static flex items-center justify-center w-full tablet-sm:absolute tablet-sm:justify-end'>
        <PeriodTypeRadioButton value={periodType} onChange={onChangePeriodType} />
      </div>
    </div>
  )
}

function UpdateInfo({ statsUpdatedAt }: { statsUpdatedAt: string }) {
  const { statsPeriod, statsSelectedDate, statsEndDate } = useStudyingReport()
  const [isToday, setIsToday] = useState<boolean>(false)

  /**
   * 기간내 오늘 날짜가 포함된 경우만 표시 (periodType daily 오늘인 경우만 해당)
   */
  useEffect(() => {
    if (statsSelectedDate !== null) {
      const today = new Date()
      setIsToday(statsPeriod === 'daily' && statsSelectedDate.isSame(today))
    }
  }, [statsSelectedDate, statsEndDate])

  return (
    <div className='flex items-center gap-[5px] mt-[10px] h-[20px]'>
      {statsUpdatedAt && isToday && (
        <>
          <SyncIcon />
          <span className='font-medium text-[11px] tablet-sm:text-[13px] text-[#BBBBBB]'>
            {statsUpdatedAt} 업데이트
          </span>
        </>
      )}
    </div>
  )
}
