import React, { useEffect } from 'react'

import { useIsOnlineData } from '../../module/myPage/hook'
import { WanrningIcon } from '../icons/icon'

interface ErrorFallbackProps {
  error: Error
}

function ErrorContent({ error, className }: ErrorFallbackProps & { className?: string }) {
  const { isOnline } = useIsOnlineData()
  const chunkFailedMessage = /Loading chunk [\d]+ failed/

  useEffect(() => {
    if (error?.message && chunkFailedMessage.test(error.message) && isOnline) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}`
    }
  }, [error, isOnline])

  if (!navigator.onLine) {
    return (
      <div className={`flex flex-col items-center w-full gap-5 ${className}`}>
        <WanrningIcon />
        <span className='text-[#C2C2C2] text-[14px]'>인터넷 연결이 끊어졌어요.</span>
      </div>
    )
  }

  if (!chunkFailedMessage.test(error.message)) {
    return (
      <div className={`flex flex-col items-center w-full gap-5 ${className}`}>
        <div className='text-red-400 text-[14px]'>{error.message ?? JSON.stringify(error)}</div>
      </div>
    )
  }
}

export default function ErrorFallback({ error }: ErrorFallbackProps) {
  return <ErrorContent error={error} className='my-28' />
}

export function ErrorFallbackSM({ error }: ErrorFallbackProps) {
  return <ErrorContent error={error} className='my-10' />
}

export function ErrorFallbackFullScreen({ error }: ErrorFallbackProps) {
  const { isOnline } = useIsOnlineData()
  const chunkFailedMessage = /Loading chunk [\d]+ failed/

  useEffect(() => {
    if (error?.message && chunkFailedMessage.test(error.message) && isOnline) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}`
    }
  }, [error, isOnline])

  if (!navigator.onLine) {
    return (
      <div className='absolute z-20 flex flex-col items-center justify-center w-screen h-screen gap-5 bg-white'>
        <WanrningIcon />
        <span className='text-[#C2C2C2] text-[14px]'>인터넷 연결이 끊어졌어요.</span>
      </div>
    )
  }

  if (!chunkFailedMessage.test(error.message)) {
    return (
      <div className='absolute z-20 flex flex-col items-center justify-center w-screen h-screen gap-5 bg-white'>
        <div className='text-red-400 text-[14px]'>{error.message ?? JSON.stringify(error)}</div>
      </div>
    )
  }
}
