import { IonDatetime, IonModal } from '@ionic/react'
import React from 'react'

import Button from './common/Button'

interface YearSelectModalProps {
  open: boolean
  onOk: () => void
  onCancel: () => void
  year: string
  onChangeYear: (e: any) => void
}

export default function YearSelectModal(props: YearSelectModalProps) {
  return (
    <IonModal
      id='custom-modal'
      className='nested-dialog'
      showBackdrop
      keepContentsMounted
      isOpen={props.open}
      backdropDismiss={false}
    >
      <div className='flex flex-col h-full pt-[15px] pb-[20px] px-[0px] max-h-[380px] '>
        <div className='flex items-center min-w-[50px] justify-end px-[25px] tablet-sm:px-[18px]'>
          <button type='button' className='text-cancel-font' onClick={props.onCancel}>
            취소
          </button>
        </div>
        <div
          className='overflow-auto grow tablet-sm:px-[8px]'
          style={{ maxHeight: 'calc(100% - 60px)' }}
        >
          <div className='flex justify-center'>
            <IonDatetime presentation='year' value={props.year} onIonChange={props.onChangeYear} />
          </div>
        </div>
        <div className='px-[25px] tablet-sm:px-[18px]'>
          <Button type='button' onClick={props.onOk}>
            완료
          </Button>
        </div>
      </div>
    </IonModal>
  )
}
