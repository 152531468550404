import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import '@ionic/react/css/core.css'
import './index.css'

import App from './App'
import SurveyForm from './components/SurveyForm'
import { MyPageContextProvider } from './module/myPage/context'
import { NativeInteractorProvider } from './module/nativeInteractor/context'
import { SconnStudyDataApiProvider } from './module/sconnStudyData/context'
import { SurveyContextProvider } from './module/survey/context'
import Complete from './pages/Complete'
import ErrorElement from './pages/ErrorElement'
import IndexRedirect from './pages/IndexRedirect'
import IntroSurvey from './pages/IntroSurvey'
import MyBookcafeAccount from './pages/MyBookcafeAccount'
import MyPage from './pages/MyPage'
import MyPageMain from './pages/MyPageMain'
import NotFound from './pages/NotFound'
import Survey from './pages/Survey'
import reportWebVitals from './reportWebVitals'
import { Analytics } from './util/Analytics'

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  const { worker } = await import('./api/mocks/browser')
  return await worker.start()
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <NativeInteractorProvider>
        <SconnStudyDataApiProvider>
          <MyPageContextProvider>
            <SurveyContextProvider>
              <App />
            </SurveyContextProvider>
          </MyPageContextProvider>
        </SconnStudyDataApiProvider>
      </NativeInteractorProvider>
    ),
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <IndexRedirect />,
      },
      {
        path: '/intro-survey',
        element: <IntroSurvey />,
      },
      {
        path: '/survey',
        element: <Survey />,
        children: [
          { path: '/survey', element: <SurveyForm /> },
          { path: 'modify', element: <SurveyForm /> },
          { path: 'complete', element: <Complete /> },
        ],
      },
      {
        path: '/my-page',
        element: <MyPage />,
        children: [
          { path: '/my-page', element: <MyPageMain /> },
          { path: 'account', element: <MyBookcafeAccount /> },
        ],
      },
    ],
  },
  { path: '*', element: <NotFound /> },
])

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root')!)
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
