import React, { forwardRef } from 'react'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css'

import { type PeriodType } from '../../module/myPage/types'
import { CustomDate } from '../../util/CustomDate'
import { ChevronBelowIcon, ChevronNextIcon, ChevronPrevIcon } from '../icons/icon'

interface DatePickerProps {
  selectedDate: Date | null
  startDate: Date | null
  endDate: Date | null
  periodType: PeriodType
  isOpenMonthYearPicker: boolean
  onChange: (value: Date | [Date | null, Date | null]) => void
  onMonthChange: (value: Date) => void
  onYearChange: (value: Date) => void
  onClickSelectMonthYear: () => void
}

export const DatePicker = forwardRef<ReactDatePicker | null, DatePickerProps>((props, ref) => {
  function getMaxDate(periodType: PeriodType): Date {
    const today = new CustomDate()

    if (periodType === 'daily') {
      return today
    } else if (periodType === 'monthly') {
      return today.setYesterday()
    } else if (periodType === 'weekly') {
      return today.getEndOfWeek()
    } else {
      throw new Error(`Invalid periodType: ${props.periodType}`)
    }
  }

  return (
    <ReactDatePicker
      selected={props.selectedDate}
      onChange={props.onChange}
      onMonthChange={props.onMonthChange}
      onYearChange={props.onYearChange}
      calendarStartDay={1}
      maxDate={getMaxDate(props.periodType)}
      dayClassName={(date: any) => {
        const day = date.getDay()
        const today = new CustomDate()
        const currDate = new CustomDate(date)
        const isFuture = currDate > today

        if (day === 6) {
          return `react-datepicker__day--saturday${isFuture ? '--disabled' : ''}`
        } else if (day === 0) {
          return `react-datepicker__day--sunday${isFuture ? '--disabled' : ''}`
        }

        if (props.periodType === 'weekly') {
          const isSame = currDate.isSame(today)
          return isFuture || isSame ? 'react-datepicker__day--disabled' : ''
        }

        return ''
      }}
      showMonthYearPicker={props.periodType === 'monthly'}
      renderMonthContent={(monthIndex, shortMonthText) => (
        <span className='month-label'>{monthIndex + 1}월</span>
      )}
      renderDayContents={(dayOfMonth: number, date?: Date) => (
        <span className='day-label'>{date?.getDate()}</span>
      )}
      renderCustomHeader={({
        date,
        monthDate,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) => (
        <>
          <div className='flex items-center gap-[5px]' onClick={props.onClickSelectMonthYear}>
            {date.getFullYear()}년{' '}
            {props.periodType === 'monthly'
              ? monthDate.toLocaleString('default', { month: 'long' })
              : date.toLocaleString('default', { month: 'long' })}
            {props.isOpenMonthYearPicker ? <ChevronNextIcon /> : <ChevronBelowIcon />}
          </div>
          {!props.isOpenMonthYearPicker && (
            <div className='flex items-center gap-[10px]'>
              <button
                onClick={props.periodType === 'monthly' ? decreaseYear : decreaseMonth}
                disabled={
                  props.periodType === 'monthly' ? prevYearButtonDisabled : prevMonthButtonDisabled
                }
              >
                <ChevronPrevIcon />
              </button>
              <button
                onClick={props.periodType === 'monthly' ? increaseYear : increaseMonth}
                disabled={
                  props.periodType === 'monthly' ? nextYearButtonDisabled : nextMonthButtonDisabled
                }
              >
                <ChevronNextIcon />
              </button>
            </div>
          )}
        </>
      )}
      inline
      selectsRange={props.periodType === 'weekly'}
      startDate={props.startDate}
      endDate={props.endDate}
      ref={ref}
    />
  )
})

DatePicker.displayName = 'DatePicker'
