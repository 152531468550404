import { type TodayStats, type WorkspaceStats } from '../../api/interfaces/sconnStudyData'
import { CustomDate, type DateRange } from '../../util/CustomDate'
import { dateDiffInDays, removeSeconds } from '../../util/numberUtils'

import { type PeriodType } from './types'

export type TodayStudyProgress = {
  duration: number
  goalDuration: number
  continuousDays: number
}

export function calculateProgress(data: TodayStats) {
  const progress: TodayStudyProgress = {
    duration: data.duration === 'NO_DATA' ? 0 : removeSeconds(data.duration),
    goalDuration:
      data.goalDaily === 'NOT_SET_GOAL' || data.goalDaily.current === null
        ? 0
        : data.goalDaily.current.timeLength,
    continuousDays: data.goalDaily === 'NOT_SET_GOAL' ? 0 : data.goalDaily.continuousDays,
  }

  let isGoalAchieved = false
  if (progress.goalDuration !== 0) {
    const ratio = (progress.duration / progress.goalDuration) * 100
    isGoalAchieved = ratio >= 100
  }

  return { progress, isGoalAchieved }
}

export function processData(data: WorkspaceStats, statsPeriod: PeriodType) {
  const newData: WorkspaceStats = {
    durations: [],
    timestamps: [],
    latestTimeStamp: data.latestTimeStamp,
  }

  let flag = null
  let currentDurationSum = 0
  for (let i = 0; i < data.durations.length; i++) {
    const currentTimestamp = new Date(data.timestamps[i])
    const currentFlag =
      statsPeriod === 'monthly'
        ? `${currentTimestamp.getFullYear()}.${currentTimestamp.getMonth() + 1}`
        : `${Math.floor(i / 7)}`

    // 월이나 주가 바뀌면 이전 월이나 주의 합산된 duration을 넣어주고 초기화
    if (flag !== null && flag !== currentFlag) {
      newData.durations.push(currentDurationSum)
      newData.timestamps.push(data.timestamps[i - 1])
      currentDurationSum = 0
    }

    currentDurationSum += data.durations[i]
    flag = currentFlag
  }

  newData.durations.push(currentDurationSum)
  newData.timestamps.push(data.timestamps[data.timestamps.length - 1])

  return newData
}

export function formatDurationToText(duration: number) {
  const secondsDuration = removeSeconds(duration)
  const hourDuration = secondsDuration / 3600
  const minuteDuration = secondsDuration / 60

  if (hourDuration < 1) {
    return `${minuteDuration !== 0 ? `${minuteDuration}분` : '0분'}`
  } else {
    const hour = Math.floor(hourDuration)
    const minutes = Math.round((hourDuration - hour) * 60)
    return `${hour !== 0 ? `${hour}시간 ` : ''}${minutes !== 0 ? `${minutes}분` : ''}${hour === 0 && minutes === 0 ? '0분' : ''}`
  }
}

interface IStatsDates {
  selectedDate: CustomDate | null
  startDate: CustomDate | null
  endDate: CustomDate | null
}

export function useLatestStatsDate(statsPeriod: PeriodType): IStatsDates {
  let selectedDate: CustomDate | null = null
  let startDate: CustomDate | null = null
  let endDate: CustomDate | null = null

  const today = new CustomDate()
  const yesterday = new CustomDate(today)
  yesterday.setDate(yesterday.getDate() - 1)

  if (statsPeriod === 'daily') {
    selectedDate = today
  } else if (statsPeriod === 'monthly') {
    selectedDate = yesterday
  } else if (statsPeriod === 'weekly') {
    const startOfWeek = yesterday.getStartOfWeek()
    const addedDays = dateDiffInDays(startOfWeek, yesterday)

    selectedDate = startOfWeek
    startDate = startOfWeek
    endDate = yesterday.getEndOfWeek(addedDays)
  }

  return { selectedDate, startDate, endDate }
}

export function useSelectedStatsDate(statsPeriod: PeriodType, date: CustomDate): IStatsDates {
  let selectedDate: CustomDate | null = null
  let startDate: CustomDate | null = null
  let endDate: CustomDate | null = null

  if (statsPeriod === 'weekly' || statsPeriod === 'monthly') {
    const yesterday = new CustomDate()
    yesterday.setDate(yesterday.getDate() - 1)

    if (date.getStartOfWeek().isSame(yesterday.getStartOfWeek())) {
      return { ...useLatestStatsDate(statsPeriod) }
    }
  }

  if (statsPeriod === 'daily' || statsPeriod === 'monthly') {
    selectedDate = date
  } else if (statsPeriod === 'weekly') {
    const startOfWeek = date.getStartOfWeek()

    selectedDate = startOfWeek
    startDate = startOfWeek
    endDate = date.getEndOfWeek()
  }

  return { selectedDate, startDate, endDate }
}

export function getDateRangeLabel(
  statsPeriod: PeriodType,
  selectedDate: CustomDate | null,
  startDate: CustomDate | null,
  endDate: CustomDate | null,
) {
  let label = ''

  if (statsPeriod === 'daily' && selectedDate) {
    label = selectedDate.toYearMonthDay('.')
  } else if (statsPeriod === 'weekly' && startDate && endDate) {
    label = startDate.isSame(endDate)
      ? `${endDate.toYearMonthDay('.')}`
      : `${startDate.toYearMonthDay('.')} ~ ${endDate.toYearMonthDay('.')}`
  } else if (statsPeriod === 'monthly' && selectedDate) {
    label = selectedDate.toYearMonth('.')
  }

  return label
}

export const contentsStatsData: Record<
  PeriodType,
  (
    selectedDate: CustomDate | null,
    startDate: CustomDate | null,
    endDate: CustomDate | null,
  ) => { range: DateRange | null; isToday: boolean }
> = {
  daily: (selectedDate) => ({
    range: selectedDate?.getOneDayRange() ?? null,
    isToday: selectedDate?.isToday() ?? false,
  }),
  weekly: (_, startDate, endDate) => ({
    range: endDate?.getOneWeekRange(startDate ?? new CustomDate()) ?? null,
    isToday: false,
  }),
  monthly: (selectedDate) => ({
    range: selectedDate?.getOneMonthRange() ?? null,
    isToday: false,
  }),
}
