import React from 'react'

import { EditIcon } from '../icons/icon'

export default function ContentsRow({
  title,
  visible = true,
  editable = false,
  onClickTitle,
  onClickEdit,
  children,
}: {
  title: string
  visible?: boolean
  editable?: boolean
  onClickTitle?: () => void
  onClickEdit?: () => void
  children?: React.ReactNode
}) {
  return (
    <>
      {visible && (
        <div className='flex items-center justify-between h-[44px]'>
          <span className='pr-8' onClick={onClickTitle}>
            {title}
          </span>
          <div className='flex items-center'>
            <span>{children}</span>
            {editable && <EditIcon className='min-w-[26px]' onClick={onClickEdit} />}
          </div>
        </div>
      )}
    </>
  )
}
