// TODO: Tree shaking
import * as echarts from 'echarts'
import { graphic } from 'echarts/core'
import React, { useEffect, useRef, useState } from 'react'

import './Echart.css'
import { BAR_COLOR_STOP } from '../../constants/chartOption'

interface EchartProps {
  className: string
  chartOption: any
}

export default function Echart({ className, chartOption }: EchartProps) {
  const chartRef = useRef(null)

  const [selectedBarIndex, setSelectedBarIndex] = useState<number>(-1)

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current)

    chartInstance.on('mousedown', function (params) {
      if (params.componentType === 'series') {
        setSelectedBarIndex(params.dataIndex)
        updateChartOptions()
      }
      if (params.componentType === 'xAxis') {
        chartInstance.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: params.dataIndex,
        })
      }
    })

    chartInstance.on('mouseout', function (params) {
      setSelectedBarIndex(-1)
      updateChartOptions()
    })

    chartInstance.on('globalout', function (params) {
      setSelectedBarIndex(-1)
      updateChartOptions()
    })

    function updateChartOptions() {
      const width = chartInstance.getWidth()
      const mobileWidth = 440

      chartInstance.setOption({
        ...chartOption,
        tooltip: {
          ...chartOption.tooltip,
          position: selectedBarIndex > 2 ? 'top' : width < mobileWidth ? 'right' : 'top',
        },
        xAxis: {
          ...chartOption.xAxis,
          axisLabel: {
            ...chartOption.xAxis.axisLabel,
            rich: {
              a: {
                width: width < mobileWidth ? 38 : 43,
                height: width < mobileWidth ? 18 : 20,
                fontSize: width < mobileWidth ? 11 : 12,
                color: '#FF8744',
                fontWeight: 900,
                backgroundColor: '#FFF5DE',
                borderRadius: 14,
              },
              b: {
                height: width < mobileWidth ? 18 : 20,
                fontSize: width < mobileWidth ? 11 : 12,
                color: 'black',
                fontWeight: 500,
              },
            },
          },
        },
        yAxis: {
          ...chartOption.yAxis,
          axisLabel: {
            ...chartOption.yAxis.axisLabel,
            fontSize: width < mobileWidth ? 11 : 12,
          },
        },
        series: [
          {
            ...chartOption.series[0],
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, BAR_COLOR_STOP),
            },
            renderItem: function (params: any, api: any) {
              const categoryWidth = api.size([1, 0])[0]
              let barWidth = categoryWidth * 0.33 // 카테고리 너비의 33%
              barWidth = barWidth >= 20 ? 20 : barWidth

              const value = api.value(1)
              const isMinHeight = value === 0 || (value !== 0 && api.size([0, value])[1] < 2)
              const height = isMinHeight ? 2 : api.size([0, value])[1] // 0인 경우 최소 높이 10px
              const base = api.coord([0, 0])[1] // y축 0의 위치를 기준으로 가져옵니다.
              const xPosition = api.coord([api.value(0), value])[0] - barWidth / 2
              const yPosition = isMinHeight ? base - height : api.coord([0, value])[1] // 0 값인 경우 위치 조정

              const categoryIndex = api.value(0)
              let color =
                categoryIndex === selectedBarIndex
                  ? new graphic.LinearGradient(0, 0, 0, 1, BAR_COLOR_STOP)
                  : '#FFF0CE'
              if (selectedBarIndex === -1) {
                color = new graphic.LinearGradient(0, 0, 0, 1, BAR_COLOR_STOP)
              }

              return {
                type: 'rect',
                shape: {
                  x: xPosition,
                  y: yPosition,
                  width: barWidth,
                  height,
                  r: [3, 3, 0, 0],
                },
                style: api.style({
                  fill: color,
                  borderRadius: [3, 3, 0, 0],
                }),
                styleEmphasis: api.style({ fill: color, borderRadius: [3, 3, 0, 0] }),
              }
            },
          },
        ],
      })
    }

    updateChartOptions()
    const resizeHandler = () => {
      chartInstance.resize()
      updateChartOptions()
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      chartInstance.dispose()
      window.removeEventListener('resize', resizeHandler)
    }
  }, [chartOption, selectedBarIndex])

  return <div className={className} ref={chartRef} />
}
