import { graphic } from 'echarts/core'
import React from 'react'

import Echart from './common/Echart'

interface StudyingReportPreparingChartProps {
  className: string
}

export default function StudyingReportPreparingChart({
  className,
}: StudyingReportPreparingChartProps) {
  const option = {
    animation: false,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '10%',
    },
    xAxis: {
      type: 'category',
      data: ['4/5', '4/6', '4/7', '4/8'],
      axisLabel: {
        color: 'black',
        fontWeight: 500,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#EEEEEE',
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: false,
        formatter: function (value: number) {
          return value === 0 ? '' : `${value}h`
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#FDCA53',
          type: 'dashed',
          width: 1,
        },
      },
    },
    series: [
      {
        type: 'bar',
        data: [6, 8, 15, 8],
        barWidth: '30%',
        barMaxWidth: '20px',
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#FFB200' },
            { offset: 1, color: '#FDCA53' },
          ]),
          borderRadius: [3, 3, 0, 0],
        },
      },
    ],
  }

  return <Echart chartOption={option} className={className} />
}
