import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { useCallback, useState } from 'react'

import {
  type UniversityMajor,
  type CertData,
  type SchoolData,
  type UpdateResponse,
  type ErrorResponse,
  type SurveyResult,
  type WorkspaceStats,
  type Dday,
  type TodayStats,
  type ContentStats,
} from '../../api/interfaces/sconnStudyData'
import { type DateRange } from '../../util/CustomDate'
import { type UserTypeName, type HasClassUserType } from '../survey/types'

import { useSconnStudyDataApi } from './context'

export const useUserType = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data } = useQuery({
    queryKey: ['userType'],
    queryFn: async () => {
      const result = await sconnStudyData.getUserTypes()
      return result
    },
  })

  return { data }
}

export const useConsentSurvey = () => {
  const queryClient = useQueryClient()

  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<UpdateResponse, AxiosError<ErrorResponse>, boolean>({
    mutationFn: async (allow: boolean) => {
      const result = await sconnStudyData.postConsentSurvey(allow)
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })

  return { isPending, mutate }
}

export const useUserInformation = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data } = useSuspenseQuery({
    queryKey: ['user'],
    queryFn: async () => await sconnStudyData.getUserInformation(),
    refetchOnWindowFocus: false,
  })

  return { data }
}

export const useUserDday = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data } = useSuspenseQuery({
    queryKey: ['dday'],
    queryFn: async () => {
      const result = await sconnStudyData.getUserDday()
      return result ?? null
    },
    refetchOnWindowFocus: false,
  })

  return { data }
}

export const useUpdateUserDday = () => {
  const queryClient = useQueryClient()

  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<UpdateResponse, AxiosError<ErrorResponse>, Dday>({
    mutationFn: async (dday: Dday) => {
      const result = await sconnStudyData.postUserDday(dday)
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dday'] })
    },
  })

  return { isPending, mutate }
}

export const useUpdateNextGoalOfDays = () => {
  const queryClient = useQueryClient()

  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<UpdateResponse, AxiosError<ErrorResponse>, number>({
    mutationFn: async (seconds: number) => {
      const timezoneOffset = new Date().getTimezoneOffset()
      const result = await sconnStudyData.postGoalDaily(seconds, timezoneOffset)
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['statistics', 'user', 'today'] })
    },
  })

  return { isPending, mutate }
}

export const useUpdateCurrentGoalOfDays = () => {
  const queryClient = useQueryClient()

  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<UpdateResponse, AxiosError<ErrorResponse>, number>({
    mutationFn: async (seconds: number) => {
      const timezoneOffset = new Date().getTimezoneOffset()
      const result = await sconnStudyData.putGoalDaily(seconds, timezoneOffset)
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['statistics', 'user', 'today'] })
    },
  })

  return { isPending, mutate }
}

export const useUserSurvey = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data } = useQuery({
    queryKey: ['survey'],
    queryFn: async () => {
      const result = await sconnStudyData.getUserSurvey()
      return result
    },
  })

  return { data }
}

export const useUserSuggests = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data } = useSuspenseQuery({
    queryKey: ['suggests', 'user'],
    queryFn: async () => {
      const result = await sconnStudyData.getUserSuggests()
      return result
    },
    refetchOnWindowFocus: false,
  })

  return { data }
}

export const useUserTodayStatistics = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data, isPending } = useSuspenseQuery({
    queryKey: ['statistics', 'user', 'today'],
    queryFn: async () => {
      const timezoneOffset = new Date().getTimezoneOffset()

      const result = await sconnStudyData.getUserStatistics({
        target: 'today',
        offset: `${timezoneOffset}`,
      })
      return result
    },
    refetchOnWindowFocus: false,
  })

  return { data: data as TodayStats, isPending }
}

export const useUserWorkspaceStatistics = ({ from, to }: DateRange) => {
  const { sconnStudyData } = useSconnStudyDataApi()
  const today = new Date()
  const staleTime = to.isSame(today) ? 0 : 1000 * 60 * 60 * 24
  const fromStr = from.toYearMonthDay()
  const toStr = to.toYearMonthDay()

  const { data } = useSuspenseQuery({
    queryKey: ['statistics', 'user', 'workspace', fromStr, toStr],
    queryFn: async () => {
      const timezoneOffset = new Date().getTimezoneOffset()
      const result = await sconnStudyData.getUserStatistics({
        target: 'workspace',
        from: fromStr,
        to: toStr,
        offset: `${timezoneOffset}`,
      })
      return result
    },
    staleTime,
    refetchOnWindowFocus: false,
  })

  return { data: data as WorkspaceStats }
}

export const useUserContentStatistics = ({ from, to }: DateRange, disable: boolean) => {
  const { sconnStudyData } = useSconnStudyDataApi()
  const today = new Date()
  const staleTime = to.isSame(today) ? 0 : 1000 * 60 * 60 * 24
  const fromStr = from.toYearMonthDay()
  const toStr = to.toYearMonthDay()

  const { data } = useSuspenseQuery({
    queryKey: ['statistics', 'user', 'content', fromStr, toStr],
    queryFn: async () => {
      if (disable) return 'NO_DATA'

      const timezoneOffset = new Date().getTimezoneOffset()
      const result = await sconnStudyData.getUserStatistics({
        target: 'content',
        from: fromStr,
        to: toStr,
        offset: `${timezoneOffset}`,
      })
      return result
    },
    staleTime,
    refetchOnWindowFocus: false,
  })

  return { data: data as ContentStats }
}

export const useUpdateNickname = () => {
  const queryClient = useQueryClient()

  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<UpdateResponse, AxiosError<ErrorResponse>, string>({
    mutationFn: async (nickname: string) => {
      const result = await sconnStudyData.postNickname(nickname)
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] })
    },
  })

  return { isPending, mutate }
}

export const usePostSurvey = () => {
  const queryClient = useQueryClient()

  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<
    UpdateResponse,
    AxiosError<ErrorResponse>,
    SurveyResult
  >({
    mutationFn: async (surveyResult: SurveyResult) => {
      const result = await sconnStudyData.postSurvey(surveyResult)
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] })
      queryClient.invalidateQueries({ queryKey: ['survey'] })
    },
    retry: 0,
  })

  return { isPending, mutate }
}

export const useValidateNickname = () => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { isPending, mutate } = useMutation<UpdateResponse, AxiosError<ErrorResponse>, string>({
    mutationFn: async (nickname: string) => {
      const result = await sconnStudyData.validateNickname(nickname)
      return result
    },
  })

  return { isPending, mutate }
}

export const useSchoolNames = (
  userType: UserTypeName | null,
  filterText: string,
  filterId: string | null,
) => {
  const { sconnStudyData } = useSconnStudyDataApi()
  const [noData, setNoData] = useState<boolean>(false)

  const selectFn = useCallback(
    (unfilteredList: SchoolData[]) => {
      if (filterId !== null) {
        const filteredList = unfilteredList.filter((item: SchoolData) => item.id === filterId)
        setNoData(filteredList.length === 0)
        return filteredList
      }

      if (filterText === '') {
        setNoData(false)
        return []
      }

      const filteredList = unfilteredList.filter((item: SchoolData) =>
        item.name.toLowerCase().includes(filterText.trim().toLowerCase()),
      )
      setNoData(filteredList.length === 0)
      return filteredList
    },
    [filterText],
  )

  const { data: filteredData } = useSuspenseQuery({
    queryKey: ['schoolName', userType],
    queryFn: async () => await sconnStudyData.getAllSchoolNames(userType),
    select: selectFn,
    staleTime: 1000 * 60 * 60 * 24,
  })

  return { noData, filteredData }
}

export const useMajorNames = (code: string, filterText: string, filterId: string | null) => {
  const { sconnStudyData } = useSconnStudyDataApi()
  const [noData, setNoData] = useState<boolean>(false)

  const selectFn = useCallback(
    (unfilteredList: UniversityMajor[]) => {
      if (filterId !== null) {
        const filteredList = unfilteredList.filter((item: UniversityMajor) => item.id === filterId)
        setNoData(filteredList.length === 0)
        return filteredList
      }

      if (filterText === '') {
        setNoData(false)
        return []
      }

      const filteredList = unfilteredList.filter((item: UniversityMajor) =>
        item.name.toLowerCase().includes(filterText.trim().toLowerCase()),
      )
      setNoData(filteredList.length === 0)
      return filteredList
    },
    [filterText],
  )

  const { data: filteredData } = useSuspenseQuery({
    queryKey: ['major', code],
    queryFn: async () => await sconnStudyData.getMajorNames(code, ''),
    select: selectFn,
    staleTime: 1000 * 60 * 60 * 24,
  })

  return { noData, filteredData }
}

export const useExaminationNames = (filterText: string) => {
  const { sconnStudyData } = useSconnStudyDataApi()
  const [noData, setNoData] = useState<boolean>(false)

  const selectFn = useCallback(
    (unfilteredList: CertData[]) => {
      if (filterText === '') {
        setNoData(false)
        return []
      }

      const filteredList = unfilteredList.filter(
        (item: CertData) =>
          item.name.toLowerCase().includes(filterText.trim().toLowerCase()) ||
          item.keyWords.replace(', ', '').toLowerCase().includes(filterText.trim().toLowerCase()),
      )
      setNoData(filteredList.length === 0)
      return filteredList
    },
    [filterText],
  )

  const { data: examinations } = useSuspenseQuery({
    queryKey: ['examinations'],
    queryFn: async () => await sconnStudyData.getAllExaminationNames(),
    select: selectFn,
    staleTime: 1000 * 60 * 60 * 24,
  })

  return { noData, examinations }
}

export const useSchoolClasses = (userType: HasClassUserType | null, code: string) => {
  const { sconnStudyData } = useSconnStudyDataApi()

  const { data: classes } = useSuspenseQuery({
    queryKey: [
      (userType === 'M' && 'middleschoolclasses') || (userType === 'H' && 'highschoolclasses'),
      code,
    ],
    queryFn: async () => await sconnStudyData.getSchoolClasses(userType, code),
    staleTime: 1000 * 60 * 60 * 24,
  })

  return { classes }
}
