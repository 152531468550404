import { AxiosError, type AxiosResponse } from 'axios'

import { type UserTypeName } from '../module/survey/types'

import {
  type MiddleschoolClass,
  type HighschoolClass,
  type DataResponse,
  type University,
  type UniversityMajor,
  type CertData,
  type Middleschool,
  type Highschool,
  type SchoolData,
  type User,
  type UpdateResponse,
  type ErrorResponse,
  type UserType,
  type SurveyResult,
  type SuggestBooks,
  type StatsOption,
  type StatsData,
  type LatestSurvey,
  type Dday,
} from './interfaces/sconnStudyData'
import type MockSconnStudyDataClient from './mockSconnStudyDataClient'
import type SconnStudyDataClient from './sconnStudyDataClient'

export default class SconnStudyData {
  readonly #apiClient: MockSconnStudyDataClient | SconnStudyDataClient

  constructor(apiClient: any) {
    this.#apiClient = apiClient
  }

  setAccessToken(accessToken: string) {
    this.#apiClient.setAccessToken(accessToken)
  }

  setExpires(expires: number) {
    this.#apiClient.expires = expires
  }

  async getUserTypes() {
    return await this.#apiClient
      .getUserTypes()
      .then(handleDataResponse<UserType[]>)
      .catch(handleErrorResolver)
  }

  async getUserInformation() {
    return await this.#apiClient
      .getUserInformation()
      .then(handleDataResponse<User>)
      .catch(handleErrorResolver)
  }

  async getUserSurvey() {
    return await this.#apiClient
      .getUserSurvey()
      .then(handleDataResponse<LatestSurvey>)
      .catch(handleErrorResolver)
  }

  async getUserDday() {
    return await this.#apiClient
      .getUserDday()
      .then(handleDataResponse<Dday>)
      .catch(handleErrorResolver)
  }

  async postUserDday(dday: Dday) {
    return await this.#apiClient
      .postUserDday(dday)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async putGoalDaily(seconds: number, offset: number) {
    return await this.#apiClient
      .putGoalDaily(seconds, offset)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async postGoalDaily(seconds: number, offset: number) {
    return await this.#apiClient
      .postGoalDaily(seconds, offset)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async getUserSuggests() {
    return await this.#apiClient
      .getUserSuggests()
      .then(handleDataResponse<SuggestBooks>)
      .catch(handleErrorResolver)
  }

  async getUserStatistics(option: StatsOption) {
    return await this.#apiClient
      .getUserStatistics(option)
      .then(handleDataResponse<StatsData>)
      .catch(handleErrorResolver)
  }

  async postNickname(nickName: string) {
    return await this.#apiClient
      .postNickname(nickName)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async validateNickname(nickName: string) {
    return await this.#apiClient
      .validateNickname(nickName)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async postConsentSurvey(allow: boolean) {
    return await this.#apiClient
      .postConsentAllow(allow)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async postSurvey(survey: SurveyResult) {
    return await this.#apiClient
      .postSurvey(survey)
      .then((res: AxiosResponse<UpdateResponse>) => res.data)
      .catch(handleErrorResolver)
  }

  async getAllSchoolNames(userType: UserTypeName | null): Promise<SchoolData[]> {
    switch (userType) {
      case 'UC':
        return await this.getAllCollegeNames()
      case 'H':
        return await this.getAllHighschoolNames()
      case 'M':
        return await this.getAllMiddleschoolNames()
      default:
        throw new AxiosError('Not exist user type')
    }
  }

  private async getAllCollegeNames() {
    return await this.#apiClient
      .allCollege()
      .then(handleListDataResponse<University>)
      .catch(handleErrorResolver)
  }

  private async getAllHighschoolNames() {
    return await this.#apiClient
      .allHighschool()
      .then(handleListDataResponse<Highschool>)
      .catch(handleErrorResolver)
  }

  private async getAllMiddleschoolNames() {
    return await this.#apiClient
      .allMiddleschool()
      .then(handleListDataResponse<Middleschool>)
      .catch(handleErrorResolver)
  }

  async getAllExaminationNames() {
    return await this.#apiClient
      .allCert()
      .then(handleListDataResponse<CertData>)
      .catch(handleErrorResolver)
  }

  async getMajorNames(code: string, searchWord: string) {
    return await this.#apiClient
      .getMajor(code, searchWord)
      .then(handleListDataResponse<UniversityMajor>)
      .catch(handleErrorResolver)
  }

  async getSchoolClasses(userType: UserTypeName | null, code: string) {
    switch (userType) {
      case 'M':
        return await this.getMiddleschoolClasses(code)
      case 'H':
        return await this.getHighschoolClasses(code)
      default:
        throw new Error('Cannot get this user type class/grade info')
    }
  }

  private async getMiddleschoolClasses(code: string) {
    return await this.#apiClient
      .getMiddleschoolClasses(code)
      .then(handleListDataResponse<MiddleschoolClass>)
      .catch(handleErrorResolver)
  }

  private async getHighschoolClasses(code: string) {
    return await this.#apiClient
      .getHighschoolClasses(code)
      .then(handleListDataResponse<HighschoolClass>)
      .catch(handleErrorResolver)
  }
}

function handleListDataResponse<T>(res: AxiosResponse<DataResponse<T[]>>) {
  const { data: lists } = res.data
  return lists === null || lists?.length === 0 ? [] : lists
}

function handleDataResponse<T>(res: AxiosResponse<DataResponse<T>>) {
  return res.data.data
}

function handleErrorResolver(error: AxiosError<ErrorResponse>): never {
  if (error.response?.data) {
    throw new AxiosError(error.response?.data.reason)
  }

  throw new AxiosError(error.message)
}
