import { type AosNativeInteractor } from './aosNativeInteractor'
import { type IosNativeInteractor } from './iosNativeInteractor'

declare global {
  interface Window {
    opera: any
    MSStream: any
  }
}

export declare interface showShareStudyingStatsModalPayload {
  nickname: string
  period: string
  studyTime: string
  contents: Array<{
    title: string
    studyTime: string
    thumbnail: string
  }>
}

export interface INativeInteractor {
  getNativeToken: () => Promise<string | undefined>
  showStoreProduct: (pdfId: string) => void
  showSettingModal: () => void
  showShareStudyingStatsModal: (payload: showShareStudyingStatsModalPayload) => void
  showSconnChargeModal: () => void
  showHoldPointPage: () => void
  showCouponPage: () => void
  showServiceList: () => void
  serviceLogout: () => void
  openUrl: (url: string) => void
  requestParentConsent: () => Promise<string | undefined>
}

export class NativeInteractor {
  constructor(
    private readonly iosInteractor: IosNativeInteractor,
    private readonly aosInteractor: AosNativeInteractor,
  ) {}

  async getNativeToken() {
    const device = this.getMobileOS()

    if (device === 'iOS') {
      console.log('device iOS')
      return await this.iosInteractor.getNativeToken()
    } else if (device === 'Android') {
      console.log('device AOS')
      return await this.aosInteractor.getNativeToken()
    } else {
      throw new Error(`Unknown device: ${device}`)
    }
  }

  async requestParentConsent() {
    const device = this.getMobileOS()

    if (device === 'iOS') {
      console.log('device iOS')
      return await this.iosInteractor.requestParentConsent()
    } else if (device === 'Android') {
      console.log('device AOS')
      return await this.aosInteractor.requestParentConsent()
    } else {
      throw new Error(`Unknown device: ${device}`)
    }
  }

  showStoreProduct(pdfId: string) {
    this.applyToAllInteractors((bridge) => {
      bridge.showStoreProduct(pdfId)
    })
  }

  showSettingModal() {
    this.applyToAllInteractors((bridge) => {
      bridge.showSettingModal()
    })
  }

  showShareStudyingStatsModal(payload: showShareStudyingStatsModalPayload) {
    this.applyToAllInteractors((bridge) => {
      bridge.showShareStudyingStatsModal(payload)
    })
  }

  showSconnChargeModal() {
    this.applyToAllInteractors((bridge) => {
      bridge.showSconnChargeModal()
    })
  }

  showHoldPointPage() {
    this.applyToAllInteractors((bridge) => {
      bridge.showHoldPointPage()
    })
  }

  showCouponPage() {
    this.applyToAllInteractors((bridge) => {
      bridge.showCouponPage()
    })
  }

  showServiceList() {
    this.applyToAllInteractors((bridge) => {
      bridge.showServiceList()
    })
  }

  serviceLogout() {
    this.applyToAllInteractors((bridge) => {
      bridge.serviceLogout()
    })
  }

  openUrl(url: string) {
    this.applyToAllInteractors((bridge) => {
      bridge.openUrl(url)
    })
  }

  private applyToAllInteractors(lambda: (bridge: INativeInteractor) => void) {
    lambda(this.iosInteractor)
    lambda(this.aosInteractor)
  }

  private getMobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }
}
