import React, { Suspense, lazy, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type SchoolClass } from '../api/interfaces/sconnStudyData'
import { useSurvey } from '../module/survey/hook'
import { type SurveyStep } from '../module/survey/types'

import ErrorFallback from './common/ErrorFallback'
import LoadingFallback from './common/LoadingFallback'
import FormContent from './surveyFormContentLayout'

const SchoolClassFilter = lazy(async () => await import('./SchoolClassFilter'))

interface SchoolClassSelectProps {
  currentStep: SurveyStep
}

export default function SchoolClassSelect(props: SchoolClassSelectProps) {
  const { userType, middleschool, highschool, grade, classroom, setGrade, setClassroom } =
    useSurvey()
  const [hasDepartment, setHasDepartment] = useState<boolean>()

  useEffect(() => {
    setHasDepartment(userType === 'H' && highschool?.hasDepartment)
  }, [userType, highschool])

  const handleNextClick = (classes: SchoolClass[]) => {
    if (classes.length > 0) {
      if (props.currentStep === 'grade') {
        setGrade(classes)
        setClassroom(null)
      }
      if (props.currentStep === 'classroom') {
        setClassroom(classes[0])
      }
    }
  }

  return (
    <FormContent>
      {props.currentStep === 'grade' && (
        <FormContent.Question
          title='학년을 알려주세요!'
          description='같은 학년의 친구들과 학습 시간을 비교해 볼 수 있어요.'
        />
      )}
      {props.currentStep === 'classroom' && (
        <FormContent.Question
          title={`마지막이에요! ${hasDepartment ? '과를' : '반을'} 알려주세요!`}
          description={`같은 ${hasDepartment ? '과' : '반'} 친구들과 학습 시간을 비교해 볼 수 있어요.`}
        />
      )}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<LoadingFallback />}>
          <SchoolClassFilter
            grade={grade}
            classroom={classroom}
            currentStep={props.currentStep}
            userType={userType === 'M' || userType === 'H' ? userType : null}
            schoolCode={
              userType === 'M' && middleschool !== null
                ? middleschool.code
                : userType === 'H' && highschool !== null
                  ? highschool.code
                  : ''
            }
            hasDepartment={hasDepartment}
            onNextClick={handleNextClick}
          />
        </Suspense>
      </ErrorBoundary>
    </FormContent>
  )
}
