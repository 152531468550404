import React from 'react'

import { ChartTitle } from '../StudyingTimeChart'

import DeferredComponent from './DeferredComponent'

export default function ChartLoadingFallback() {
  return (
    <DeferredComponent height={365}>
      <ChartSkeleton />
    </DeferredComponent>
  )
}

function ChartSkeleton() {
  return (
    <div className='flex flex-col gap-[20px]'>
      <ChartTitle />
      <div className='animate-pulse  flex h-[299px] tablet-sm:h-[316px] mt-[13px] pb-[93px]'>
        <div className='flex flex-col items-center w-[220px]'>
          <div className='grow'>
            <div className='w-[16px] h-full bg-skeleton rounded'></div>
          </div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
        <div className='flex items-center w-full h-full grow gap-[10px] flex-col justify-end'>
          <div className='max-w-[20px] w-1/3 h-full bg-skeleton rounded'></div>
          <div className='w-[18px] h-[16px] bg-skeleton rounded'></div>
        </div>
      </div>
    </div>
  )
}
