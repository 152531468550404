export const WEEK_AXIS_DATA = {
  xAxis: [
    1711929600000, 1712016000000, 1712102400000, 1712188800000, 1712275200000, 1712361600000,
    1712448000000,
  ],
  series: [0, 0, 0, 0, 0, 0, 0],
  yAxisMax: 24,
}

export const BAR_COLOR_STOP = [
  { offset: 0, color: '#FFB200' },
  { offset: 1, color: '#FDCA53' },
]

export const BAR_OPTION = {
  animation: false,
  tooltip: {
    trigger: 'item',
    position: 'top',
    backgroundColor: 'black',
    borderWidth: 0,
    padding: 0,
    axisPointer: {
      type: 'none',
    },
  },
  grid: {
    left: '40px',
    right: '0px',
    top: '10%',
    bottom: '25%',
  },
  xAxis: {
    type: 'category',
    data: WEEK_AXIS_DATA.xAxis,
    axisLabel: {
      interval: 0,
      formatter: function (value: string, index: number) {
        return index === 6 ? `{a|${value}}` : `{b|${value}}`
      },
      rich: {
        a: {
          width: 43,
          height: 20,
          fontSize: 12,
          color: '#FF8744',
          fontWeight: 900,
          backgroundColor: '#FFF5DE',
          borderRadius: 14,
        },
        b: {
          width: 43,
          height: 20,
          fontSize: 12,
          borderRadius: 14,
          color: 'black',
          fontWeight: 500,
        },
      },
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#EEEEEE',
      },
    },
  },
  yAxis: {
    type: 'value',
    splitNumber: 4,
    min: 0,
    max: 24,
    interval: 6,
    axisLabel: {
      fontSize: 11,
      color: '#A1A1A1',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#FDCA53',
        type: 'dashed',
        width: 1,
      },
    },
  },
  series: [
    {
      type: 'custom',
      select: {
        disabled: true,
      },
      data: WEEK_AXIS_DATA.series,
      renderItem: function (params: any, api: any) {
        const categoryWidth = api.size([1, 0])[0]
        let barWidth = categoryWidth * 0.33 // 카테고리 너비의 33%
        barWidth = barWidth >= 20 ? 20 : barWidth

        const value = api.value(1)
        const height = value === 0 ? 2 : api.size([0, value])[1] // 0인 경우 최소 높이 10
        const base = api.coord([0, 0])[1] // y축 0의 위치를 기준으로 가져옵니다.
        const xPosition = api.coord([api.value(0), value])[0] - barWidth / 2
        const yPosition = value === 0 ? base - height : api.coord([0, value])[1] // 0 값인 경우 위치 조정

        return {
          type: 'rect',
          shape: {
            x: xPosition,
            y: yPosition,
            width: barWidth,
            height,
            r: [3, 3, 0, 0],
          },
          style: api.style({
            borderRadius: [3, 3, 0, 0],
          }),
        }
      },
    },
  ],
}
