import React from 'react'

import { useMyPage, useStudyingReport } from '../module/myPage/hook'

import StudyingReportPreparingChart from './StudyingPreparingChart'
import StudyingTimeReport from './StudyingTimeReport'

export default function StudyingReport() {
  const { noContents } = useStudyingReport()
  const { surveyParticipated } = useMyPage()
  const chartStyle = !surveyParticipated
    ? 'w-full h-[369px] tablet-sm:h-[408px]'
    : `w-full h-[${noContents ? '890' : '1078'}px] tablet-sm:h-[844.5px]`

  return (
    <div
      className={`${!surveyParticipated && 'blur-[3px]'} select-none px-[20px] tablet-md:px-[42px] tablet-lg:px-[101px] mb-[10px]`}
    >
      <div
        className={`w-full flex flex-col text-[14px] mb-[-30px] items-center justify-center ${chartStyle}`}
      >
        <div className={chartStyle}>
          {!surveyParticipated ? (
            <StudyingReportPreparingChart className={chartStyle} />
          ) : (
            <StudyingTimeReport className={'w-full h-[297px] tablet-sm:h-[336px]'} />
          )}
        </div>
      </div>
    </div>
  )
}
