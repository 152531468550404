import { type UserTypeName, type UserGroup, type SurveyStep } from './types'

export function getUserType(userGroup: UserGroup | null): UserTypeName {
  switch (userGroup) {
    case 'college':
      return 'UC'
    case 'highschool':
      return 'H'
    case 'repeater':
      return 'H4'
    case 'middleschool':
      return 'M'
    case 'elemantaryschool':
      return 'E'
    case 'general':
      return 'AG'
    case 'generalCollege':
      return 'UC'
    default:
      throw new Error('Not exist user type')
  }
}

export function getUserGroup(userType: UserTypeName | null): UserGroup | null {
  if (userType === null) return null

  switch (userType) {
    case 'AG':
      return 'general'
    case 'E':
      return 'elemantaryschool'
    case 'H':
      return 'highschool'
    case 'H4':
      return 'repeater'
    case 'M':
      return 'middleschool'
    case 'UC':
      return 'college'
    default:
      throw new Error('Not exist user type')
  }
}

export function getSurveyStepByUserGroup(
  userGroup: UserGroup,
  skipFirstStep = false,
  skipLastStep = false,
): SurveyStep[] {
  let steps: SurveyStep[] = []

  switch (userGroup) {
    case 'none':
    case 'college':
      steps = !skipLastStep
        ? ['userNickname', 'userGroup', 'schoolName', 'major', 'year', 'examinations']
        : ['userNickname', 'userGroup', 'schoolName', 'major', 'year']
      break
    case 'highschool':
      steps = ['userNickname', 'userGroup', 'schoolName', 'grade', 'classroom']
      break
    case 'repeater':
      steps = ['userNickname', 'userGroup']
      break
    case 'middleschool':
      steps = ['userNickname', 'userGroup', 'schoolName', 'grade', 'classroom']
      break
    case 'elemantaryschool':
      steps = ['userNickname', 'userGroup']
      break
    case 'general':
      // MEMO: for 'AG' to 'general' step to edit exams
      steps = !skipLastStep
        ? ['userNickname', 'userGroup', 'examinations', 'examinationsCollege']
        : ['userNickname', 'userGroup', 'examinations']
      break
    case 'generalCollege':
      steps = [
        'userNickname',
        'userGroup',
        'examinations',
        'examinationsCollege',
        'schoolName',
        'major',
        'year',
      ]
      break
    default:
      throw new Error('Not exist user group information.')
  }

  return skipFirstStep ? steps.slice(1) : steps
}
