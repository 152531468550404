import { type NicknameFailReason } from '../api/interfaces/sconnStudyData'

export const nicknameErrorMessage: { [key in NicknameFailReason]: string } = {
  IS_TOO_SHORT: '최소 2글자 이상이어야 해요.',
  IS_HAS_INVALID_CHARACTER:
    '특수문자는 포함할 수 없습니다. 한글, 영문, 숫자, 띄어쓰기만 입력해 주세요.',
  IS_TOO_LONG: '최대 12글자까지 가능해요.',
  IS_NOT_VALID: '욕/비방하는 닉네임은 사용할 수 없어요.',
  IS_EXIST: '이미 존재하는 닉네임이에요.',
}
