import { IonModal, IonPicker, IonPickerColumn, IonPickerColumnOption } from '@ionic/react'
import React, { useEffect, useState } from 'react'

import Button from './common/Button'

interface GoalOfDaysEditModalProps {
  open: boolean
  hours: number
  minutes: number
  loading: 'true' | 'false'
  onCancel: () => void
  onOk: (seconds: number) => void
}

export default function GoalOfDaysEditModal(props: GoalOfDaysEditModalProps) {
  const [hours, setHours] = useState<number>(props.hours)
  const [minutes, setMinutes] = useState<number>(props.minutes)

  useEffect(() => {
    setHours(props.hours)
    setMinutes(props.minutes)
  }, [props.minutes, props.hours])

  return (
    <IonModal
      id='custom-modal'
      className='picker-modal'
      keepContentsMounted
      isOpen={props.open}
      onIonModalDidDismiss={props.onCancel}
    >
      <div className='flex flex-col h-full'>
        <span className='w-full text-center text-[15px] font-extrabold px-[20px] pt-[20px]'>
          목표 시간을 설정해주세요!
        </span>
        <div className='flex items-center justify-center h-full'>
          <IonPicker>
            <IonPickerColumn
              value={hours}
              onIonChange={({ detail }) => {
                if (typeof detail.value === 'number') {
                  setHours(detail.value)
                  if (detail.value === 24) {
                    setMinutes(0)
                  } else if (detail.value === 0) {
                    setMinutes(10)
                  }
                }
              }}
            >
              {[...Array(25).keys()].map((i) => (
                <IonPickerColumnOption key={i} value={i} className='left'>
                  {i}시간
                </IonPickerColumnOption>
              ))}
            </IonPickerColumn>
            <span className='text-[14px]'>:</span>
            <IonPickerColumn
              value={minutes}
              disabled={hours === 24}
              onIonChange={({ detail }) => {
                if (typeof detail.value === 'number') {
                  setMinutes(detail.value)
                }
              }}
            >
              {hours !== 0 && (
                <IonPickerColumnOption value={0} className='right'>
                  0분
                </IonPickerColumnOption>
              )}
              <IonPickerColumnOption value={10} className='right'>
                10분
              </IonPickerColumnOption>
              <IonPickerColumnOption value={20} className='right'>
                20분
              </IonPickerColumnOption>
              <IonPickerColumnOption value={30} className='right'>
                30분
              </IonPickerColumnOption>
              <IonPickerColumnOption value={40} className='right'>
                40분
              </IonPickerColumnOption>
              <IonPickerColumnOption value={50} className='right'>
                50분
              </IonPickerColumnOption>
            </IonPickerColumn>
          </IonPicker>
        </div>
        <div className='px-[20px] pb-[20px]'>
          <Button
            loading={props.loading}
            onClick={() => {
              const value = hours * 3600 + minutes * 60
              props.onOk(value)
            }}
          >
            완료
          </Button>
        </div>
      </div>
    </IonModal>
  )
}
