import React from 'react'
import Lottie from 'react-lottie'

import * as animationData from '../../assets/Loading.json'

interface SconnLoadingProps {
  width: number
  height: number
}

function SconnLoading(props: SconnLoadingProps) {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      {...props}
    />
  )
}

export default SconnLoading
