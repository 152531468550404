import { IonModal } from '@ionic/react'
import debounce from 'lodash/debounce'
import React, { type ChangeEvent, useState, useCallback } from 'react'

import { type NicknameFailReason } from '../api/interfaces/sconnStudyData'
import { nicknameErrorMessage } from '../constants/errorMessage'
import { useBookcafeData } from '../module/myPage/hook'
import { useUpdateNickname, useValidateNickname } from '../module/sconnStudyData/hook'

import Button from './common/Button'
import SearchInput, { type Validation } from './common/SearchInput'

interface EditNicknameProps {
  open: boolean
  onClose: () => void
}

export default function EditNicknameModal(props: EditNicknameProps) {
  const [validation, setValidation] = useState<Validation>('none')
  const [invalidmessage, setInvalidMessage] = useState<string>('FAIL')
  const { isPending, mutate } = useUpdateNickname()
  const { mutate: validate } = useValidateNickname()
  const { user, setUser } = useBookcafeData()

  const [filterText, setFilterText] = useState<string>(
    user?.suggestNickName ?? user?.nickName ?? '',
  )

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newNickname = e.target.value.trim()
    setFilterText(newNickname)
    debouncedValidateNickname(newNickname)
  }

  const debouncedValidateNickname = useCallback(
    debounce((nickname: string) => {
      validate(nickname, {
        onSuccess: (data, variables) => {
          if (data.result === 'Success' && user) {
            setValidation('valid')
          }
        },
        onError: (err) => {
          setValidation('invalid')
          setInvalidMessage(
            nicknameErrorMessage[err.message as NicknameFailReason] ||
              '알 수 없는 오류가 발생했습니다.',
          )
        },
      })
    }, 500),
    [validate, user],
  )

  const handleSaveNickname = () => {
    mutate(filterText, {
      onSuccess: (data, variables) => {
        if (data.result === 'Success' && user) {
          setValidation('valid')
          setUser({ ...user, nickName: variables })
          props.onClose()
        }
      },
      onError: (err) => {
        setValidation('invalid')
        setInvalidMessage(
          nicknameErrorMessage[err.message as NicknameFailReason] ||
            '알 수 없는 오류가 발생했습니다.',
        )
      },
    })
  }

  return (
    <IonModal id='sheet-modal' keepContentsMounted isOpen={props.open} backdropDismiss={false}>
      <div className='flex flex-col h-full p-[30px]'>
        <div className='flex items-center min-w-[50px] justify-end'>
          <button
            type='button'
            className='text-cancel-font'
            onClick={() => {
              setValidation('none')
              setInvalidMessage('FAIL')
              setFilterText(user?.suggestNickName ?? user?.nickName ?? '')
              props.onClose()
            }}
          >
            취소
          </button>
        </div>
        <SearchInput
          className='mt-[20px]'
          label='닉네임'
          id='userNickname'
          name='userNickname'
          placeholder='닉네임을 입력해 주세요.'
          value={filterText}
          required
          onChange={handleTextChange}
          validation={validation}
          invalidmessage={invalidmessage}
          validmessage={
            user?.nickName === filterText
              ? '이미 사용 중인 닉네임입니다.'
              : '사용할 수 있는 닉네임입니다.'
          }
        />
        <div className='overflow-auto grow' style={{ maxHeight: 'calc(100% - 60px)' }}></div>
        <Button
          type='button'
          loading={`${isPending}`}
          disabled={
            user?.nickName === filterText ||
            filterText === '' ||
            filterText.length < 2 ||
            filterText.length > 12 ||
            validation === 'invalid'
          }
          onClick={handleSaveNickname}
        >
          확인
        </Button>
      </div>
    </IonModal>
  )
}
