import { type User } from '../../api/interfaces/sconnStudyData'
import { type CustomDate } from '../../util/CustomDate'

export type MyPageState = {
  isOnline: boolean
  user: User | null
  surveyParticipated: boolean
  statsComplete: boolean
  statsPeriod: PeriodType
  statsDateRangeLabel: string
  statsUpdatedAt: string
  statsSelectedDate: CustomDate | null
  statsStartDate: CustomDate | null
  statsEndDate: CustomDate | null
  statsDuration: number
  statsIsNoContents: boolean
  statsIsToday: boolean
  statsDisplayContents: Array<{ title: string; studyTime: string; thumbnail: string }>
}

export type PeriodType = 'monthly' | 'weekly' | 'daily'

export const MYPAGE_SET_USER = 'MYPAGE/SET_USER' as const
export const MYPAGE_SET_IS_ONLINE = 'MYPAGE/SET_IS_ONLINE' as const
export const MYPAGE_SET_SURVEY_PARTICIPATED = 'MYPAGE/SET_SURVEY_PARTICIPATED' as const
export const MYPAGE_SET_STATS_COMPLETE = 'MYPAGE/SET_STATS_COMPLETE' as const
export const MYPAGE_SET_STATS_PERIOD = 'MYPAGE/SET_STATS_PERIOD' as const
export const MYPAGE_SET_STATS_DATE_RANGE_LABEL = 'MYPAGE/SET_STATS_DATE_RANGE_LABEL' as const
export const MYPAGE_SET_STATS_UPDATED_AT = 'MYPAGE/SET_STATS_UPDATED_AT' as const
export const MYPAGE_SET_STATS_SELECTED_DATE = 'MYPAGE/SET_STATS_SELECTED_DATE' as const
export const MYPAGE_SET_STATS_START_DATE = 'MYPAGE/SET_STATS_START_DATE' as const
export const MYPAGE_SET_STATS_END_DATE = 'MYPAGE/SET_STATS_END_DATE' as const
export const MYPAGE_SET_STATS_DURATION = 'MYPAGE/SET_STATS_DURATION' as const
export const MYPAGE_SET_STATS_IS_NO_CONTENTS = 'MYPAGE/SET_STATS_IS_NO_CONTENTS' as const
export const MYPAGE_SET_STATS_IS_TODAY = 'MYPAGE/SET_STATS_IS_TODAY' as const
export const MYPAGE_SET_STATS_DISPLAY_CONTENTS = 'MYPAGE/SET_STATS_DISPLAY_CONTENTS' as const

export type Action =
  | {
      type: typeof MYPAGE_SET_USER
      payload: User
    }
  | {
      type: typeof MYPAGE_SET_IS_ONLINE
      payload: boolean
    }
  | { type: typeof MYPAGE_SET_SURVEY_PARTICIPATED; payload: boolean }
  | { type: typeof MYPAGE_SET_STATS_COMPLETE; payload: boolean }
  | { type: typeof MYPAGE_SET_STATS_PERIOD; payload: PeriodType }
  | { type: typeof MYPAGE_SET_STATS_DATE_RANGE_LABEL; payload: string }
  | { type: typeof MYPAGE_SET_STATS_UPDATED_AT; payload: string }
  | { type: typeof MYPAGE_SET_STATS_SELECTED_DATE; payload: CustomDate | null }
  | { type: typeof MYPAGE_SET_STATS_START_DATE; payload: CustomDate | null }
  | { type: typeof MYPAGE_SET_STATS_END_DATE; payload: CustomDate | null }
  | { type: typeof MYPAGE_SET_STATS_DURATION; payload: number }
  | { type: typeof MYPAGE_SET_STATS_IS_NO_CONTENTS; payload: boolean }
  | { type: typeof MYPAGE_SET_STATS_IS_TODAY; payload: boolean }
  | {
      type: typeof MYPAGE_SET_STATS_DISPLAY_CONTENTS
      payload: Array<{ title: string; studyTime: string; thumbnail: string }>
    }
