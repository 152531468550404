import React from 'react'

import { useStudyingReport } from '../module/myPage/hook'
import { type PeriodType } from '../module/myPage/types'
import { type CustomDate } from '../util/CustomDate'

import { DateRangePickerModal } from './DateRangePickerModal'
import { ChevronLeftMDIcon, ChevronRightMDIcon, ReportCalendarIcon } from './icons/icon'

interface StudyingDateRangePickerProps {
  openCalandar: boolean
  periodType: PeriodType
  onClickCalandar: () => void
  onCancel: () => void
  onOk: (selectedDate: CustomDate | null, dateRange: [CustomDate | null, CustomDate | null]) => void
}

export default function StudyingDateRangePicker(props: StudyingDateRangePickerProps) {
  const { statsDateRangeLabel } = useStudyingReport()

  return (
    <>
      <div className='flex gap-[10px] items-center justify-center my-[15px]'>
        <ChevronLeftMDIcon onClick={props.onClickCalandar} />
        <div className='flex gap-[6px] items-center' onClick={props.onClickCalandar}>
          <ReportCalendarIcon />
          <span className='text-[14px] font-medium'>{statsDateRangeLabel}</span>
        </div>
        <ChevronRightMDIcon onClick={props.onClickCalandar} />
      </div>
      <DateRangePickerModal open={props.openCalandar} onCancel={props.onCancel} onOk={props.onOk} />
    </>
  )
}
