import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useBookcafeData } from '../module/myPage/hook'
import { useSurvey } from '../module/survey/hook'

import PrivacyPolicy from './PrivacyPolicy'

export default function IntroSurvey() {
  const navigate = useNavigate()
  const { user } = useBookcafeData()
  const { setSurveyOnModal } = useSurvey()

  useEffect(() => {
    setSurveyOnModal(false)
  }, [])

  useEffect(() => {
    if (user?.consentLearningData) {
      navigate('/survey')
    }
  }, [user?.consentLearningData])

  const showPrivacyPolicy = user && !user.consentLearningData

  return <>{showPrivacyPolicy && <PrivacyPolicy />}</>
}
