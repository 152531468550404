import { createAnimation, IonToast } from '@ionic/react'
import { useState } from 'react'

function useToast(cssClass: string) {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const renderToast = () => (
    <IonToast
      mode='md'
      cssClass={cssClass}
      isOpen={isOpen}
      message={message}
      onDidDismiss={() => {
        setOpen(false)
      }}
      duration={1000}
      leaveAnimation={(baseEl) =>
        createAnimation().addElement(baseEl).duration(500).fromTo('opacity', '1', '0')
      }
    />
  )

  return { isOpen, setOpen, message, setMessage, renderToast }
}

export function useErrorToast() {
  const { setOpen, setMessage, renderToast } = useToast('toast-error')
  return {
    setOpenError: setOpen,
    setErrorMessage: setMessage,
    errorToast: renderToast,
  }
}

export function useInfoToast() {
  const { isOpen, setOpen, message, setMessage, renderToast } = useToast('toast')
  return { setOpenToast: setOpen, setMessage, renderToast }
}
