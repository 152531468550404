import React, { type MouseEvent, type InputHTMLAttributes } from 'react'

import { SearchIcon, XBackgroundIcon } from '../icons/icon'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  icon?: string
  description?: string
  removable?: boolean
  onClickRemove?: (e: MouseEvent<HTMLButtonElement>, id: string) => void
}

export default function ButtonCheckbox(props: CheckboxProps) {
  return (
    <div className='relative'>
      <div
        className={`w-full border-solid rounded-[12px] tablet-sm:rounded-[7px] border-[0.5px] ${
          props.checked && !props.removable
            ? 'border-main-border shadow-selected'
            : 'border-sub-border shadow-none'
        }`}
      >
        {!props.removable && (
          <input className='hidden' type='checkbox' {...props} id={`${props.id}`} />
        )}
        <label
          htmlFor={`${props.id}`}
          className={`block text-center p-[15px] ${props.checked && !props.removable ? 'font-bold' : 'font-normal'}`}
        >
          <div className='flex items-center justify-center gap-[6px]'>
            <p className='line-clamp-1 text-[15px] tablet-sm:text-[16px]'>{props.label}</p>
            {props.icon && <SearchIcon stroke='black' />}
          </div>
          {props.description && <p className='text-[14px] text-sub-border'>{props.description}</p>}
        </label>
      </div>
      {props.removable && (
        <button
          className='absolute -top-[6px] -right-[6px] z-9'
          onClick={(e) => {
            if (props.onClickRemove && props.id) {
              props.onClickRemove(e, props.id)
            }
          }}
        >
          <XBackgroundIcon />
        </button>
      )}
    </div>
  )
}
