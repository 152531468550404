import { type ReactNode, createContext, useContext } from 'react'

import MockSconnStudyDataClient from '../../api/mockSconnStudyDataClient'
import SconnStudyData from '../../api/sconnStudyData'
import SconnStudyDataClient from '../../api/sconnStudyDataClient'
import { useNativeInteractor } from '../nativeInteractor/context'

import { type SconnStudyDataState } from './types'

export const SconnStudyDataApiContext = createContext<SconnStudyDataState | null>(null)

export function SconnStudyDataApiProvider({ children }: { children: ReactNode }) {
  const { interactor } = useNativeInteractor()

  const client =
    process.env.NODE_ENV !== 'development'
      ? new SconnStudyDataClient(interactor)
      : new MockSconnStudyDataClient()

  const sconnStudyData = new SconnStudyData(client)

  return (
    <SconnStudyDataApiContext.Provider value={{ sconnStudyData }}>
      {children}
    </SconnStudyDataApiContext.Provider>
  )
}

export function useSconnStudyDataApi() {
  const context = useContext(SconnStudyDataApiContext)

  if (context === null) {
    throw Error('SconnStudyData instance is null: Cannot proceed with operation.')
  }

  return context
}
