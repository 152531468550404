import { getSurveyStepByUserGroup, getUserType } from './helper'
import {
  type Action,
  type SurveyState,
  SURVEY_NEXT_STEPS,
  SURVEY_PREV_STEPS,
  SURVEY_SET_EXAMINATION,
  SURVEY_SET_GRADE,
  SURVEY_SET_MAJOR,
  SURVEY_SET_CLASSROOM,
  SURVEY_SET_STEPS,
  SURVEY_SET_USER_GROUP,
  SURVEY_SET_UNIVERSITY,
  SURVEY_SET_MIDDLESCHOOL,
  SURVEY_SET_HIGHSCHOOL,
  SURVEY_SET_EXAMINATION_HAS_USER_SEARCH,
  SURVEY_SET_EXAMINATION_CHECKBOX_ITEM,
  SURVEY_SET_SKIP_FIRST_STEP,
  SURVEY_SET_SKIP_LAST_STEP,
  SURVEY_SET_CHECKBOX_IS_UNIVERSITY,
  SURVEY_SET_CHECKED_USER_GROUP,
  SURVEY_SET_YEAR,
  SURVEY_SET_IS_PENDING,
  SURVEY_SET_ON_MODAL,
  SURVEY_SET_LATEST_RESULT,
  SURVEY_SET_START_STEP,
} from './types'

export function surveyReducer(state: SurveyState, action: Action): SurveyState {
  switch (action.type) {
    case SURVEY_SET_USER_GROUP:
      return {
        ...state,
        userGroup: action.payload,
        activeStep:
          action.payload === 'none' // UserGroupSelect에서 surveyOnModal이 false일 때, UserGroupSelect 컴포넌트가 step대로 보이기 위해 추가
            ? state.steps.includes('userNickname')
              ? 1
              : 0
            : state.activeStep,
        checkedUserGroup: action.payload === 'none' ? null : state.checkedUserGroup,
        surveyed: {
          userType: action.payload === 'none' ? null : getUserType(action.payload),
          university: null,
          middleschool: null,
          highschool: null,
          major: null,
          grade: null,
          year: null,
          classroom: null,
          examinations:
            action.payload === 'generalCollege' ||
            action.payload === 'general' ||
            action.payload === 'college'
              ? state.surveyed.examinations
              : null,
        },
        hasUserSearch:
          action.payload === 'generalCollege' ||
          action.payload === 'general' ||
          action.payload === 'college'
            ? state.hasUserSearch
            : false,
        checkboxIsUniversity:
          action.payload === 'generalCollege' ||
          action.payload === 'general' ||
          action.payload === 'college'
            ? state.checkboxIsUniversity
            : null,
        isSkipLastStep: !state.surveyOnModal ? false : state.isSkipLastStep,
        surveyResult: {
          group:
            action.payload === 'generalCollege' ||
            action.payload === 'general' ||
            action.payload === 'college'
              ? state.surveyResult.group
              : null,
          exams:
            action.payload === 'generalCollege' ||
            action.payload === 'general' ||
            action.payload === 'college'
              ? state.surveyResult.exams
              : null,
          userType: action.payload === 'none' ? null : getUserType(action.payload),
        },
      }
    case SURVEY_SET_STEPS:
      return {
        ...state,
        steps: getSurveyStepByUserGroup(
          action.payload,
          state.isSkipFirstStep,
          state.isSkipLastStep,
        ),
      }
    case SURVEY_NEXT_STEPS:
      return {
        ...state,
        activeStep:
          state.activeStep + 1 > state.steps.length - 1 ? state.activeStep : state.activeStep + 1,
      }
    case SURVEY_PREV_STEPS:
      return {
        ...state,
        activeStep: state.activeStep - 1 === -1 ? 0 : state.activeStep - 1,
      }
    case SURVEY_SET_START_STEP:
      return {
        ...state,
        activeStep: state.steps.indexOf(action.payload),
      }
    case SURVEY_SET_SKIP_FIRST_STEP:
      return {
        ...state,
        // skip 이후, activeStep도 1에서 0으로 줄이기 위해 PrevSteps 처럼 수정
        activeStep:
          state.activeStep === 1
            ? state.activeStep - 1 === -1
              ? 0
              : state.activeStep - 1
            : state.activeStep,
        isSkipFirstStep: action.payload,
        steps: getSurveyStepByUserGroup(
          state.userGroup ?? 'college',
          action.payload,
          state.isSkipLastStep,
        ),
      }
    case SURVEY_SET_SKIP_LAST_STEP:
      return {
        ...state,
        isSkipLastStep: action.payload,
        steps: getSurveyStepByUserGroup(
          state.userGroup ?? 'college',
          state.isSkipFirstStep,
          action.payload,
        ),
      }
    case SURVEY_SET_UNIVERSITY:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          major: null,
          university: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: { ...state.surveyResult.group, school: action.payload.id, dept1: null },
        },
      }
    case SURVEY_SET_MIDDLESCHOOL:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          grade: null,
          classroom: null,
          middleschool: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: {
            ...state.surveyResult.group,
            school: action.payload.id,
            grade: null,
            class: null,
          },
        },
      }
    case SURVEY_SET_HIGHSCHOOL:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          grade: null,
          classroom: null,
          major: null,
          highschool: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: {
            ...state.surveyResult.group,
            school: action.payload.id,
            grade: null,
            class: null,
            dept1: null,
          },
        },
      }
    case SURVEY_SET_MAJOR:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          major: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: {
            ...state.surveyResult.group,
            dept1: action.payload.id,
          },
        },
      }
    case SURVEY_SET_YEAR:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          year: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: {
            ...state.surveyResult.group,
            year: action.payload,
          },
        },
      }
    case SURVEY_SET_GRADE:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          grade: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: {
            ...state.surveyResult.group,
            grade: action.payload[0].grade.toString(),
          },
        },
      }
    case SURVEY_SET_CLASSROOM:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          classroom: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          group: {
            ...state.surveyResult.group,
            class: action.payload?.id,
          },
        },
      }
    case SURVEY_SET_EXAMINATION:
      return {
        ...state,
        surveyed: {
          ...state.surveyed,
          examinations: action.payload,
        },
        surveyResult: {
          ...state.surveyResult,
          exams:
            action.payload?.map((i) => i.code).includes('none') &&
            action.payload?.map((i) => i.id).includes('11')
              ? null
              : action.payload.map((i) => i.id),
        },
      }
    case SURVEY_SET_CHECKED_USER_GROUP:
      return {
        ...state,
        checkedUserGroup: action.payload,
      }
    case SURVEY_SET_EXAMINATION_HAS_USER_SEARCH:
      return {
        ...state,
        hasUserSearch: action.payload,
      }
    case SURVEY_SET_EXAMINATION_CHECKBOX_ITEM:
      return {
        ...state,
        checkboxExaminations: action.payload,
      }
    case SURVEY_SET_CHECKBOX_IS_UNIVERSITY:
      return {
        ...state,
        checkboxIsUniversity: action.payload,
      }
    case SURVEY_SET_IS_PENDING:
      return {
        ...state,
        surveyIsPending: action.payload,
      }
    case SURVEY_SET_ON_MODAL:
      return {
        ...state,
        activeStep: action.payload ? state.activeStep : 0,
        latestResult: action.payload ? state.latestResult : null,
        surveyOnModal: action.payload,
      }
    case SURVEY_SET_LATEST_RESULT:
      return {
        ...state,
        latestResult: action.payload,
        surveyResult: {
          userType: action.payload.userType,
          group:
            action.payload.group === null
              ? null
              : {
                  school: action.payload.group.school?.id,
                  grade: action.payload.group.grade,
                  class: action.payload.group.class,
                  year: action.payload.group.year,
                  dept1: action.payload.group.dept1?.id,
                  dept2: action.payload.group.dept2?.id,
                },
          exams: action.payload.exams?.map((i) => i.id),
        },
      }
    default:
      throw Error('Not Exist Action In SurveyReducer')
  }
}
