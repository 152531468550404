import classNames from 'classnames'
import React, { type ButtonHTMLAttributes, type MouseEvent, type ReactNode } from 'react'

import SconnLoading from './SconnLoading'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  accent?: 'true' | 'false'
  loading?: 'true' | 'false'
  radioStyle?: 'true' | 'false'
}

export default function Button(props: ButtonProps) {
  return (
    <button
      {...props}
      className={classNames(
        'flex justify-center items-center disabled:bg-button-disabled font-bold h-[45px] text-[14px] tablet-sm:text-[16px] tablet-sm:h-[48px] tablet-sm:pt-[12px] tablet-sm:pb-[13px] w-full rounded-[12px] tablet-sm:rounded-[7px]',
        (props.accent === 'true' && !props.disabled) || props.radioStyle === 'true'
          ? 'text-black'
          : 'text-white',
        props.accent === 'true' ? 'bg-brand-primary' : 'bg-button-primary',
        props.radioStyle === 'true' && 'border-main-border shadow-selected bg-transparent',
      )}
    >
      {props.loading === 'true' ? (
        <>
          <span>진행중...</span>
          <div>
            <SconnLoading width={30} height={30} />
          </div>
        </>
      ) : (
        props.children
      )}
    </button>
  )
}
