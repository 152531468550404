import { isPlatform } from '@ionic/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { type User } from '../../api/interfaces/sconnStudyData'
import { useNativeInteractor } from '../../module/nativeInteractor/context'
import { formatThousandSeparated } from '../../util/stringUtils'
import {
  ChargeSconnIcon,
  ChevronBelowIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CouponIcon,
  HoldSconnIcon,
  SconnServiceIcon,
  SettingIcon,
} from '../icons/icon'

export default function AccountNavigation({ user }: { user: User | null }) {
  const navigate = useNavigate()
  const { interactor } = useNativeInteractor()
  const [openInfo, setOpenInfo] = useState<boolean>(false)

  const handleClickSconnService = () => {
    console.log('스콘 서비스 클릭')
    interactor.showServiceList()
  }

  const handleClickSetting = () => {
    console.log('세팅 모달 열기')
    interactor.showSettingModal()
  }

  const handleClickMyAccount = () => {
    console.log('계정 클릭, set surveyParticipated true')
    navigate('account')
  }
  const handleClickCoupon = () => {
    console.log('쿠폰 클릭, set surveyParticipated false')
    interactor.showCouponPage()
  }
  const handleClickHoldSconn = () => {
    console.log('보유 스콘 클릭, set statsComplete toggle')
    interactor.showHoldPointPage()
  }
  const handleClickChageSconn = () => {
    console.log('스콘 충전')
    interactor.showSconnChargeModal()
  }

  return (
    <>
      {isPlatform('ios') ? (
        <>
          <div className='flex h-[46px] pl-[20px] tablet-md:px-[42px] tablet-lg:px-[101px] items-center justify-between'>
            <div className='flex items-center gap-[10px]'>
              {user?.nickName && (
                <span className='flex gap-[5px] font-medium'>
                  <span className='max-w-[220px] tablet-sm:max-w-full text-ellipsis overflow-hidden whitespace-nowrap'>
                    <span className='text-decoration__underline--bold h-[20px]'>
                      {user?.nickName}
                    </span>
                  </span>
                  님
                </span>
              )}
              <button
                id='ios-open-info'
                title='계정 정보 더보기'
                onClick={() => {
                  setOpenInfo((prev) => !prev)
                }}
              >
                {openInfo ? <ChevronUpIcon /> : <ChevronBelowIcon />}
              </button>
            </div>

            <button
              id='ios-open-setting'
              title='설정 열기'
              className='px-[15px] tablet-sm:hidden'
              onClick={handleClickSetting}
            >
              <SettingIcon />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='flex gap-[10px] h-[50px] px-[10px] items-center justify-between tablet-sm:hidden'>
            <button
              id='aos-open-sconn-service'
              title='서비스 리스트 열기'
              onClick={handleClickSconnService}
            >
              <SconnServiceIcon />
            </button>
            <button id='aos-open-setting' title='설정 열기' onClick={handleClickSetting}>
              <SettingIcon />
            </button>
          </div>
          <div className='flex gap-[10px] h-[46px] pl-[20px] tablet-md:px-[42px] tablet-lg:px-[101px] items-center justify-center tablet-sm:justify-start'>
            {user?.nickName && (
              <span className='flex gap-[5px] font-medium'>
                <span className='max-w-[220px] tablet-sm:max-w-full text-ellipsis overflow-hidden whitespace-nowrap'>
                  <span className='text-decoration__underline--bold h-[20px]'>
                    {user?.nickName}
                  </span>
                </span>
                님
              </span>
            )}
            <button
              id='aos-open-info'
              title='계정 정보 더보기'
              onClick={() => {
                setOpenInfo((prev) => !prev)
              }}
            >
              {openInfo ? <ChevronUpIcon /> : <ChevronBelowIcon />}
            </button>
          </div>
        </>
      )}
      <div className='tablet-sm:divide-y px-[20px] tablet-md:px-[42px] tablet-lg:px-[101px]'>
        {openInfo && (
          <>
            <div className='flex items-center justify-between h-[46px]'>
              <span className='text-[13px]'>스콘 북카페 계정</span>
              <ChevronRightIcon stroke='black' onClick={handleClickMyAccount} />
            </div>
            <div className='flex items-center justify-between h-[46px]'>
              <div className='flex gap-[8px] items-center'>
                <CouponIcon />
                <span className='text-[13px]'>쿠폰</span>
              </div>
              <div className='flex gap-[10px] items-center'>
                <span className='font-black text-[14px]'>{user?.coupon} 개</span>
                <ChevronRightIcon stroke='black' onClick={handleClickCoupon} />
              </div>
            </div>
          </>
        )}
        <div>
          <div className='flex items-center justify-between'>
            <div className='flex gap-[8px] items-center h-[46px]'>
              <HoldSconnIcon />
              <span className='text-[13px]'>보유 스콘</span>
            </div>
            <div className='flex gap-[10px] items-center'>
              {user?.cash !== undefined && (
                <span className='font-black text-[14px]'>
                  {formatThousandSeparated(user?.cash)} 스콘
                </span>
              )}
              <button
                className='hidden tablet-sm:block rounded-md bg-[#FECB4C] text-[12px] font-bold w-[87px] py-[5px]'
                onClick={handleClickChageSconn}
              >
                스콘 충전
              </button>
              <ChevronRightIcon stroke='black' onClick={handleClickHoldSconn} />
            </div>
          </div>
          <button
            className='tablet-sm:hidden flex items-center gap-[8px] justify-center rounded-md bg-[#FECB4C] text-[14px] font-bold px-[20px] h-[46px] w-full'
            onClick={handleClickChageSconn}
          >
            <ChargeSconnIcon />
            스콘 충전
          </button>
        </div>
        <div></div>
      </div>
    </>
  )
}
