import React, { type ReactNode } from 'react'

import Header from './Header'

export default function SurveyLayout({ children }: { children: ReactNode }) {
  return <>{children}</>
}

function Section({ children }: { children: ReactNode }) {
  return <section style={{ height: 'calc(100% - 80px)' }}>{children}</section>
}

SurveyLayout.Header = Header
SurveyLayout.Section = Section
