import React, { type ChangeEvent, useState, useMemo, useEffect } from 'react'

import { useSurvey, useSurveySteps } from '../module/survey/hook'
import { type OkNo } from '../module/survey/types'
import { appendObjectMarker } from '../util/stringUtils'

import Radio from './common/Radio'
import FormContent from './surveyFormContentLayout'

export default function ExaminationCollegeSelect() {
  const { setUserGroup } = useSurveySteps()
  const { examinations, checkboxIsUniversity, setCheckboxIsUniversity } = useSurvey()
  const [checked, setChecked] = useState<OkNo | null>(null)

  useEffect(() => {
    setChecked(checkboxIsUniversity)
  }, [])

  const nameWithObjectMarker = useMemo(() => {
    return (
      examinations &&
      appendObjectMarker(
        examinations.length > 3
          ? examinations
              .slice(0, 3)
              .map((item) => item.name)
              .join(', ')
          : examinations.map((item) => item.name).join(', '),
        examinations.length > 3,
      )
    )
  }, [examinations])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value as OkNo
    setChecked(result)
    setCheckboxIsUniversity(result)
  }

  return (
    <FormContent>
      <FormContent.Grow>
        <FormContent.Question
          title={`혹시 ${examinations === null || (examinations[0].id === '11' && examinations[0].code === 'none') ? '' : ` ${nameWithObjectMarker} 준비중인 `}대학생이세요?`}
        />
        <ul className='grid grid-cols-1 gap-y-[15px] gap-x-[16px] mt-[50px]'>
          <li>
            <Radio
              id='ok'
              name='is-college'
              value='ok'
              label='네, 대학생입니다.'
              checked={checked === 'ok'}
              onChange={handleChange}
            />
          </li>
          <li>
            <Radio
              id='no'
              name='is-college'
              value='no'
              label='아닙니다.'
              checked={checked === 'no'}
              onChange={handleChange}
            />
          </li>
        </ul>
      </FormContent.Grow>
      <FormContent.ProcessButton
        disabled={checked === null}
        onNextClick={() => {
          if (checked !== null) {
            setUserGroup(checked === 'ok' ? 'generalCollege' : 'general')
          }
        }}
      />
    </FormContent>
  )
}
