import React, { useEffect } from 'react'

import { useSurvey } from '../../module/survey/hook'

import Contents from './Contents'
import ContentsRow from './ContentsRow'
import Header from './Header'

export default function MyAccountSetting({ children }: { children: React.ReactNode }) {
  const { setSurveyOnModal } = useSurvey()

  useEffect(() => {
    setSurveyOnModal(true)
  }, [])

  return <div className='flex flex-col'>{children}</div>
}

function Extensions({ children }: { children: React.ReactNode }) {
  return <>{children}</>
}

MyAccountSetting.Header = Header
MyAccountSetting.Contents = Contents
MyAccountSetting.ContentsRow = ContentsRow
MyAccountSetting.Extensions = Extensions
