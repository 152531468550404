import { IonModal } from '@ionic/react'
import React, { useEffect, useState, type ChangeEvent } from 'react'

import { CustomDate } from '../util/CustomDate'

import Button from './common/Button'
import Checkbox from './common/Checkbox'
import SearchInput from './common/SearchInput'
import { defaultMessage } from './DdayBanner'
import DdayBannerEditDate from './DdayBannerEditDate'
import { CalendarIcon } from './icons/icon'

interface DdayBannerEditModalProps {
  open: boolean
  data: {
    certName: string
    ddayTimeStamp: number
    message: string
    hideMessage: boolean
    onChangeCertName: (e: ChangeEvent<HTMLInputElement>) => void
    onChangeMessage: (e: ChangeEvent<HTMLInputElement>) => void
    onChangeHideMessage: (e: ChangeEvent<HTMLInputElement>) => void
    onClickCheckedCheckboxHideMessage: () => void
  }
  loadingOnOk?: 'true' | 'false'
  disabledOnOk: boolean
  onCancel: () => void
  onOk: (saveDate: number) => void
}

export function DdayBannerEditModal(props: DdayBannerEditModalProps) {
  const [certDateValue, setCertDateValue] = useState<string>(
    new CustomDate(props.data.ddayTimeStamp).toYearMonthDay('.'),
  )

  const [openCalandar, setOpenCalandar] = useState<boolean>(false)
  const [calandarValue, setCalandarValue] = useState<string>(
    new Date(
      props.data.ddayTimeStamp - new Date(props.data.ddayTimeStamp).getTimezoneOffset() * 60000,
    ).toISOString(),
  )
  const [selectedCalandarValue, setSelectedCalandarValue] = useState<number>(
    props.data.ddayTimeStamp,
  )

  useEffect(() => {
    setSelectedCalandarValue(props.data.ddayTimeStamp)
  }, [props.data.ddayTimeStamp])

  useEffect(() => {
    setCertDateValue(new CustomDate(selectedCalandarValue).toYearMonthDay('.'))
    setCalandarValue(
      new Date(
        selectedCalandarValue - new Date(selectedCalandarValue).getTimezoneOffset() * 60000,
      ).toISOString(),
    )
  }, [selectedCalandarValue])

  return (
    <IonModal id='sheet-modal' keepContentsMounted isOpen={props.open} backdropDismiss={false}>
      <div className='flex flex-col px-[30px] py-[20px] h-full'>
        {/* Header */}
        <div className='flex items-center justify-between'>
          <div className='w-[30px]'></div>
          <span className='text-center text-[15px] font-extrabold flex-1'>시험 디데이</span>
          <button
            type='button'
            className='text-cancel-font'
            onClick={() => {
              setSelectedCalandarValue(props.data.ddayTimeStamp)
              props.onCancel()
            }}
          >
            취소
          </button>
        </div>
        {/* Contents */}
        <div className='flex flex-col h-full'>
          <SearchInput
            className='mt-[55px]'
            label='시험명'
            id='certName'
            name='certName'
            maxLength={36}
            placeholder='시험명을 입력해 주세요.'
            value={props.data.certName}
            onChange={props.data.onChangeCertName}
          />
          <div className='flex flex-col mt-[20px]'>
            <label htmlFor='ddayDate' className='text-sub-font text-[13px] placeholder-sub-font'>
              날짜
            </label>
            <div className='relative'>
              <CalendarIcon className='absolute bottom-[12px]' />
              <input
                readOnly
                name='ddayDate'
                value={certDateValue}
                onClick={() => {
                  setCalandarValue(
                    new Date(
                      selectedCalandarValue -
                        new Date(props.data.ddayTimeStamp).getTimezoneOffset() * 60000,
                    ).toISOString(),
                  )
                  setOpenCalandar(true)
                }}
                className='pl-[30px] text-[14px] tablet-sm:text-[16px] w-full rounded-none border-transparent focus:border-b-black border-b-sub-border border-[0.5px] py-[12px] focus:outline-none'
              />
            </div>
          </div>
          <SearchInput
            className='mt-[20px]'
            label='응원의 한마디'
            id='message'
            name='message'
            maxLength={36}
            placeholder={defaultMessage}
            value={props.data.message}
            onChange={props.data.onChangeMessage}
          />
          <Checkbox
            id='hideMessage'
            name='hideMessage'
            checked={props.data.hideMessage}
            value={`${props.data.hideMessage}`}
            onChange={props.data.onChangeHideMessage}
            onClick={props.data.onClickCheckedCheckboxHideMessage}
            label='응원 숨기기'
          />
        </div>
        <div className='pb-[10px]'>
          <Button
            loading={props.loadingOnOk}
            disabled={props.disabledOnOk}
            onClick={() => {
              props.onOk(selectedCalandarValue)
            }}
          >
            완료
          </Button>
        </div>
      </div>
      <DdayBannerEditDate
        open={openCalandar}
        min={getDatepickerMinDate()}
        max={getDatepickerMaxDate()}
        defaultValue={calandarValue}
        value={calandarValue}
        onDatetimeChange={(e) => {
          if (typeof e.detail.value === 'string') {
            const isoDate = e.detail.value
            setCalandarValue(isoDate)
          }
        }}
        onCancel={() => {
          setCalandarValue(new Date(selectedCalandarValue).toISOString())
          setOpenCalandar(false)
        }}
        onOk={() => {
          const timestamp = Date.parse(calandarValue)
          setSelectedCalandarValue(timestamp)
          setOpenCalandar(false)
        }}
      />
    </IonModal>
  )
}

function getDatepickerMinDate() {
  const today = new Date()
  today.setFullYear(today.getFullYear() - 10)
  today.setMonth(0)
  today.setDate(1)
  return today.toISOString()
}

function getDatepickerMaxDate() {
  const today = new Date()
  today.setFullYear(today.getFullYear() + 10)
  today.setMonth(11)
  today.setDate(31)
  return today.toISOString()
}
