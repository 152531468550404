import React from 'react'

export default function Contents({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) {
  return (
    <div className='text-[13px] tablet-sm:text-[14px]'>
      <span className='px-[20px] tablet-sm:px-[30px] tablet-md:px-[42px] tablet-lg:px-[101px]'>
        {title}
      </span>
      <div className='flex flex-col divide-y px-[25px] tablet-sm:px-[47px] tablet-md:px-[59px] tablet-lg:px-[118px]'>
        {children}
      </div>
    </div>
  )
}
