import React, { type ChangeEvent, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { userGroups } from '../constants/checkboxItems'
import { useBookcafeData } from '../module/myPage/hook'
import { getUserGroup } from '../module/survey/helper'
import { useSurvey, useSurveySteps } from '../module/survey/hook'
import { type UserGroup } from '../module/survey/types'

import Radio from './common/Radio'
import FormContent from './surveyFormContentLayout'

export default function UserGroupSelect() {
  const { user } = useBookcafeData()
  const { setUserGroup } = useSurveySteps()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    latestResult,
    surveyOnModal,
    checkedUserGroup: checked,
    setCheckedUserGroup: setChecked,
  } = useSurvey()

  useEffect(() => {
    /**
     * MEMO: 여기서 초기화 할 필요없이, MyPage쪽에서 다시 Provider를 새로 감싸면 되지 않나?!!!!!!!!!!
     */
    if (!surveyOnModal) {
      setUserGroup('none')
    }

    searchParams.delete('userGroup')
    searchParams.delete('school')
    searchParams.delete('grade')
    searchParams.delete('classroom')
    searchParams.delete('major')
    searchParams.delete('isUniversity')
    searchParams.delete('examinations')

    setSearchParams(searchParams)
  }, [])

  useEffect(() => {
    if (latestResult) {
      setChecked(latestResult === null ? null : getUserGroup(latestResult.userType))
    }
  }, [latestResult])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value as UserGroup)
  }

  return (
    <FormContent>
      <FormContent.Grow className='mb-10'>
        <FormContent.Question
          title='어느 그룹에서 공부중이세요?'
          description={`${user?.nickName}님에 대해서 알려주세요!`}
        />
        <ul
          className={`grid grid-cols-1 gap-y-[15px] gap-x-[16px] ${surveyOnModal ? 'mt-[35px]' : 'mt-[50px]'}`}
        >
          {userGroups.map((group) => (
            <li key={group.id}>
              <Radio {...group} checked={checked === group.id} onChange={handleChange} />
            </li>
          ))}
        </ul>
      </FormContent.Grow>
      <FormContent.ProcessButton
        disabled={checked === null}
        onNextClick={() => {
          if (checked !== null) {
            setUserGroup(checked)
          }
        }}
      />
    </FormContent>
  )
}
