import React from 'react'
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom'

import Button from '../components/common/Button'

function isErrorWithMessage(error: any): error is Error {
  return error && typeof error.message === 'string'
}

export default function ErrorElement() {
  const navigate = useNavigate()
  const error = useRouteError()

  return (
    <div className='flex flex-col w-full h-screen p-10'>
      <div className='mb-5 font-extrabold'>
        <h1>오류 발생</h1>
        <p>죄송합니다. 예상치 못한 오류가 발생했습니다.</p>
      </div>
      <p>
        <span className='text-sm text-red-400'>
          {isRouteErrorResponse(error) ? (
            <i>{error.statusText}</i>
          ) : (
            isErrorWithMessage(error) && <i>{error.message}</i>
          )}{' '}
        </span>
      </p>
      <div className='flex-grow'></div>
      <Button
        onClick={() => {
          navigate('/my-page')
        }}
      >
        마이페이지로 돌아가기
      </Button>
    </div>
  )
}
