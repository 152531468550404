export class CustomDate extends Date {
  setYesterday() {
    this.setDate(this.getDate() - 1)
    return this
  }

  isSame(date: Date): boolean {
    return (
      this.getFullYear() === date.getFullYear() &&
      this.getMonth() === date.getMonth() &&
      this.getDate() === date.getDate()
    )
  }

  isToday(): boolean {
    return this.isSame(new Date())
  }

  // date to 'MMDD'
  toMonthDay(separator = '', pad = true): string {
    const month = this.formatPart(this.getMonth() + 1, pad)
    const day = this.formatPart(this.getDate(), pad)
    return `${month}${separator}${day}`
  }

  // date to 'YYYYMMDD'
  toYearMonthDay(separator = ''): string {
    const year = this.getFullYear()
    const month = this.formatPart(this.getMonth() + 1)
    const day = this.formatPart(this.getDate())
    return `${year}${separator}${month}${separator}${day}`
  }

  // date to 'YYYYMM'
  toYearMonth(separator = ''): string {
    const year = this.getFullYear()
    const month = this.formatPart(this.getMonth() + 1)
    return `${year}${separator}${month}`
  }

  // 'YY.MM.DD-YY.MM.DD' for tooltip period type 'monthly'
  toChartMonthRange(): string {
    const year = this.getFullYear()
    const yearShorten = year.toString().slice(-2)
    const month = this.formatPart(this.getMonth() + 1)

    const firstDay = this.formatPart(new Date(year, this.getMonth(), 1).getDate())
    const lastDay = this.formatPart(this.getDate())

    return `${yearShorten}.${month}.${firstDay}-${yearShorten}.${month}.${lastDay}`
  }

  // 'YYYY.MM.DD - YYYY.MM.DD' for sharing period type 'monthly'
  toShareMonthRange(): string {
    const year = this.getFullYear()
    const month = this.formatPart(this.getMonth() + 1)

    const firstDay = this.formatPart(new Date(year, this.getMonth(), 1).getDate())
    const lastDay = this.formatPart(this.getDate())

    return `${year}.${month}.${firstDay} - ${year}.${month}.${lastDay}`
  }

  // 'MM.DD-MM.DD' for tooltip period type 'weekly'
  toChartWeekRange(): string {
    const firstDayOfWeek = this.getDate() - this.getDay() + (this.getDay() === 0 ? -6 : 1)
    const firstDayDate = new Date(this.getFullYear(), this.getMonth(), firstDayOfWeek)
    const firstDayMonth = this.formatPart(firstDayDate.getMonth() + 1)
    const firstDay = this.formatPart(firstDayDate.getDate())

    const lastDayMonth = this.formatPart(this.getMonth() + 1)
    const lastDay = this.formatPart(this.getDate())

    if (`${firstDayMonth}.${firstDay}` === `${lastDayMonth}.${lastDay}`) {
      return `${lastDayMonth}.${lastDay}`
    }

    return `${firstDayMonth}.${firstDay}-${lastDayMonth}.${lastDay}`
  }

  // 'YYYY.MM.DD - YYYY.MM.DD' for sharing period type 'weekly'
  toShareWeekRange(): string {
    const year = this.getFullYear()

    const firstDayOfWeek = this.getDate() - this.getDay() + (this.getDay() === 0 ? -6 : 1)
    const firstDayDate = new Date(this.getFullYear(), this.getMonth(), firstDayOfWeek)
    const firstDayMonth = this.formatPart(firstDayDate.getMonth() + 1)
    const firstDay = this.formatPart(firstDayDate.getDate())

    const lastDayMonth = this.formatPart(this.getMonth() + 1)
    const lastDay = this.formatPart(this.getDate())

    if (`${year}.${firstDayMonth}.${firstDay}` === `${year}.${lastDayMonth}.${lastDay}`) {
      return `${year}.${lastDayMonth}.${lastDay}`
    }

    return `${year}.${firstDayMonth}.${firstDay} - ${year}.${lastDayMonth}.${lastDay}`
  }

  /**
   * 현재 날짜를 기준으로 7일 전의 시작일과 종료일을 반환
   *
   * @returns selectedDate.getLastSevenDaysRange()
   */
  getLastSevenDaysRange(): DateRange {
    const sevenDaysAgo = new CustomDate(this)
    sevenDaysAgo.setDate(this.getDate() - 6)
    return { from: sevenDaysAgo, to: new CustomDate(this) }
  }

  /**
   * 종료일을 포함한 주를 포함하여 최근 4주 반환
   * (3주 전 일자에서 현재 주의 마지막 일자까지 4주)
   *
   * @param startDate 주의 시작일
   * @param endDate 주의 종료일
   * @example endDate.getLastFourWeeksRange(startDate)
   */
  getLastFourWeeksRange(startDate: CustomDate): DateRange {
    const startOfRange = new CustomDate(startDate)
    startOfRange.setDate(startDate.getDate() - 21)

    return { from: startOfRange, to: new CustomDate(this) }
  }

  /**
   * 현재 날짜를 기준으로 4개월 전의 시작일과 종료일을 반환
   *
   * @example selectedDate.getLastFourMonthsRange()
   */
  getLastFourMonthsRange(): DateRange {
    const startOfRange = new CustomDate(this.getFullYear(), this.getMonth() - 3, 1)
    return { from: startOfRange, to: new CustomDate(this) }
  }

  /**
   * 이 메소드는 현재 CustomDate 인스턴스의 주 시작일을 계산합니다.
   * 이때, 주의 시작을 월요일로 간주하여 계산을 조정합니다.
   * 주 시작일을 계산한 후, 현재 인스턴스의 날짜를 그 날짜로 설정합니다.
   *
   * @returns
   */
  getStartOfWeek() {
    const date = new CustomDate(this)
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1) // Adjust to get Monday as the start of the week
    date.setDate(diff)
    return new CustomDate(date)
  }

  /**
   * 이 메소드는 현재 CustomDate 인스턴스의 주 종료일을 계산합니다.
   * 이때, 주의 시작을 월요일로 간주하여 계산을 조정합니다.
   *
   * @returns
   */
  getEndOfWeek(addedDays = 6) {
    const date = new CustomDate(this)
    const startOfWeek = date.getStartOfWeek()

    // outside-month가 이전 달에 해당할 때 다음달로 넘어가지 않도록
    if (startOfWeek.getMonth() < date.getMonth()) {
      date.setMonth(date.getMonth() - 1)
    }

    date.setDate(startOfWeek.getDate() + addedDays)

    return new CustomDate(date)
  }

  /**
   * For periodType: 'monthly'
   *
   * contents (Top 3 도서) 호출을 위한 당월 범위 반환
   */
  getOneMonthRange(): DateRange {
    const startOfMonth = new CustomDate(this.getFullYear(), this.getMonth(), 1)
    return { from: startOfMonth, to: new CustomDate(this) }
  }

  getOneWeekRange(startDate: CustomDate): DateRange {
    return { from: startDate.getStartOfWeek(), to: new CustomDate(this) }
  }

  getOneDayRange(): DateRange {
    return { from: new CustomDate(this), to: new CustomDate(this) }
  }

  /**
   * For periodType: 'weekly'
   *
   * 주의 시작이 월요일인 date의 주차를 반환
   */
  getWeekNumberOfMonth() {
    const firstDayOfMonth = new Date(this.getFullYear(), this.getMonth(), 1)
    const firstDayOfWeek = firstDayOfMonth.getDay()
    const offset = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek
    return Math.ceil((this.getDate() + offset) / 7)
  }

  // pad zero to single digit number
  private formatPart(part: number, pad = true): string {
    return pad ? part.toString().padStart(2, '0') : part.toString()
  }
}

export type DateRange = {
  from: CustomDate
  to: CustomDate
}
