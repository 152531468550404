import React from 'react'

import { type SurveyStep } from '../module/survey/types'

import ExaminationCollegeSelect from './ExaminationCollegeSelect'
import ExaminationSelect from './ExaminationSelect'
import MajorSelect from './MajorSelect'
import SchoolClassSelect from './SchoolClassSelect'
import SchoolNameSelect from './SchoolNameSelect'
import SurveyFormLayout from './surveyFormLayout'
import UserGroupSelect from './UserGroupSelect'
import UserNicknameSelect from './UserNicknameSelect'
import YearSelect from './YearSelect'

interface SurveyFormProps {
  onFormLoad?: () => void
  onSubmitSuccess?: () => void
}

export default function SurveyForm(props: SurveyFormProps) {
  const steps: Array<{ step: SurveyStep; component: React.ReactNode }> = [
    { step: 'userNickname', component: <UserNicknameSelect /> },
    { step: 'userGroup', component: <UserGroupSelect /> },
    { step: 'schoolName', component: <SchoolNameSelect /> },
    { step: 'major', component: <MajorSelect /> },
    { step: 'year', component: <YearSelect /> },
    { step: 'grade', component: <SchoolClassSelect currentStep='grade' /> },
    { step: 'classroom', component: <SchoolClassSelect currentStep='classroom' /> },
    { step: 'examinations', component: <ExaminationSelect /> },
    { step: 'examinationsCollege', component: <ExaminationCollegeSelect /> },
  ]

  return (
    <SurveyFormLayout>
      <SurveyFormLayout.ProgressBar />
      <SurveyFormLayout.Form onFormLoad={props.onFormLoad} onSubmitSuccess={props.onSubmitSuccess}>
        {steps.map(({ step, component }) => (
          <SurveyFormLayout.Contents key={step} step={step}>
            {component}
          </SurveyFormLayout.Contents>
        ))}
      </SurveyFormLayout.Form>
    </SurveyFormLayout>
  )
}
