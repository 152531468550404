import debounce from 'lodash/debounce'
import React, { type ChangeEvent, useState, useCallback } from 'react'

import { type NicknameFailReason } from '../api/interfaces/sconnStudyData'
import { nicknameErrorMessage } from '../constants/errorMessage'
import { useBookcafeData } from '../module/myPage/hook'
import { useUpdateNickname, useValidateNickname } from '../module/sconnStudyData/hook'
import { useSurveySteps } from '../module/survey/hook'

import SearchInput, { type Validation } from './common/SearchInput'
import FormContent from './surveyFormContentLayout'

export default function UserNicknameSelect() {
  const { setSkipFirstStep } = useSurveySteps()
  const { isPending, mutate } = useUpdateNickname()
  const { mutate: validate } = useValidateNickname()
  const { user, setUser } = useBookcafeData()
  const [validation, setValidation] = useState<Validation>('none')
  const [invalidmessage, setInvalidMessage] = useState<string>('FAIL')
  const [filterText, setFilterText] = useState<string>(user?.suggestNickName ?? '')

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newNickname = e.target.value.trim()
    setFilterText(newNickname)
    debouncedValidateNickname(newNickname)
  }

  const debouncedValidateNickname = useCallback(
    debounce((nickname: string) => {
      validate(nickname, {
        onSuccess: (data, variables) => {
          if (data.result === 'Success' && user) {
            setValidation('valid')
          }
        },
        onError: (err) => {
          setValidation('invalid')
          setInvalidMessage(
            nicknameErrorMessage[err.message as NicknameFailReason] ||
              '알 수 없는 오류가 발생했습니다.',
          )
        },
      })
    }, 500),
    [validate, user],
  )

  return (
    <FormContent>
      <FormContent.Grow>
        <FormContent.Question
          title='닉네임을 정해주세요.'
          description='여러분께 제공될 맞춤데이터에서 나를 표현할 이름이 되어줄거에요.'
        />
        <SearchInput
          className='mt-[50px]'
          label='닉네임'
          id='userNickname'
          name='userNickname'
          placeholder='닉네임을 입력해 주세요.'
          value={filterText}
          required
          onChange={handleTextChange}
          validation={validation}
          invalidmessage={invalidmessage}
          validmessage='사용할 수 있는 닉네임입니다.'
        />
      </FormContent.Grow>
      <FormContent.ProcessButton
        submitLoading={isPending}
        disabled={
          filterText === '' ||
          filterText.length < 2 ||
          filterText.length > 12 ||
          validation === 'invalid'
        }
        onNextClick={() => {
          mutate(filterText, {
            onSuccess: (data, variables) => {
              if (data.result === 'Success' && user) {
                setValidation('valid')
                delete user.suggestNickName
                setUser({ ...user, nickName: variables })
                setSkipFirstStep(true)
              }
            },
            onError: (err) => {
              setValidation('invalid')
              setInvalidMessage(
                nicknameErrorMessage[err.message as NicknameFailReason] ||
                  '알 수 없는 오류가 발생했습니다.',
              )
            },
          })
        }}
      />
    </FormContent>
  )
}
