import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ChevronLeftIcon } from '../icons/icon'

export default function Header() {
  const navigate = useNavigate()

  return (
    <div className='flex justify-between h-[50px] items-center px-[12px] tablet-sm:px-[18px]'>
      <div
        className='flex items-center justify-center'
        onClick={() => {
          navigate('/my-page')
        }}
      >
        <ChevronLeftIcon />
        <span className='hidden font-medium text-sub-font tablet-sm:block'>마이페이지</span>
      </div>
      <div className='font-medium'>스콘 북카페 계정</div>
      <div className='w-[25px] tablet-sm:w-[80px]'></div>
    </div>
  )
}
