import { IonModal } from '@ionic/react'
import React from 'react'

import Button from './common/Button'

interface EditGoalAlertModalProps {
  open: boolean
  onClickOk: () => void
}

export default function EditGoalAlertModal(props: EditGoalAlertModalProps) {
  return (
    <IonModal
      id='custom-modal'
      className='edited-goal-alert'
      keepContentsMounted
      isOpen={props.open}
      showBackdrop={false}
      backdropDismiss={false}
    >
      <div className='flex flex-col h-full'>
        <span className='w-full text-center text-[15px] font-extrabold px-[20px] pt-[20px]'>
          하루 목표가 수정되었어요!
        </span>
        <div className='flex flex-col items-center justify-center h-full text-[14px] font-medium text-center'>
          <span>오늘 목표는 달성했으니</span>
          <span>내일부터 변경된</span>
          <span>하루 목표가 반영될거에요.</span>
        </div>
        <div className='px-[20px] pt-[10px] pb-[20px]'>
          <Button onClick={props.onClickOk}>확인</Button>
        </div>
      </div>
    </IonModal>
  )
}
