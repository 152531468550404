import React, { type ChangeEvent, useState, useEffect, Fragment, lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type UniversityMajor } from '../api/interfaces/sconnStudyData'
import { useSurvey } from '../module/survey/hook'

import ErrorFallback from './common/ErrorFallback'
import LoadingFallback from './common/LoadingFallback'
import SearchInput from './common/SearchInput'
import FormContent from './surveyFormContentLayout'

const MajorNameFilter = lazy(async () => await import('./MajorNameFilter'))

export default function MajorSelect() {
  const { latestResult, university, userType, major, setMajor } = useSurvey()
  const [filterId, setFilterId] = useState<string | null>(null)
  const [filterText, setFilterText] = useState<string>('')
  const [selectedSearch, setSelectedSearch] = useState<UniversityMajor | null>(null)

  useEffect(() => {
    if (latestResult && userType === latestResult.userType) {
      setFilterId(latestResult.group?.dept1?.id ?? '')
      setFilterText(latestResult.group?.dept1?.name ?? '')
    }
  }, [latestResult])

  useEffect(() => {
    if (major) {
      setFilterText(major.name)
      setSelectedSearch(major)
    }
  }, [major])

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value)
    setSelectedSearch(null)
    setFilterId(null)
  }

  const handleFilterListItemClick = (item: UniversityMajor) => {
    setFilterText(item.name)
    setSelectedSearch(item)
  }

  return (
    <FormContent>
      <FormContent.Grow>
        <FormContent.Question
          title='전공이 어떻게 되세요?'
          description='같은 전공의 친구들과 학습 시간을 비교해 볼 수 있어요.'
        />
        <SearchInput
          className='mt-[50px]'
          placeholder='전공명을 입력해 주세요.'
          id='major'
          name='major'
          searchicon='true'
          value={filterText}
          onChange={handleTextChange}
          label='전공명'
        />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingFallback />}>
            <MajorNameFilter
              code={university?.code ?? ''}
              filterId={filterId}
              filterText={filterText}
              selectedItem={selectedSearch}
              onListItemClick={handleFilterListItemClick}
            />
          </Suspense>
        </ErrorBoundary>
      </FormContent.Grow>
      <FormContent.ProcessButton
        disabled={!selectedSearch}
        onNextClick={() => {
          if (selectedSearch) {
            setMajor(selectedSearch)
          }
        }}
      />
    </FormContent>
  )
}
