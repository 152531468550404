import classNames from 'classnames'
import React, { useEffect } from 'react'

import { type Dday } from '../api/interfaces/sconnStudyData'
import { useUserDday } from '../module/sconnStudyData/hook'

import { defaultMessage, defaultName } from './DdayBanner'
import { EditSMIcon } from './icons/icon'

interface DdayBannerLayoutProps {
  data: Dday
  diffDate: number
  onClickEdit: () => void
  onLoadData: (data: Dday) => void
}

export default function DdayBannerLayout(props: DdayBannerLayoutProps) {
  const { data } = useUserDday()

  useEffect(() => {
    if (data) {
      props.onLoadData(data)
    }
  }, [data])

  return (
    <div className='h-[147px] flex flex-col items-center justify-center bg-[#FFF4DD] mx-[20px] my-[14px] tablet-md:mx-[42px] tablet-lg:mx-[100px] rounded-[10px]'>
      <div className='flex gap-[5px] items-center'>
        <span className='font-extrabold text-[14px] tablet-sm:text-[16px]'>
          {props.data.name === '' ? defaultName : props.data.name}
        </span>
        <EditSMIcon onClick={props.onClickEdit} />
      </div>
      <span
        className={classNames(
          'font-extrabold text-[30px] tablet-sm:text-[40px] mt-[5px]',
          props.diffDate < 11 && props.diffDate >= 0 ? 'text-[#FF3030]' : 'text-black',
        )}
      >{`${props.diffDate === 0 ? 'D-DAY' : props.diffDate < 0 ? `D+${-props.diffDate}` : `D-${props.diffDate}`}`}</span>
      {props.data.showMessage && (
        <span className='font-medium text-[13px] mt-[10px]'>
          {props.data.message ?? defaultMessage}
        </span>
      )}
    </div>
  )
}

export function DdayBannerSkeleton() {
  return (
    <div className='h-[147px] flex flex-col items-center justify-center bg-[#FFF4DD] mx-[20px] my-[14px] tablet-md:mx-[42px] tablet-lg:mx-[100px] rounded-[10px]'>
      <div className='flex gap-[5px] items-center'>
        <span className='font-extrabold text-[14px] tablet-sm:text-[16px]'>{defaultName}</span>
      </div>
      <span className='font-extrabold text-[30px] tablet-sm:text-[40px] mt-[5px]'>D-365</span>
      <span className='font-medium text-[13px] mt-[10px]'>{defaultMessage}</span>
    </div>
  )
}
