import React from 'react'

import { useSurveySteps } from '../../module/survey/hook'
import { type SurveyStep } from '../../module/survey/types'

export default function Contents({
  children,
  step,
}: {
  children: React.ReactNode
  step: SurveyStep
}) {
  const { steps, activeStep } = useSurveySteps()
  const currentStep = steps[activeStep]
  const isActiveStep = step === currentStep
  return <>{isActiveStep && children}</>
}
