import { type Dispatch, type ReactNode, createContext, useReducer } from 'react'

import { EXAM_PRESET } from '../../constants/checkboxItems'

import { surveyReducer } from './reducer'
import { type Action, type SurveyState } from './types'

type SurveyDispatch = Dispatch<Action>
export const SurveyStateContext = createContext<SurveyState | null>(null)
export const SurveyDispatchContext = createContext<SurveyDispatch | null>(null)

export const initialState: SurveyState = {
  steps: ['userNickname', 'userGroup', 'schoolName', 'major', 'examinations'],
  activeStep: 0,
  isSkipFirstStep: false,
  isSkipLastStep: false,
  userGroup: null,
  surveyed: {
    userType: null,
    university: null,
    middleschool: null,
    highschool: null,
    major: null,
    year: null,
    grade: null,
    classroom: null,
    examinations: null,
  },
  checkedUserGroup: null,
  hasUserSearch: false,
  checkboxExaminations: EXAM_PRESET.map((item) => ({ ...item, checked: false })),
  checkboxIsUniversity: null,
  surveyIsPending: false,
  surveyOnModal: false,
  latestResult: null,
  surveyResult: {
    userType: 'UC',
    group: { school: null, grade: null, class: null, year: null, dept1: null, dept2: null },
    exams: null,
  },
}

export function SurveyContextProvider({ children }: { children: ReactNode }) {
  const [survey, dispatch] = useReducer(surveyReducer, initialState)

  return (
    <SurveyDispatchContext.Provider value={dispatch}>
      <SurveyStateContext.Provider value={survey}>{children}</SurveyStateContext.Provider>
    </SurveyDispatchContext.Provider>
  )
}
