import {
  type UniversityMajor,
  type Highschool,
  type Middleschool,
  type University,
  type SchoolClass,
  type CertData,
  type LatestSurvey,
  type SurveyResult,
} from '../../api/interfaces/sconnStudyData'
import { type CheckboxItemCertData } from '../../components/ExaminationSelect'

export type SurveyStep =
  | 'userNickname'
  | 'userGroup'
  | 'schoolName'
  | 'major'
  | 'year'
  | 'grade'
  | 'classroom'
  | 'examinations'
  | 'examinationsCollege'
export type UserGroup =
  | 'none'
  | 'college'
  | 'highschool'
  | 'repeater'
  | 'middleschool'
  | 'elemantaryschool'
  | 'general'
  | 'generalCollege'
export type UserTypeName = 'E' | 'M' | 'H' | 'H4' | 'UC' | 'AG'
export type HasClassUserType = Extract<UserTypeName, 'M' | 'H'>
export type OkNo = 'ok' | 'no'

export type SurveyState = {
  steps: SurveyStep[]
  activeStep: number
  isSkipFirstStep: boolean
  isSkipLastStep: boolean
  userGroup: UserGroup | null
  surveyed: {
    userType: UserTypeName | null
    university: University | null
    middleschool: Middleschool | null
    highschool: Highschool | null
    major: UniversityMajor | null
    year: string | null
    grade: SchoolClass[] | null
    classroom: SchoolClass | null
    examinations: CertData[] | null
  }
  hasUserSearch: boolean
  checkedUserGroup: UserGroup | null
  checkboxExaminations: CheckboxItemCertData[]
  checkboxIsUniversity: OkNo | null
  surveyIsPending: boolean
  surveyOnModal: boolean
  latestResult: LatestSurvey | null
  /**
   * 처음부터 수정 (기존)
   * - surveyed 수정 마다 surveyResult를 업데이트
   *
   * 기존 것 수정 (추가)
   * - latestResult로 덮어쓰고, surveyed로 업데이트된거 수정
   * - 문제: 각 컴포넌트에서 무조건 latestResult를 사용하여 뒤로갈 경우 선택한게 보이지 않음
   *       TODO:  모든 컴프에서 이렇게 하면 안되는 이유 -> 뒤로가도 최근 데이터만 나옴. 최근데이터는 취소시에만 적용
   *        onFormLoad에서 처리하는걸로 수정. 그럼 filterid, filtertext도 다 context로 둬야 함
   *
   * 결국 post 시, surveyResult를 전달
   *
   */
  surveyResult: SurveyResult
}

export const SURVEY_SET_USER_GROUP = 'SURVEY/SET_USER_GROUP' as const
export const SURVEY_SET_STEPS = 'SURVEY/SET_STEPS' as const
export const SURVEY_NEXT_STEPS = 'SURVEY/NEXT_STEPS' as const
export const SURVEY_PREV_STEPS = 'SURVEY/PREV_STEPS' as const
export const SURVEY_SET_START_STEP = 'SURVEY/SET_START_STEP' as const
export const SURVEY_SET_SKIP_FIRST_STEP = 'SURVEY/SKIP_FIRST_STEP' as const
export const SURVEY_SET_SKIP_LAST_STEP = 'SURVEY/SKIP_LAST_STEP' as const
export const SURVEY_SET_UNIVERSITY = 'SURVEY/SET_UNIVERSITY' as const
export const SURVEY_SET_MIDDLESCHOOL = 'SURVEY/SET_MIDDLESCHOOL' as const
export const SURVEY_SET_HIGHSCHOOL = 'SURVEY/SET_HIGHSCHOOL' as const
export const SURVEY_SET_MAJOR = 'SURVEY/SET_MAJOR' as const
export const SURVEY_SET_YEAR = 'SURVEY/SET_YEAR' as const
export const SURVEY_SET_GRADE = 'SURVEY/SET_GRADE' as const
export const SURVEY_SET_CLASSROOM = 'SURVEY/SET_CLASSROOM' as const
export const SURVEY_SET_EXAMINATION = 'SURVEY/SET_EXAMINATION' as const

export const SURVEY_SET_CHECKED_USER_GROUP = 'SURVEY/SET_CHECKED_USER_GROUP' as const
export const SURVEY_SET_EXAMINATION_HAS_USER_SEARCH =
  'SURVEY/SET_EXAMINATION_HAS_USER_SEARCH' as const
export const SURVEY_SET_EXAMINATION_CHECKBOX_ITEM = 'SURVEY/SET_EXAMINATION_CHECKBOX_ITEM'
export const SURVEY_SET_CHECKBOX_IS_UNIVERSITY = 'SURVEY/SET_CHECKBOX_IS_UNIVERSITY' as const
export const SURVEY_SET_IS_PENDING = 'SURVEY/SET_SURVEY_IS_PENDING' as const
export const SURVEY_SET_ON_MODAL = 'SURVEY/SET_SURVEY_ON_MODAL' as const
export const SURVEY_SET_LATEST_RESULT = 'SURVEY/SET_SURVEY_LATEST_RESULT' as const
export const SURVEY_SET_RESULT = 'SURVEY/SET_RESULT' as const

export type Action =
  | { type: typeof SURVEY_SET_USER_GROUP; payload: UserGroup | null }
  | {
      type: typeof SURVEY_SET_STEPS
      payload: UserGroup
    }
  | {
      type: typeof SURVEY_NEXT_STEPS
    }
  | {
      type: typeof SURVEY_PREV_STEPS
    }
  | { type: typeof SURVEY_SET_START_STEP; payload: SurveyStep }
  | {
      type: typeof SURVEY_SET_SKIP_FIRST_STEP
      payload: boolean
    }
  | {
      type: typeof SURVEY_SET_SKIP_LAST_STEP
      payload: boolean
    }
  | {
      type: typeof SURVEY_SET_UNIVERSITY
      payload: University
    }
  | {
      type: typeof SURVEY_SET_MIDDLESCHOOL
      payload: Middleschool
    }
  | {
      type: typeof SURVEY_SET_HIGHSCHOOL
      payload: Highschool
    }
  | {
      type: typeof SURVEY_SET_MAJOR
      payload: UniversityMajor
    }
  | {
      type: typeof SURVEY_SET_YEAR
      payload: string | null
    }
  | {
      type: typeof SURVEY_SET_GRADE
      payload: SchoolClass[]
    }
  | {
      type: typeof SURVEY_SET_CLASSROOM
      payload: SchoolClass | null
    }
  | {
      type: typeof SURVEY_SET_EXAMINATION
      payload: CertData[]
    }
  | {
      type: typeof SURVEY_SET_CHECKED_USER_GROUP
      payload: UserGroup | null
    }
  | {
      type: typeof SURVEY_SET_EXAMINATION_HAS_USER_SEARCH
      payload: boolean
    }
  | {
      type: typeof SURVEY_SET_EXAMINATION_CHECKBOX_ITEM
      payload: CheckboxItemCertData[]
    }
  | {
      type: typeof SURVEY_SET_CHECKBOX_IS_UNIVERSITY
      payload: OkNo
    }
  | {
      type: typeof SURVEY_SET_IS_PENDING
      payload: boolean
    }
  | {
      type: typeof SURVEY_SET_ON_MODAL
      payload: boolean
    }
  | {
      type: typeof SURVEY_SET_LATEST_RESULT
      payload: LatestSurvey
    }
