import React, { useEffect, useRef, type InputHTMLAttributes } from 'react'

import { useSurvey } from '../../module/survey/hook'
import { SearchIcon } from '../icons/icon'

export type Validation = 'none' | 'invalid' | 'valid'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  searchicon?: 'true' | 'false'
  validation?: Validation
  invalidmessage?: string
  validmessage?: string
  className: string
}

export default function SearchInput(props: SearchInputProps) {
  const { surveyOnModal } = useSurvey()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView()
    }
  }, [inputRef.current])

  return (
    <div className={`flex flex-col relative ${surveyOnModal ? 'mt-[35px]' : props.className}`}>
      <label htmlFor={props.id} className='text-sub-font text-[13px] placeholder-sub-font'>
        {props.label}
      </label>
      <input
        {...props}
        type='text'
        ref={inputRef}
        className={`text-[14px] tablet-sm:text-[16px] rounded-none border-transparent ${props.validation === 'invalid' ? 'focus:border-b-invalid-msg border-b-invalid-msg border-[1px]' : 'focus:border-b-black border-b-sub-border border-[0.5px]'}  py-[12px] focus:outline-none`}
      />
      {props.searchicon === 'true' && (
        <div className='absolute right-3 top-10'>
          <SearchIcon stroke='black' />
        </div>
      )}
      {props.validation === 'invalid' && (
        <div className='mt-[5px]'>
          <span className='text-[14px] text-invalid-msg'>{props.invalidmessage}</span>
        </div>
      )}
      {props.validation === 'valid' && (
        <div className='mt-[5px]'>
          <span className='text-[14px] text-valid-msg'>{props.validmessage}</span>
        </div>
      )}
    </div>
  )
}
