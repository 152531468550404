import React from 'react'

interface IContentCardProps {
  content: {
    studyTime: string
    title: string
    thumbnail: string
  }
  className?: string
  imgClassName?: string
  backgroundColor?: string
}

export default function ContentCard(props: IContentCardProps) {
  return (
    <div
      className={`${props.className} overflow-hidden relative flex-1 flex flex-col ${props.backgroundColor} rounded-[10px] gap-[5px] p-[15px]`}
    >
      <span className='text-[14px] font-extrabold'>{props.content.studyTime}</span>
      <span className='text-[12px] font-medium line-clamp-3'>{props.content.title}</span>
      <img
        className={`w-[88px] h-auto object-contain rounded-md right-[20px] top-0 ${props.imgClassName} absolute shadow-bookItem`}
        src={props.content.thumbnail}
        alt={props.content.title}
      />
    </div>
  )
}

interface INoContentCardProps {
  backgroundColor: string
  textColor: string
}

export function NoContentCard({ textColor, backgroundColor }: INoContentCardProps) {
  return (
    <div
      className={`gap-[17px] items-center justify-center flex-1 flex flex-col ${backgroundColor} rounded-[10px]`}
    >
      <span className='text-[12px] font-medium'>학습한 도서가 없어요.</span>
      <div className='flex items-center justify-center w-[80px] h-[107px] rounded-[5px] bg-white opacity-20'>
        <span className={`text-[40px] font-extrabold blur-[3px] ${textColor}`}>?</span>
      </div>
    </div>
  )
}

export function ContentsCardSkeleton() {
  return (
    <div className='flex gap-[15px] mt-[10px] text-white h-[200px] blur-[3px] transform translate3d(0,0,0)'>
      <ContentCard
        content={{
          title: '2024대학수학능력시험문제모음+정답 국어(홀수형) 짝수형홀수형',
          studyTime: '999시간 99분',
          thumbnail: '/img/book1.jpeg',
        }}
        imgClassName='mt-[107px]'
        className='col-span-2 overflow-hidden tablet-sm:col-span-1'
        backgroundColor='bg-gradient-to-b from-[#FF8E06] to-[#FFAF13]'
      />
      <ContentCard
        content={{
          title: '2024대학수학능력시험',
          studyTime: '9시간 9분',
          thumbnail: '/img/book2.jpeg',
        }}
        className='overflow-hidden'
        imgClassName='mt-[107px]'
        backgroundColor='bg-gradient-to-b from-[#6249DE] to-[#C0E6FC]'
      />
      <ContentCard
        content={{
          title: '2024대학수학능력시험문제모음+정답 국어(홀수형) 짝수형홀수형짝수형홀수...',
          studyTime: '99분',
          thumbnail: '/img/book1.jpeg',
        }}
        className='overflow-hidden'
        imgClassName='mt-[107px]'
        backgroundColor='bg-gradient-to-b from-[#78C776] to-[#D9EC91]'
      />
    </div>
  )
}
