import React from 'react'

import Contents from './Contents'
import Form from './Form'
import ProgressBar from './ProgressBar'

export default function SurveyFormLayout({ children }: { children: React.ReactNode }) {
  return <>{children}</>
}

SurveyFormLayout.ProgressBar = ProgressBar
SurveyFormLayout.Form = Form
SurveyFormLayout.Contents = Contents
