import { type ReactNode, createContext, useContext } from 'react'

import { AosNativeInteractor } from './aosNativeInteractor'
import { IosNativeInteractor } from './iosNativeInteractor'
import { NativeInteractor, type INativeInteractor } from './nativeInteractor'

export const NativeInteractorContext = createContext<{ interactor: INativeInteractor } | null>(null)

export function NativeInteractorProvider({ children }: { children: ReactNode }) {
  const interactor = new NativeInteractor(new IosNativeInteractor(), new AosNativeInteractor())

  return (
    <NativeInteractorContext.Provider value={{ interactor }}>
      {children}
    </NativeInteractorContext.Provider>
  )
}

export function useNativeInteractor() {
  const context = useContext(NativeInteractorContext)

  if (context === null) {
    throw Error('NativeInteractor instance is null: Cannot proceed with operation.')
  }

  return context
}
