import { IonDatetime, IonModal } from '@ionic/react'
import React, { useEffect, useState } from 'react'

import Button from './common/Button'

interface DdayBannerEditDateProps {
  open: boolean
  defaultValue: string
  value: string
  min: string
  max: string
  onDatetimeChange: (e: any) => void
  onCancel: () => void
  onOk: () => void
}

export default function DdayBannerEditDate(props: DdayBannerEditDateProps) {
  const [value, setValue] = useState<string>(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <IonModal
      id='custom-modal'
      className='nested-dialog datetime-modal'
      keepContentsMounted
      isOpen={props.open}
      backdropDismiss={false}
      showBackdrop
    >
      <div className='flex flex-col h-full px-[20px] pt-[20px] pb-[20px]'>
        <div className='flex items-center justify-between'>
          <div className='w-[30px]'></div>
          <span className='text-center text-[15px] font-extrabold flex-1'>시험날짜</span>
          <button type='button' className='text-cancel-font' onClick={props.onCancel}>
            취소
          </button>
        </div>
        <div className='flex items-center justify-center h-full'>
          <IonDatetime
            id='app-datetime-presentation-date'
            preferWheel
            presentation='date'
            defaultValue={props.defaultValue}
            value={value}
            min={props.min}
            max={props.max}
            onIonChange={props.onDatetimeChange}
          />
        </div>
        <Button onClick={props.onOk}>완료</Button>
      </div>
    </IonModal>
  )
}
