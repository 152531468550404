import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { secondsToHour, secondsToMinute } from '../util/numberUtils'

import {
  BurnIcon,
  SpeechBubbleAchievedIcon,
  SpeechBubbleAchievedSMIcon,
  SpeechBubbleIcon,
  SpeechBubbleMaxIcon,
  SpeechBubbleMaxSMIcon,
  SpeechBubbleMinIcon,
  SpeechBubbleSMIcon,
} from './icons/icon'

interface TodayDurationProgressBarProps {
  duration: number
  maxDuration: number | null
  isOverDuration: boolean
}

export default function TodayDurationProgressBar(props: TodayDurationProgressBarProps) {
  const [percentage, setPercentage] = useState<number>(0)
  const [timeLabel, setTimeLabel] = useState<string>('0분')
  const [isShortLabel, setIsShortLabel] = useState<boolean | null>(false)

  useEffect(() => {
    const hours = secondsToHour(props.duration)
    const minutes = secondsToMinute(props.duration)
    const timeString = `${hours !== 0 ? `${hours}시간` : ''}${hours !== 0 && minutes !== 0 ? ' ' : ''}${minutes !== 0 ? `${minutes}분` : ''}`
    setTimeLabel(timeString === '' ? '0분' : timeString)
    setIsShortLabel(
      timeString === '' ? true : (hours === 0 && minutes !== 0) || (hours !== 0 && minutes === 0),
    )

    setPercentage(
      props.maxDuration === null || props.maxDuration === 0
        ? props.duration === 0
          ? 0
          : 100
        : props.isOverDuration
          ? (props.maxDuration / props.duration) * 100
          : (props.duration / props.maxDuration) * 100,
    )
  }, [props.duration, props.maxDuration, props.isOverDuration])

  return (
    <div className='mt-[7px]'>
      <div
        className={'relative w-full rounded-[40px] h-[15px] bg-progress-background'}
        style={{
          background: props.isOverDuration
            ? `linear-gradient(to right, #F17400 0%, #F17400 ${percentage}%, #FF2222 100%)`
            : '#E9E9E9',
        }}
      >
        {percentage > 2 && (
          <div className='bg-[#FFE68F] w-[5px] h-[5px] absolute left-[5px] top-[3px] rounded-full'></div>
        )}
        <div
          className={classNames(
            'h-full rounded-[40px] bg-gradient-to-r from-brand-primary',
            props.isOverDuration ? 'to-[#FDAF53]' : 'to-[#FFB72A]',
          )}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      {props.maxDuration !== null && (
        <div className='w-full'>
          {props.isOverDuration ? (
            <div className='flex justify-end mr-4'>
              <div
                className={classNames(
                  'flex items-center justify-center relative h-[35px] mt-[5px]',
                  isShortLabel ? 'w-[72px]' : 'w-[104px]',
                )}
              >
                {isShortLabel ? <SpeechBubbleAchievedSMIcon /> : <SpeechBubbleAchievedIcon />}
                <div className='absolute z-10 flex gap-[6px] items-center mt-[8px]'>
                  <BurnIcon />
                  <span className='text-[12px] text-white font-medium'>{timeLabel}</span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className='flex justify-end'
              style={{
                width: `calc(${percentage}% + ${percentage === 0 ? '54' : percentage === 100 ? '0' : isShortLabel ? '28' : '44'}px)`,
              }}
            >
              <div
                className={classNames(
                  'flex items-center justify-center relative h-[43px] mt-[5px]',
                  isShortLabel ? 'w-[56px]' : 'w-[88px]',
                )}
              >
                {percentage === 0 && <SpeechBubbleMinIcon />}
                {percentage === 100 && !isShortLabel && <SpeechBubbleMaxIcon />}
                {percentage === 100 && isShortLabel && <SpeechBubbleMaxSMIcon />}
                {percentage !== 0 && percentage !== 100 && isShortLabel && <SpeechBubbleSMIcon />}
                {percentage !== 0 && percentage !== 100 && !isShortLabel && <SpeechBubbleIcon />}
                <span className='absolute z-10 text-[12px] font-extrabold'>{timeLabel}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export function TodayDurationProgressBarSkeleton() {
  return (
    <div className='animate-pulse mt-[7px]'>
      <div className='relative w-full rounded-[40px] h-[15px] bg-progress-background'></div>
      <div className='w-full h-[43px] mt-[5px]'></div>
    </div>
  )
}
