import React from 'react'
import { useNavigate } from 'react-router'

import Button from '../components/common/Button'

export default function Complete() {
  const navigate = useNavigate()
  return (
    <div className='flex flex-col items-center text-center break-keep  mt-[10px] tablet-sm:mt-[50px] px-[30px]'>
      <p className='text-[21px] font-bold'>설문이 완료되었어요!</p>
      <p className='text-[21px] font-bold'>실용적인 학습 데이터와 함께 다시 찾아올게요.</p>
      <div className='mt-[30px] mb-[25px] w-[260px] tablet-sm:w-[278px] tablet-md:w-[400px] tablet-lg:w-[500px]'>
        <picture>
          <source
            srcSet={`${process.env.PUBLIC_URL}/img/update_popup_thumbnail.webp`}
            type='image/webp'
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/update_popup_thumbnail.jpg`}
            alt='update_popup_thumbnail'
          />
        </picture>
      </div>
      <p className='text-[15px]'>만년형 디지털 다이어리가 지급되었습니다.</p>
      <p className='text-[15px]'>구매도서 리스트에서 확인해 주세요!</p>
      <div className='bg-white fixed inset-x-0 bottom-0 pl-[26px] pt-[15px] pr-[25px] pb-[25px] tablet-sm:px-[78px] tablet-sm:pt-[25px] tablet-sm:pb-[25px] tablet-md:px-[80px] tablet-md:pt-[25px] tablet-md:pb-[50px] tablet-lg:px-[118px] tablet-lg:pt-[25px] tablet-lg:pb-[80px]'>
        <Button
          onClick={() => {
            navigate('/my-page')
          }}
        >
          마이페이지 메인으로
        </Button>
      </div>
    </div>
  )
}
