import { type ChangeEvent, type MouseEvent } from 'react'

import ButtonCheckbox from './common/ButtonCheckbox'
import { type CheckboxItemCertData } from './ExaminationSelect'
import { SearchIcon } from './icons/icon'

interface ExaminationCheckboxListProps {
  items: CheckboxItemCertData[]
  disabledAdd?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClickSearchMore?: (e: MouseEvent<HTMLButtonElement>) => void
}

export default function ExaminationCheckboxList(props: ExaminationCheckboxListProps) {
  return (
    <ul className='grid grid-cols-1 tablet-sm:grid-cols-2 gap-y-[15px] gap-x-[16px] mt-[20px]'>
      {props.items.map((exam) => (
        <li key={exam.id}>
          {exam.id === '0' && exam.code === 'add-button' ? (
            <button
              onClick={props.onClickSearchMore}
              disabled={props.disabledAdd}
              className={`w-full ${props.disabledAdd ? 'border-dashed bg-[#F4F4F4]' : 'border-solid'} rounded-[12px] tablet-sm:rounded-[7px] border-[0.5px] border-sub-border shadow-none`}
            >
              <div className={'block text-center p-[15px] font-normal'}>
                <div className='flex items-center justify-center gap-[6px]'>
                  <p
                    className={`${props.disabledAdd && 'text-[#A3A4A5]'} line-clamp-1 text-[15px] tablet-sm:text-[16px]`}
                  >
                    {exam.name}
                  </p>
                  <SearchIcon stroke={props.disabledAdd ? '#A3A4A5' : 'black'} />
                </div>
              </div>
            </button>
          ) : (
            <ButtonCheckbox
              id={exam.id || ''}
              value={exam.id || ''}
              label={exam.name || ''}
              name='examinations'
              checked={exam.checked || false}
              onChange={props.onChange}
            />
          )}
        </li>
      ))}
    </ul>
  )
}
