import React from 'react'

import { type User } from '../../api/interfaces/sconnStudyData'

import AccountNavigation from './AccountNavigation'
import Content from './Content'

export default function MyPageLayout({
  children,
  user,
}: {
  children: React.ReactNode
  user: User | null
}) {
  return <>{user && children}</>
}

MyPageLayout.AccountNavigation = AccountNavigation
MyPageLayout.Content = Content
