import { type ChangeEvent, type MouseEvent } from 'react'

import { useSurvey } from '../module/survey/hook'

import ButtonCheckbox from './common/ButtonCheckbox'
import { type CheckboxItemCertData } from './ExaminationSelect'
import { AddIcon, SearchIcon } from './icons/icon'

interface ExaminationUserSearchListProps {
  items: CheckboxItemCertData[]
  disabledAdd?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClickAddItem: (e: MouseEvent<HTMLButtonElement>) => void
  onClickRemoveItem: (e: MouseEvent<HTMLButtonElement>, id: string) => void
}

export default function ExaminationUserSearchList(props: ExaminationUserSearchListProps) {
  const { surveyOnModal } = useSurvey()

  return (
    <ul
      className={`grid grid-cols-1 tablet-sm:grid-cols-2 gap-y-[15px] gap-x-[16px] ${surveyOnModal ? 'mt-[35px]' : 'mt-[50px]'}`}
    >
      <li className='col-span-2'>
        <button
          onClick={props.onClickAddItem}
          disabled={props.disabledAdd}
          className={`w-full ${props.disabledAdd ? 'border-dashed bg-[#F4F4F4]' : 'border-solid'} rounded-[12px] tablet-sm:rounded-[7px] border-[0.5px] border-sub-border shadow-none`}
        >
          <div className={'block text-center p-[15px] font-normal'}>
            <div className='flex items-center justify-center gap-[6px]'>
              <AddIcon stroke={props.disabledAdd ? '#A3A4A5' : 'black'} />
              <p
                className={`${props.disabledAdd ? 'text-[#A3A4A5]' : ''} line-clamp-1 text-[15px] tablet-sm:text-[16px]`}
              >
                시험 추가
              </p>
            </div>
          </div>
        </button>
      </li>
      {props.items.map((exam) => (
        <li key={exam.id} className='col-span-2 tablet-sm:col-span-1'>
          {exam.id === '0' && exam.code === 'add-button' ? (
            <button
              onClick={props.onClickAddItem}
              className={
                'w-full border-solid rounded-[12px] tablet-sm:rounded-[7px] border-[0.5px] border-sub-border shadow-none'
              }
            >
              <div className={'block text-center p-[15px] font-normal'}>
                <div className='flex items-center justify-center gap-[6px]'>
                  <p className='line-clamp-1 text-[15px] tablet-sm:text-[16px]'>{exam.name}</p>
                  <SearchIcon stroke='black' />
                </div>
              </div>
            </button>
          ) : (
            <ButtonCheckbox
              id={exam.id}
              value={exam.id}
              label={exam.name}
              name='examinations'
              checked={exam.checked}
              onChange={props.onChange}
              removable
              onClickRemove={props.onClickRemoveItem}
            />
          )}
        </li>
      ))}
    </ul>
  )
}
