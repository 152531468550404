import React from 'react'
import { Outlet } from 'react-router-dom'

import SurveyLayout from '../components/surveyLayout'

function Survey() {
  return (
    <SurveyLayout>
      <SurveyLayout.Header />
      <SurveyLayout.Section>
        <Outlet />
      </SurveyLayout.Section>
    </SurveyLayout>
  )
}

export default Survey
